<template>
  <div>
    <div class="ant-modal-root">
      <div class="ant-modal-mask" style="z-index: 1007"></div>
      <div
        tabindex="-1"
        class="ant-modal-wrap ant-modal-centered"
        role="dialog"
        aria-labelledby="rcDialogTitle3"
        style="z-index: 1007"
      >
        <div
          role="document"
          class="ant-modal exnh8b38 css-1x3qht3 e1lu69xk0"
          style="
            min-width: 530px;
            left: 50%;
            top: 50%;
            position: fixed;
            transform: translate(-50%, -50%);
            margin: 0;
          "
        >
          <div
            tabindex="0"
            aria-hidden="true"
            style="width: 0px; height: 0px; overflow: hidden; outline: none"
          ></div>
          <div class="ant-modal-content">
            <button
              type="button"
              aria-label="Close"
              class="ant-modal-close"
              @click="hidePayModal"
            >
              <span class="ant-modal-close-x"
                ><span
                  role="img"
                  aria-label="close"
                  class="anticon anticon-close ant-modal-close-icon"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    class=""
                    data-icon="close"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
                    ></path>
                  </svg> </span
              ></span>
            </button>
            <div class="ant-modal-header">
              <div class="ant-modal-title" id="rcDialogTitle3">立即支付</div>
            </div>
            <div class="ant-modal-body">
              <div
                class="css-14srtnw exnh8b37"
                style="padding: 20px 0px 0px 20px"
              >
                <div class="paytype_contianer">
                  <div class="pay-select">
                    <el-form
                      :model="form"
                      :rules="rules"
                      ref="form"
                      v-if="isShowForm"
                      @submit.native.prevent
                    >
                      <div class="mask" ref="formMask"></div>
                      <div class="icon">*</div>
                      <el-form-item prop="mobile">
                        <el-input
                          v-model="form.mobile"
                          placeholder="输入你的手机号码"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                    <div class="pay-select-title">付款方式</div>
                    <div class="pay-type">
                      <el-radio v-model="payment" :label="1"
                        >借记卡/信用卡</el-radio
                      >
                    </div>
                    <div class="ant-radio-group ant-radio-group-outline">
                      <label
                        class="
                          ant-radio-wrapper
                          ant-radio-wrapper-checked
                          ant-radio-wrapper-disabled
                        "
                      >
                        <span
                          class="ant-radio ant-radio-checked ant-radio-disabled"
                        >
                          <span class="ant-radio-inner"></span>
                        </span>
                        <div class="radio-desc">
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAACVUlEQVRIS83TXUhTcRjH8e9xy+aWL0MnS0MXminYi7moDKyksshkYpFoUJBR0C4sujTwQrCwUiTDiwqLIiMvLJRGRkZqGAwMEhTCWi+o2WTqppvT7YTnohIXeQSlc3MOnP9zPv/n9z+PIIqiyDJewn8PVj+yMj7ukTJJMERStC9FVj6yOwzaUoEoCr+QJ7VHyc1IWDAqC/wy7CQ+uxYmRxCmJ5g9/JKa81TlrFsa8FbLe06XtoBzCMHvRUxPI/vUYSwH9EsDlt/u5NKNdsjbBdowFBo1jVnRmAyapQHPXWnlZoMVzAVUGLUUrw8lSqVYMDa7UNYZPn3TT5vNSbU7nN78WJIjgmVhskHZXw9QELDDusfdvO0ZYFNSNOZj6SiVQQGtV4Nu7n5wEatRYk4JQ6/+HW91zximeDWG0BVzagOCxsJ6ivM2UlrbTnPNETxeH1ERISgVQcz4/KQm6ujo/kqTV4PH56fHMU1OnJrdq1WoFAJKQaCgbRhLtn7OJv4a6aqMa2xI1OGc9KKP1JC8NpLm1/2Und1JWV0HVy9k0WDpxbN/B59dMzim/BxcE8Kg28eo1892nYr7/S6GCuPmJTOvwyG7i80Fd+i6dwJDTDiqbZU0VuZxseolTdfzMV9+jm1wDOuDkxif/aA+U4dRtxJT63epQ6t9iuTwYF4MuOnKjfk3aBsYk+I6fihVWmzp/EifbUR6Linayt4zD6V7TmYi5d0OStO00ru+US+NnyakMUnVBmOf8mGKnz+fssbC7pik4903THuSFv3DygIXrfxRuOzgT6b+A9rsg+bTAAAAAElFTkSuQmCC"
                            alt=""
                          /><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAACwklEQVRIS+2TXUjTYRTGH5vL4Qe6OVGGbs0ya36NXGrm8oPK7MsorVkWhoklSTdeVCgNvGiGhKIWgYIXhhM17EOdYWkkOSxs5tRlizmaojlxpta0bf/YWwjRurO88b16eTnnPOf5nfc4URRF4T8ep3XB1aa9RkitZtjGHoCafv3TEFMAWnAOufZoplDTpUUEj4nQAC/c79EhOYIDSSwf6k+zqOzQ4P3EF7gznHElZTv2hnFIXunjIQwZTIjewsbFfcEroH5z+N00ClVTLoTuH0FPHwVoDBwueYbWtwbU5++BUjuN8vYRVGRFQbSZDfGNdtBoThAFsmGYWYQsIxKS3XwoP0xjV1EbEbE3qio56ljQ/hpT2Aop5y72pxfhMz0cAXmN8PZwgeFOOhHveDeBl9IDeNJvQMkjNbITg1CdG0sKWqw2ONM2QFL+Ag29Y+Cx3aA3LmK+9jTcGXQS88cMpY0qyFpU0F73QZ2Wi2v1/Sg+KUTh8Qj45TZgas6M2ZoM4iJF1kmKxAt8UXYuCsJNLOKUn9+MeIEftnE8UfVUg66iZCSE+DkWtM9MLFUgS8xD14gRk3PfMFaRBgadBmZ2PXw9GZi8d4okdw5OoKDuDQb0s/By3QhdxQnIHg4S5/YRWGw2nK3qwc2MHbiaGuZY0I6FnSOH1UphYcmCM3GBqLssRvfQJBKLO5AczkFLQRIpZsdkXrbCP68RMwtLBPWRW89h+roMf5YrLFaKNJwqCiA5DpHaH9Nud6O5T08CeosPIibIB5WKEeTX9pGfeEzEJTiFPBaM82Zop+YRyWfhQtJWXKpRIpLvjVCuF8lvUuoJHWO15O+CCtU45L06sD1cUJq5kwTKX+mgGBhHZlwg/FluKGsfhmZ8jrhMEPiSr1/WNkzECw6FIJTL/LUeaqgNJlSejyaxa7T4K1vy7y/rDled8Q/XZ03a6n3J9gAAAABJRU5ErkJggg=="
                            alt=""
                          /><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAACbklEQVRIS82US2gTURSG/3snmaZtYpMmLWOlMtQUs4i1Wim29uGiSBCFqBu7EFF0I8EHBEQISN0IYlFxpSJkK3YRJMWF6MZsBEULWbgQDFhIKbEmMWle85DcYCDmpk4Rinczw/3/c757ztw5RNd1HVu4yH8HVBPfUFpYhLacBHF1Q5yZgPngCOuJllqClnwLPZ8EsfaD9s+Adrk37FfLCnVFQT50B4X5R4CiNiRp80+i/aIJ6uqbxuREgLD7DMyjcwA1ccEtgbmrN1F48LQ5iOpwnEiB9mooH+gBKGny0IGTEKceGgcqn+L4sc/HDWgfzsO6N8O0yoAN6k4r1yf6noNK400at8Jc8BYK84+5iRyzKZjEcu0bdggoj/ZyfcLgLMyH7hoDZo6dRXnxNTeR69wKiKbVNAIUp7dzfVQag+hbMAbMnr6E0rMX3ETO8yugag2oCwSlSenfgev3nyB/bY6baNvRNbT1FJmm2kVUhp1cn2noMkz7rxurUEtnsCaPQc9kmwLMcgn26e9sv7zHAc1p4dxkEeLxl6AOjzFg1VWKvkLWfwFQG//BqtZ5+CfEcRXKYFczjAgwT9yDsOsUt/INR1vl/RJygRCUdx/rwXSHhI7QFYhHbKh8uA3kl+sacQ7BPHIDtG+KC2P3zMgs1VZWoXz+Aurqhsnb2CY9+xX6ehLEJoN09rUE/RZaAiORCIrFIjweD+LxOLxeL3K5HKLRKPx+P4tPp9PsKUkS87jdtTkai8UQDAY319JwOMwCUqkUA8uyjEQiAbvdzkAul6t+GIvFwrQq0Gq1svdAIIDq/p/LUEv/2qdNGLYc+Att0AbamzzyOQAAAABJRU5ErkJggg=="
                            alt=""
                          /><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAACv0lEQVRIS+1UXUiTURh+tum+/evMOYeaFuIsDS1D7UeslH7IiFjgRZkXEhEYBtVFdOWFmFD0Z1BUIGRUkJSkMDNTyZHEqMSGFktLJ5rTuZ9v+zb3bcY5kBrYnSVE5+acm/M87/O8z/sKUDU4h794BP8Jl9vtlbW0dKMSHZ99iJYKkZMkweP3HuxfL4fNxSM7gaFirfYgUjWR4ENAzzCH9Dgx2ga92LZGCgUjhM0ZRKxChG4rh/JcFZr7WWTEM+gbC4CdDWNeYX6yBPfLdHjywYNPk7O4YdCi4PoI2k8mob5nBqcL1bjaPQPziB8NR3Q4/9yOE1ujKcijdx5sXyvFA7MbZ3aqKWHBtVHMXEzFLZOTFlJtnMZXR3CB8OExHVWwI1WGauMU8lOkyEuWwjTkw6iLnyfssvrQcjwRbCCMS50OlGQo8HE8gBaLF989PJ5WJGDCzaPR7MbZXTEwj/qRGc9g323bAqFCLMSVQxrUtDtgyFJCIxeBAFdsicLlVw7otWIczVFRa3uGOBSnyVDzYhqlm1RQMALcfO1EbYmGglvGA7BOBVFVqKatOdU0CSKmrHEcE57QgsLFaSTep8REwubkqR333rhQnqeC0xem/ZtiQzANczi4QQF/cA6tFhaGbCWFID3bky6HJFKAVouXviNEQN1LB/z83NKEnZVJaHjrptYoGSFqS2Jxt9eFdK0YxgEvKgvUMA1xICuKFObiQtCqIvCsn0ViVAQydQxaLCy9iQNdVg5NfR5a0JJjQQiJpaTJE+4Q6g1xNDCbV0tA7O/9xsHFhUFSTQJyrtmOC7tX0T9tAz4Upcmwd52c9jFeJcLhLCUO3Bn7NTRLWUoIiaUkfeW5UVQxGQEnF54fE32cGE4uRENGzhd7EEV6GS2UhKlYL6OjVNcxDT78G4XLvV0W463spvmTyn5i//sKfwAZzFEOBYDJ3QAAAABJRU5ErkJggg=="
                            alt=""
                          /><span>暂不支持中国大陆地区</span>
                        </div>
                      </label>
                    </div>
                    <div class="pay-type">
                      <el-radio v-model="payment" :label="1">移动支付</el-radio>
                    </div>
                  </div>
                  <div class="pay-content">
                    <div class="pay-content-payType">
                      <button
                        class="payType-item zfb-pay"
                        :class="payType === 0 && 'active'"
                        @click="payType = 0"
                      >
                        <span
                          class="payType-tips"
                          v-if="Number($gameStartState.zfbCharges) != 1"
                          >{{ $gameStartState.zfbChargesText }}</span
                        >
                        <span v-else class="payType-tips payType-tips_solid"
                          >推荐</span
                        >
                        <img :src="imgs.zfb" alt="" /> 支付宝
                      </button>
                      <button
                        class="payType-item wx-pay"
                        :class="payType === 1 && 'active'"
                        @click="payType = 1"
                      >
                        <span
                          class="payType-tips"
                          v-if="Number($gameStartState.wxCharges) != 1"
                          >{{ $gameStartState.wxChargesText }}</span
                        >
                        <img :src="imgs.wx" alt="" />微信支付
                      </button>
                    </div>
                  </div>
                </div>
                <div class="css-1ghwnnj exnh8b34">
                  <div class="css-zg1vud exnh8b33">
                    <div class="css-2s2hu2 exnh8b31">
                      支付金额：<span class="main"
                        >￥<span class="primary">{{
                          payType === 0
                            ? (88 * $gameStartState.zfbCharges).toFixed(2)
                            : (88 * $gameStartState.wxCharges).toFixed(2)
                        }}</span></span
                      >
                    </div>
                    <div class="css-rb3fhw exnh8b30">
                      支付成功即表示您同意<span
                        style="color: rgb(254, 146, 72); cursor: pointer"
                        @click="showAgreement"
                        >《购买协议》</span
                      >
                    </div>
                    <div class="pay-content-button" @click="getQrCodeForm">
                      去支付
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            tabindex="0"
            aria-hidden="true"
            style="width: 0px; height: 0px; overflow: hidden; outline: none"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { checkUser, getPayParam, getUUID } from "../common/utils";
import { EventBus } from "../common/event-bus.js";
import { userApi, homeApi } from "../api";
import md5 from "js-md5";
export default {
  name: "PayModal",
  props: {
    hidePayModal: Function,
    games: Array,
    isShowForm: Boolean,
  },
  components: {},
  data() {
    const checkPhone = (rule, value, callback) => {
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          value
        )
      ) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      payType: 0,
      showAliPayQrcode: false,
      payment: 1,
      imgs: {
        wx: require("../assets/images/wx.png"),
        zfb: require("../assets/images/zfb.png"),
      },
      payParam: {},
      form: {
        mobile: "",
      },
      rules: {
        mobile: [
          {
            validator: checkPhone,
            required: true,
            trigger: "change",
          },
        ],
      },
    };
  },

  watch: {
    payType() {
      this.showAliPayQrcode = false;
    },
  },
  methods: {
    getQrFrom() {
      const gameid = this.games.map((item) => item.gameid).join(",");
      const gamename = "88元游戏礼包";
      const platform = this.games.map((item) => item.platform).join(",");
      // 组装支付参数
      const payParam = {
        type: 0,
        gameid: gameid,
        gamename: gamename,
        num: 1,
        bShare: 1,
        paydetail: this.payType === 0 ? "alipay" : "wxpay",
        xfmoney:
          this.payType === 0
            ? (88 * this.$gameStartState.zfbCharges).toFixed(2)
            : (88 * this.$gameStartState.wxCharges).toFixed(2),
        // xfmoney: "0.01",
        lastmoney: 0,
        info: 0,
        platform,
        goodsinfo: 0,
        versionName: "",
      };
      this.payParam = {
        ...payParam,
        Uuid: this.form.mobile,
        sign: md5(this.form.mobile + "kkkuu").toUpperCase(),
      };
      // this.showAliPayQrcode = true;
      // this.$nextTick(() => this.$refs.payCode.getQrCode());
      EventBus.$emit("showPayQrcode", this.payParam, true);
    },
    userfirst(uuid) {
      let url = "";
      let arr = [];
      let urls = window.localStorage.getItem("_conversionTypes")
        ? JSON.parse(window.localStorage.getItem("_conversionTypes"))
        : "";
      if (urls) {
        url = urls && urls[0].logidUrl;
        arr = JSON.stringify(urls);
      } else {
        url = window.location.href;
        arr = JSON.stringify([{ newType: 10, logidUrl: url }]);
      }
      userApi.reqGettime().then((time) => {
        homeApi
          .reqGetUserFirst(
            this.$gameStartState.ip,
            url,
            time,
            arr,
            this.$gameStartState.belong,
            uuid,
             0,
            1
          )
          .then(() => {});
      });
    },
    getQrCodeForm() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            this.$refs.formMask.style = "animation: formTwinkle 3s linear";
            setTimeout(() => {
              this.$refs.formMask.style = "";
            }, 3000);
            return;
          } else {
            // 校验通过
            this.$refs.formMask.style = "";
            EventBus.$emit("editformMobile", this.form.mobile);
            userApi
              .reqLogin(
                "",
                0,
                "",
                this.form.mobile,
                "",
                this.$gameStartState.ip,
                this.$gameStartState.city,
                2,
                this.$gameStartState.belong
              )
              .then((res) => {
                if (!res) {
                  return this.$alert("您的账号出现异常，请联系客服处理");
                }
                let uuid =res.Uuid
                this.userfirst(uuid)
                this.getQrFrom();
              });
          }
        });
      } else {
        this.userfirst(getUUID())
        this.getQrCode();
      }
    },
    getQrCode() {
      if (!checkUser()) return;

      const gameid = this.games.map((item) => item.gameid).join(",");
      const gamename = "88元游戏礼包";
      const platform = this.games.map((item) => item.platform).join(",");
      // 组装支付参数
      const payParam = {
        type: 0,
        gameid: gameid,
        gamename: gamename,
        num: 1,
        bShare: 1,
        paydetail: this.payType === 0 ? "alipay" : "wxpay",
        xfmoney:
          this.payType === 0
            ? 88
            : (88 * this.$gameStartState.wxCharges).toFixed(2),
        // xfmoney: "0.01",
        lastmoney: 0,
        info: 0,
        platform,
        goodsinfo: 0,
        versionName: "",
      };
      this.payParam = getPayParam(payParam);
      // this.showAliPayQrcode = true;
      // this.$nextTick(() => this.$refs.payCode.getQrCode());
      EventBus.$emit("showPayQrcode", this.payParam);
    },

    paySuccess() {
      this.showAliPayQrcode = false;
      this.hidePayModal();
      this.$message({
        type: "success",
        message: "支付成功!",
      });

      EventBus.$emit("showPaySuccess", true);
    },

    showAgreement() {
      EventBus.$emit("showServicePurchaseAgreement", true);
    },
  },
};
</script>
<style scoped lang="less">
/deep/.el-form {
  position: relative;
  height: 50px;
  .icon {
    position: absolute;
    top: 30%;
    color: red;
    width: 15px;
    height: 15px;
    background-color: rgb(23, 29, 39);
    text-align: center;
    margin-right: 10px;
  }
  .mask {
    width: 220px;
    height: 30px;
    margin-left: 25px;
    position: absolute;
    top: 5px;
    opacity: 0;
    background-color: #f76058;
  }
  .el-form-item {
    margin-left: 25px;
    width: 220px;
    .el-input__inner {
      color: white;
      background-color: rgb(23, 29, 39);
      border-radius: 3px;
      border: rgb(76, 77, 77) solid 1px;
      height: 30px;
    }
    .el-form-item__error {
      margin-left: 44%;
    }
  }
}
img {
  width: auto;
  height: auto;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-centered .ant-modal {
  top: 50%;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  transform: translateY(-50%);
}
.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding: 0 0 24px;
  pointer-events: none;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-modal-close svg {
  width: 16px;
  height: 16px;
  color: #fff;
  margin-top: 5px;
  margin-right: 5px;
}
.css-1x3qht3 .ant-modal-header {
  padding: 13px 20px;
  background: rgb(18, 21, 23);
  border: none;
}
.css-1x3qht3 .ant-modal-header .ant-modal-title {
  font-size: 16px;
  color: rgb(255, 255, 255);
}
.css-1x3qht3 .ant-modal-body {
  background: rgb(34, 38, 42);
  padding: 20px;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.css-14srtnw {
  min-height: 140px;
  border: 1px solid rgb(59, 64, 70);
  display: flex;
  margin: 0px auto;
}
.css-1ooxjxi {
  width: 107px;
  height: 138px;
  border-right: 1px solid rgb(59, 64, 70);
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}
.css-17ac63r.active {
  background: rgb(64, 69, 75);
}
.css-17ac63r {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 14px;
  color: rgb(255, 255, 255);
  padding-left: 16px;
  width: 100%;
  height: 50%;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}
.css-17ac63r .payment-title {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-17ac63r img {
  margin-right: 6px;
}
.css-17ac63r {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 14px;
  color: rgb(255, 255, 255);
  padding-left: 16px;
  width: 100%;
  height: 50%;
  cursor: pointer;
  position: relative;
}
.css-17ac63r .payment-title {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-17ac63r .discount-row {
  font-size: 12px;
}
.css-17ac63r .discount-row .primary {
  color: rgb(239, 76, 37);
}
.css-1ghwnnj {
  display: flex;
  -webkit-box-align: center;
  padding-left: 16px;
  justify-content: space-between;
  height: 218px;
  box-sizing: border-box;
}
.css-1kzw5wo {
  width: 240px;
  height: 240px;
  background: rgb(255, 255, 255);
  /* padding: 5px; */
  font-size: 0px;
  margin: 10px;
  margin-left: 0;
  position: relative;
}
.qr-mask {
  position: relative;
  width: 240px;
  height: 240px;
  text-align: center;
  line-height: 240px;
  cursor: pointer;
  background-image: url("https://imgcloud.huifangkuai.com/Display/qr1.png");
  background-size: contain;
  opacity: 0.8;
}
.css-zg1vud {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px 20px 0;
  box-sizing: border-box;
}
.css-2s2hu2 {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  line-height: 14px;
  color: rgb(255, 255, 255);
}
.css-2s2hu2 .main {
  color: rgb(255, 223, 103);
}
.css-2s2hu2 .primary {
  font-size: 24px;
  line-height: 24px;
}
.css-rb3fhw {
  font-size: 12px;
  margin-top: 16px;
  color: rgb(184, 184, 184);
}
.css-rb3fhw .link {
  cursor: pointer;
}
.inHCcR .pay-content-qrcode .qrcode-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.ellipsis {
  line-height: auto;
  background-image: linear-gradient(270deg, #cc3930, #f95244);
}
.cvOASq.ml {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 91px;
  height: 36px;
  font-size: 16px;
  line-height: 33px;
  font-weight: 500;
  border-radius: 8px;
  padding: 0 4px;
  letter-spacing: 0;
}
.cvOASq.ml:hover {
  opacity: 0.8;
}
</style>

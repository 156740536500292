<template>
  <el-dialog
    title="投诉"
    :visible.sync="complaintIsShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item prop="text">
        <el-input
          type="textarea"
          placeholder="至少输入五个字"
          :rows="5"
          v-model="form.text"
        ></el-input>
      </el-form-item>
      <el-form-item prop="phone">
        <el-input
          v-model="form.phone"
          placeholder="填写您的手机号，我们会尽快回复您"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <!-- <img src="../assets/images/callphone.png" alt="" />
      <div class="call_text">
        <p>我们的电话166 0182 2228</p>
        <p>快速解决您的问题</p>
      </div> -->
      <el-button class="submit_btn" type="primary" @click="submit"
        >提交投诉</el-button
      >
      <el-button class="cancal_btn" @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ["complaintIsShow"],
  data() {
    const checkPhone = (rule, value, callback) => {
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          value
        )
      ) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      form: {
        text: "",
        phone: "",
      },
      rules: {
        text: [
          {
            required: true,
            message: "请输入您的问题，且不少于五个字",
            trigger: "blur",
            min: 5,
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入正确的手机号",
            trigger: "blur",
            validator: checkPhone,
          },
        ],
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit("closeComplaint");
    },
    submit() {
      this.$refs.ruleForm.validate((check) => {
        if (check) {
            this.$message({message:"投诉成功",type:"success"})
            this.$refs.ruleForm.resetFields()
          this.$emit("closeComplaint");
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
img{width: auto; height: auto;}
/deep/.el-dialog {
  width: 600px;
}
/deep/.el-dialog__headerbtn{
    top: 10px;
    right: 10px;
}
/deep/.el-dialog__title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 10px;
  color: #aaa;
}
/deep/.el-dialog__body {
  padding: 20px 20px 0 20px;
  height: 220px !important;
  overflow-x: hidden; overflow-y: auto;
}
/deep/.el-textarea__inner {
  background-color: #424553;
  border: none;
  color: #fff;
  &::placeholder {
    color: #aaa;
  }
}
/deep/.el-input__inner {
  background-color: #424553 !important;
  border: none !important;
  color: #fff;
  &::placeholder {
    color: #aaa;
  }
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .call_text {
    width: 200px;
    margin-left: 5px;
    text-align: left;
  }
  .submit_btn {
    width: 120px;
    height: 40px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d9d9d9;
  }
  .cancal_btn {
    margin-left: 20px;
    height: 40px;
  }
}
</style>


import Vue from "vue";
import VueRouter from "vue-router";
import { getUser, getQueryObject, setLocalOcpc, setLocalOcpcForm } from '../common/utils'
import { EventBus } from '../common/event-bus'

// 引入组件
import Home from "../views/Home.vue";
import Gamecenter from "../views/Gamecenter.vue";
import Gamedetail from "../views/Gamedetail";
import MyAssets from '../views/MyAssets.vue';
import MemberCenter from '../views/MemberCenter.vue'
import GameCombination from '../views/GameCombination.vue'
import Nameplate from '../views/Nameplate.vue'
import H5 from '../views/h5.vue'
import mGameCenter from '../views/mGameCenter.vue'
import mGamedetail from '../views/mGamedetail.vue'
import mHome from '../views/mHome.vue'
import mMine from '../views/mMine.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: "/gamecenter",
    component: Gamecenter
  },
  {
    path: "/gamedetail/:platform/:id",
    component: Gamedetail
  },
  {
    path: "/myassets",
    component: MyAssets
  },
  {
    path: "/membercenter",
    component: MemberCenter
  },
  {
    path: "/gamecombination",
    component: GameCombination
  },
  {
    path: "/nameplate",
    component: Nameplate
  },
  {
    path: '/h5',
    component: H5
  },
  // 移动端游戏中心页面
  {
    path:"/mGameCenter",
    component:mGameCenter,
    name:"mGameCenter"
  },
  // 移动端游戏中心首页
  {
    path:"/mHome",
    component:mHome,
    name:"mHome"
  },
  // 移动端我的页面
  {
    path:"/mMine",
    component:mMine,
    name:"mMine"
  },
  // 移动端游戏详情页面
  {
    path:"/mGamedetail/:platform/:id",
    component:mGamedetail,
  }
]

var router = new VueRouter({
  // base: process.env.NODE_ENV === 'production' ? './' : '/',
  mode: 'history',
  // mode: 'hash',
  routes
})


// 全局路由守卫
router.beforeEach((to, form, next) => {
  let params = getQueryObject()
  console.log(params)
  let local1 = window.localStorage.getItem("_conversionTypes") ? JSON.parse(window.localStorage.getItem("_conversionTypes")) : ""
  let local2 = window.localStorage.getItem("_conversionFormTypes") ? JSON.parse(window.localStorage.getItem("_conversionFormTypes")) : ""
  if (local1) {
    if (!local1[0].logidUrl.includes("bd_vid")) {
      setLocalOcpc(window.location.href)
    }
  } else {
    setLocalOcpc(window.location.href)
  }
  if (local2) {
    if (!local2[0].logidUrl.includes("bd_vid")) {
      setLocalOcpcForm(window.location.href)
    }
  } else {
    setLocalOcpcForm(window.location.href)
  }
  if(params.bd_vid){
    setLocalOcpc(window.location.href)
    setLocalOcpcForm(window.location.href)
  }


  // 判断登录逻辑
  const nextRoute = ['/myassets'] // 需要登录的页面
  const user = getUser()  // 判断是否登录，本地存储有用户数据则视为已经登录
  // 未登录状态；当路由到 nextRoute 指定页时，显示登录页面并阻止跳转
  if (nextRoute.indexOf(to.path) >= 0) { // 检测是否登录的页面
    if (!user) { // 如果未登录（本地存储无用户数据），并且要弹出登录页面
      next(false)
      EventBus.$emit("showLogin", true)
      return
    }
  } else {
    if (user) {
      EventBus.$emit("checkLogin", true)
    }
  }

  next() // 必须使用 next ,执行效果依赖 next 方法的调用参数
})

export default router;
<template>
  <div class="pay-wrapper">
    <div
      class="groupnews_insideL"
      id="qrcode"
      v-html="htmlDom"
      ref="payBox"
      v-show="!loading"
    ></div>
    <div
      class="groupnews_insideL qrcode-mask"
      v-show="loading"
      :style="`width: 240px;height: 240px;background-image: url('https://imgcloud.huifangkuai.com/Display/qr1.png');background-size: contain;`"
    >
      <div class="loading-wrapper">
        <i class="iconfont loading">&#xe6ac;</i>
        <span>请耐心等待{{ count }}秒...</span>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { payApi, homeApi, userApi } from "../api";
import { OCPC_TOKEN } from "../common/config.json";
import { getUUID, getSign } from "../common/utils";

export default {
  name: "PayCode",
  props: {
    payParam: Object,
    paySuccess: Function,
  },
  data() {
    return {
      htmlDom: "",
      loading: false,
      qr: require("../assets/images/qr.png"),
      count: "",
      OCPC_TOKEN,
      timer: null,
      timer2: null,
    };
  },
  methods: {
    getCode() {
      var _this = this;
      var TIME_COUNT = 5;
      // if (!this.window.timer) {
      this.count = TIME_COUNT;
      this.window.timer = setInterval(() => {
        if (_this.count > 0 && _this.count <= TIME_COUNT) {
          _this.count--;
        } else {
          window.window.clearInterval(_this.window.timer);
          _this.window.timer = null;
          _this.loading = false;
        }
      }, 1000);
      // }
    },
    getQrCode() {
      // 获取可用的支付接口, 根据返回的信息调用支付服务
      // this.loading = true;
      // this.getPayType((payInterface) => this[`payService_${payInterface}`]());
      // this.getCode();

      // H5支付方式
      this.getPayTypeH5();
      // this.postOcpc("gm6206071959678");
    },

    getPayTypeH5() {
      // const BASE_H5_URL = window.location.origin + "/#/h5";
      const BASE_H5_URL = window.location.origin + "/h5";
      const url = `${BASE_H5_URL}`;
      console.log(url);
      this.$refs.payBox.innerHTML = "";
      userApi.reqGettime().then((time) => {
        this.payParam.paytime = time;

        // let h5obj ={}
        // if(this.payParam.paydetail==="alipay"){
        //支付宝参数
        const h5obj = {
          Uuid: this.payParam.Uuid,
          sign: this.payParam.sign,
          type: this.payParam.type,
          gameid: this.payParam.gameid,
          num: this.payParam.num,
          bShare: this.payParam.bShare,
          paydetail: this.payParam.paydetail,
          xfmoney: this.payParam.xfmoney,
          lastmoney: this.payParam.lastmoney,
          info: this.payParam.info,
          gamename: this.payParam.gamename,
          platform: this.payParam.platform,
          paytime: time,
          ipaddress: this.$gameStartState.ip,
          goodsinfo: this.payParam.goodsinfo,
          versionName: "标准版",
          jsqname: this.payParam.jsqname,
          speedtime: this.payParam.speedtime,
        };
        userApi
          .reqOppay({ flag: 0, url: JSON.stringify(h5obj), type: "" })
          .then((res) => {
            this.payParam.pid = res.pid;
            let qrurl = url + "?pid=" + res.pid;
            // let qrurl = url+"/#/" + "?pid=" + res.pid;
            // 本地调试需更换域名
            // let qrurl = "https://stm.zzzzu.com/h5?pid=" + res.pid;
            console.log(qrurl);
            new QRCode(this.$refs.payBox, {
              text: qrurl, // 需要转换为二维码的内容
              width: 160,
              height: 160,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: 3,
            });
            this.$nextTick(() => {
              this.payCallBack_JK();
              const el = document.querySelector("#qrcode");
              el && (el.title = "");
            });
          });
      });
    },
    // 获取当前可用的支付接口
    getPayType(fn) {
      payApi.reqGetPayType(getUUID(), 0, getSign()).then((res) => {
        fn(res.msg);
      });
    },

    // 支付服务1(把返回的表单放到ifarme中, 只显示二维码)
    payService_TBK() {
      userApi.reqGettime().then((time) => {
        this.payParam.paytime = time;
        payApi
          .reqPayoptbk({
            ...this.payParam,
            ipaddress: this.$gameStartState.ip,
          })
          .then((res) => {
            // console.log(res)

            this.loading = false;
            this.htmlDom = `<iframe id="jin10" width="240" height="600" frameborder="0" 
          style="position:relative; top:-215px; float:left; left:0;transform: translate(-2px, 1px);" 
          scrolling="no" srcdoc='' name="jin10">
        </iframe>`;
            const formText = res.replace(
              "http://pay.81ka.cn/submit.php",
              "https://stm.zzzzu.com/proxy/"
            );
            this.$nextTick(() => {
              document.querySelector("#jin10").setAttribute("srcdoc", formText);
            });

            // 二维码生成后监听支付成功回调
            this.payCallBack_TBK();
          });
      });
    },

    // 支付服务2(返回的是网络链接)
    payService_JK() {
      // 第二个接口金额单位是分需要处理一下
      const { xfmoney } = this.payParam;
      const newXfmoney = xfmoney * 100;
      const paydetail = this.payParam.paydetail === "alipay" ? 1 : 2;

      userApi.reqGettime().then((time) => {
        this.payParam.paytime = time;
        console.log(this.payParam, "jk");
        payApi
          .reqJkpayop({
            ...this.payParam,
            ipaddress: this.$gameStartState.ip,
            xfmoney: newXfmoney,
            paydetail,
            city: this.$gameStartState.city,
          })
          .then((res) => {
            // console.log(res);
            const tradeNo = res.split("=")[1];

            // 获取链接参数订单号, 调用支付宝和微信对应接口
            if (this.payParam.paydetail === "alipay") {
              // 支付宝返回的表单放到ifarme中, 只显示二维码
              payApi.reqJkopdetailaplipay(tradeNo).then((res) => {
                this.htmlDom = `<iframe id="jin10" width="240" height="600" frameborder="0"
              style="position:relative; top:0; float:left; left:0; width: 900px; height: 900px; transform: translate(-354px, -210px);"
              scrolling="no" srcdoc='' name="jin10">
            </iframe>`;
                this.$nextTick(() => {
                  document.querySelector("#jin10").setAttribute("srcdoc", res);
                });

                // 二维码生成后监听支付成功回调
                this.payCallBack_JK();

                let timer = setTimeout(() => {
                  this.loading = false;
                  clearTimeout(timer);
                }, 4000);
              });
            } else if (this.payParam.paydetail === "wxpay") {
              // 微信返回的内容生成二维码
              payApi.reqJkopdetailwxpay(tradeNo).then((res) => {
                new QRCode(this.$refs.payBox, {
                  text: res, // 需要转换为二维码的内容
                  width: 240,
                  height: 240,
                  colorDark: "#000000",
                  colorLight: "#ffffff",
                  correctLevel: QRCode.CorrectLevel.H,
                });
              });

              // 二维码生成后监听支付成功回调
              this.payCallBack_JK();
              this.loading = false;
            }
          });
      });
    },

    // 监听支付成功回调-TBK
    payCallBack_TBK() {
      window.addEventListener("message", (e) => {
        // console.log(e)
        const { type } = e.data;
        if (type === "PAY_TBK") {
          this.paySuccess();
          // window.removeEventListener("message");
        }
      });
    },

    oneMoreTime() {
      // console.log("重新开始");
      this.payCallBack_JK();
    },

    // 监听支付成功回调-JK
    payCallBack_JK() {
      // window.clearInterval(window.timer);
      let count = 0;
      var paytime;
      // 轮询支付成功状态
      window.timer = setInterval(() => {
        console.log(window.timer);
        userApi
          .reqOppay({
            flag: 1,
            pid: this.payParam.pid,
            type: this.payParam.paydetail,
          })
          .then((res) => {
            // console.log("获取时间");
            var _res = res.data;
            if (_res.xfdate) {
              paytime = _res.xfdate;
              payApi
                .reqJkweblunxun(
                  this.payParam.Uuid,
                  0,
                  this.payParam.sign,
                  paytime
                )
                .then((res) => {
                  // console.log("获取单号");
                  if (res.orderNo) {
                    window.clearInterval(window.timer);
                    count = 0;
                    window.timer2 = setInterval(() => {
                      console.log(window.timer2);
                      userApi
                        .reqOppay({
                          flag: 1,
                          pid: this.payParam.pid,
                          type: this.payParam.paydetail,
                        })
                        .then((res2) => {
                          var _res2 = res2.data;
                          if (paytime == _res2.xfdate) {
                            payApi
                              .reqJklunxun(
                                this.payParam.Uuid,
                                0,
                                this.payParam.sign,
                                res.orderNo
                              )
                              .then((res3) => {
                                // console.log("获取状态");
                                // console.log(res);
                                if (res3.msg === 1) {
                                  window.clearInterval(window.timer);
                                  window.clearInterval(window.timer2);
                                  this.paySuccess();
                                  this.postOcpc(res.orderNo);
                                } else if (count >= 60) {
                                  window.clearInterval(window.timer);
                                  window.clearInterval(window.timer2);
                                } else {
                                  count++;
                                }
                              });
                          } else {
                            // console.log("更新时间！");
                            window.clearInterval(window.timer);
                            window.clearInterval(window.timer2);
                            this.oneMoreTime();
                          }
                        });
                    }, 5000);
                  } else if (count >= 60) {
                    window.clearInterval(window.timer);
                  } else {
                    count++;
                  }
                });
            }
          });
      }, 5000);
    },

    postOcpc(selfbill) {
      homeApi.reqXfBdvid(this.$gameStartState.ip, selfbill).then((res) => {
        if (res.data.length > 0) {
          if (res.data[0].bmake === 0) {
            if (res.data[0].bdvid) {
              let arr = JSON.parse(res.data[0].bdvid);
              let domain = arr[0].logidUrl;
              homeApi
                .reqOcpc(
                  arr,
                  this.$gameStartState.ip,
                  domain,
                  selfbill,
                  getUUID()
                )
                .then(() => {});
            }
            // if (
            //   res.data[0].bdvid.includes("stm.qiaohai68.cn") ||
            //   res.data[0].bdvid.includes("steam.uuuzz.com")
            // ) {
            //   token =
            //     "EjgafU4AFv4K4tqftnhzNM9pU0ZHGGIn@fbdbRwGbyImOYaFYRZ16DgFjVuzw1hqC";
            // } else if (res.data[0].bdvid.includes("stm.shenyi06.cn")) {
            //   token =
            //     "K3iGZGYwTRQlB3qixcOjQGFjj7NELvMQ@vVOQdDy7c581t0VvqwXHRb0GOwRz2HTA";
            // } else if (res.data[0].bdvid.includes("stm.bangyoue.cn")) {
            //   // 搜狗
            //   token = "2d1e68f736021c753636d5e2fb808d54";
            // } else if (res.data[0].bdvid.includes("stm.shenyi08.cn")) {
            //   // 百度
            //   token =
            //     "gwpuqXuVvsPwwuA4uV0GHrAstdSHYbPE@7orF6G7P8FaYcdmZ0oXlUgG2KgG5w1zo";
            // }
            // else if (res.data[0].bdvid.includes("games.yjtkeji.com")){
            //   token="xh9ASYDP4SgyCSHEI3zGIrHvqz6f3lrW@2l7hdQ2IT73IneHIQNXmElUjxirBu85t"
            // }
            // else if (res.data[0].bdvid.includes("vip.yjtkeji.com")){
            //   token="mGrmm3N2PKo90XA0svi1wThZO8n9uEAu@YDialRkIqpNO8wyvAcYXq8tinGODxjIy"
            // }
            // // else if (res.data[0].bdvid.includes("stem.bangyoue.cn")){
            // //   // 360
            // //   token=""
            // // }
            // // 百度
            // else if (res.data[0].bdvid.includes("evip.hflxjy.cn")){
            //   token="EUsuINexEGqv5wOFAscqf86jyiObgOXh@vcKH3ER5TFfmBa8naYtecOOvbtgDcBmj"
            // }
            // else if (res.data[0].bdvid.includes("xg1.yjtkeji.com")){
            //   token="quTpUwhztdvyjaDQhNbosoDpo35yw0AH@PDSKpWepHf1GiykzGINHidGeWSSTVYNt"
            // }
            //  else if (res.data[0].bdvid.includes("svip.z01x02.top")){
            //   token="oaBLlYWYssSTTgqINQN2jvFvrF3keXz7@kbb3CLsON8AeOtDMUSdNGgaKSLu5pd21"
            // }
            //  else if (res.data[0].bdvid.includes("svip.hfgchl.cn")){
            //   token="W6pBSvB4u5b0brxUWH1z2p24CrOGXMHV@p2GVcWGzBM292668R6uwYk2dfV2i90iV"
            // }
            // else if (res.data[0].bdvid.includes("svip.benhongwl.cn")){
            //   token="UR178DLYmfOaW0yZvAGbadLYk3S7YZYu@1M80P6RuOnBZoDmRNHBNBPYAi9CH8rkp"
            // }
            // else if (res.data[0].bdvid.includes("svip.anhuixj.top")){
            //   token="xXDt0TV2Qvz2xMRlhGk8vGNDKrcQjbT7@dT5HMmfkhrcMPezKAR1GlZ3WQPVxhXOq"
            // }
            // else if (res.data[0].bdvid.includes("svip.yasuorj.com")){
            //   token="DVr8eWdpgpPgTKKn3Qy7atOYwG7hbzMk@VZtqEaWKvktc6mrdSlgIYs4ryYCwvvIt"
            // }
            // else if (res.data[0].bdvid.includes("stm.zjbryy.cn")){
            //   token="Eb61kYLnYxXRQTAf1vyySAobh58I7cNr@PqVFwjyooZjvt1ytIdu8szHzp3h6Gjgc"
            // }
            // else if (res.data[0].bdvid.includes("stram.zjbryy.cn")){
            //   token="UuNg6i49yhdHQkPpG664tHLGZnxXhDhK@zLcvbwQ5aWWT3P5kmVouRQKxeSuhGdS0"
            // }
            //  else {
            //   // 其他域名
            //   token =
            //     "qkIyV1pqh8sY2kp6w6SLqNDWkwxsaA92@CwpFa0xxg3B1Ng2sNpjQrdaDhn2UGteK";
            // }
          }
        }
        // }
      });
    },
  },
};
</script>
<style scoped>
.pay-wrapper {
  width: 240px;
  height: 240px;
  overflow: hidden;
  position: relative;
}
.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading {
  font-size: 88px;
  color: #d94036;
  animation: rotation 1s linear infinite;
  -moz-animation: rotation 1s linear infinite;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
}
.loading-wrapper span {
  color: #d94036;
  font-size: 14px;
  background-color: #000000;
  border-radius: 2px;
  padding: 2px 10px;
  margin-top: 22px;
}
@keyframes rotation {
  form {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
#qrcode {
  padding: 5px;
  background-color: #fff;
}
</style>

/* user请求模块 */
import ajax from '../ajax.js'
import { BASE_REQUEST_URL_DEV, BASE_REQUEST_URL_PROD } from '../../common/config.json'
const BASE_REQUEST_URL = process.env.NODE_ENV === 'production' ? BASE_REQUEST_URL_PROD : BASE_REQUEST_URL_DEV
// import {  BASE_REQUEST_URL_PROD,BASE_REQUEST_URL_TEST } from '../../common/config.json'
// const BASE_REQUEST_URL = process.env.NODE_ENV === 'production' ? BASE_REQUEST_URL_PROD : BASE_REQUEST_URL_TEST
// 发送短信验证码
const reqSendCode = (yzm,Uuid, page, sign, mobile) => ajax(`${BASE_REQUEST_URL}/msg`, {yzm, Uuid, page, sign, mobile }, 'POST');

// 登录
const reqLogin = (Uuid, page, sign, mobile, computer,ipaddress ,city,type = 2,belong,tg_url) => ajax(`${BASE_REQUEST_URL}/mlogin`, { Uuid, page, sign, mobile, computer,ipaddress ,type,city ,belong,tg_url}, 'POST');
const reqUpdateLogin = (Uuid, page, sign, mobile, computer, ipaddress, city, type = 1, belong,deviceid,firsturl) => ajax(`${BASE_REQUEST_URL}/updateMloinInfo`, { Uuid, page, sign, mobile, computer, ipaddress, type, city, belong,deviceid,firsturl }, 'POST');
const reqUpdateWxLogin = (Uuid, page, sign, mobile, computer, ipaddress, city, type = 1, belong,deviceid,firsturl) => ajax(`${BASE_REQUEST_URL}/updateWxloginInfo`, { Uuid, page, sign, mobile, computer, ipaddress, type, city, belong,deviceid,firsturl }, 'POST');

// 微信登录
const reqWxLogin = (Uuid, page, sign, code, computer,ipaddress ,city,type = 2,belong,tg_url) => ajax(`${BASE_REQUEST_URL}/wxlogin`, { Uuid, page, sign, code, computer,ipaddress,city ,type ,belong,tg_url});

// 校验用户登录状态
const reqUserlogcheck = (Uuid) => ajax(`${BASE_REQUEST_URL}/userlogcheck`, { Uuid }, 'POST');

// 请求用户详细信息
const reqUserInfo = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/getuserinfo`, { Uuid, page, sign });

// 修改用户信息
const reqUpdateUserInfo = (Uuid, page, sign, username, address) => ajax(`${BASE_REQUEST_URL}/updateuserinfo`, { Uuid, page, sign, username, address })

// 用户地址列表
const reqGetUserAddress = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/getuseraddress`, { Uuid, page, sign });

// 设为地址默认
const reqSetAddress = (Uuid, page, sign, id, address) => ajax(`${BASE_REQUEST_URL}/setaddress`, { Uuid, page, sign, id, address });

// 添加/修改地址
const reqOpuserAddress = (Uuid, page, sign, address) => ajax(`${BASE_REQUEST_URL}/opuseraddress`, { Uuid, page, sign, ...address });

// 会员中心
const reqMemeber = (Uuid, page, sign, gamename) => ajax(`${BASE_REQUEST_URL}/memeber`, { Uuid, page, sign, gamename }, 'POST');

// 我的游戏库
const reqUsergame = (Uuid, page, sign, gamename) => ajax(`${BASE_REQUEST_URL}/usergame`, { Uuid, page, sign, gamename }, 'POST');

// 我的钱包和订单
const reqGetPayInfo = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/getpayinfo`, { Uuid, page, sign }, 'POST');

// 实名认证
const reqCertification = (Uuid, page, sign, cardname, cardno) => ajax(`${BASE_REQUEST_URL}/certification`, { Uuid, page, sign, cardname, cardno }, 'POST');

// 获取用户订单详情
const reqGetPaydetail = (Uuid, page, sign, selfbill,cdkcode) => ajax(`${BASE_REQUEST_URL}/getPaydetail`, { Uuid, page, sign, selfbill,cdkcode }, 'POST')

// 下载更新
const reqUploads = (version) => ajax(`${BASE_REQUEST_URL}/getbanben`, {version}, 'POST')

// 获取服务器时间
const reqGettime = () => ajax(`${BASE_REQUEST_URL}/gettime`)

// 提交用户浏览的游戏
const reqUserHistory=(uuid,gameid,platform,url,ipaddress)=>ajax(`${BASE_REQUEST_URL}/userHistory`,{uuid,gameid,platform,url,ipaddress},"POST")
// 获取用户是否为svip
const reqGetUserIsSvip= (params) => ajax(`${BASE_REQUEST_URL}/getVip`, params)
// 提交支付url和获取pid
// flag 0提交url  返回给你pid  
// flag 1提交pid 返回给你结果
const reqOppay = (params) => ajax(`${BASE_REQUEST_URL}/oppay`, params)

const checkyzm = (yzm) =>ajax(`${BASE_REQUEST_URL}/checkyzm?yzm=${yzm}`,{}, 'GET');
const generateCaptcha = () =>ajax(`${BASE_REQUEST_URL}/generateCaptcha`, {}, 'POST');

export default {
  checkyzm,
  generateCaptcha,
  reqSendCode,
  reqLogin,
  reqWxLogin,
  reqUserlogcheck,
  reqUserInfo,
  reqUpdateUserInfo,
  reqGetUserAddress,
  reqSetAddress,
  reqOpuserAddress,
  reqMemeber,
  reqUsergame,
  reqGetPayInfo,
  reqCertification,
  reqGetPaydetail,
  reqUploads,
  reqGettime,
  reqOppay,
  reqUserHistory,
  reqGetUserIsSvip,
  reqUpdateWxLogin,
  reqUpdateLogin
}
<template>
  <div
    tabindex="-1"
    class="ant-modal-wrap ant-modal-centered"
    role="dialog"
    style="z-index: 1007"
  >
    <div
      role="document"
      class="ant-modal css-uif7s8 e1lu69xk0"
      style="width: 706px; height: 730px"
    >
      <div
        tabindex="0"
        aria-hidden="true"
        style="width: 0px; height: 0px; overflow: hidden; outline: none"
      ></div>
      <div class="ant-modal-content">
        <button
          type="button"
          aria-label="Close"
          class="ant-modal-close"
          @click="hadeChoiceGameFn"
        >
          <span class="ant-modal-close-x">
            <span
              role="img"
              aria-label="close"
              class="anticon anticon-close ant-modal-close-icon"
            >
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                class=""
                data-icon="close"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
                ></path>
              </svg>
            </span>
          </span>
        </button>
        <div class="ant-modal-body">
          <div class="css-1bntj9o e1w3ytqh30" style="align-items: flex-end">
            <div class="css-1fgrwst e1w3ytqh31">
              <img src="../assets/images/eb52815e.png" draggable="false" />
            </div>
            <div class="css-t9u26m e1w3ytqh29">
              <span
                class="
                  ant-input-search
                  ant-input-search-enter-button
                  ant-input-group-wrapper
                "
              >
                <span class="ant-input-wrapper ant-input-group">
                  <input
                    placeholder="请搜索游戏名称"
                    style="width: 180px; box-sizing: border-box"
                    class="ant-input"
                    type="text"
                    value=""
                    v-model="searchText"
                  />
                  <span class="ant-input-group-addon">
                    <button
                      type="button"
                      style="position: absolute; top: 4px; right: 16px"
                      class="ant-btn ant-input-search-button ant-btn-primary"
                      @click="searchData"
                    >
                      <span>搜 索</span>
                    </button>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div class="css-1qen36e e1w3ytqh1">
            <div
              class="css-1vl3wo1 e1w3ytqh21"
              :class="i === selectGameIndex && 'active'"
              v-for="(item, i) in games"
              :key="item.id"
              @click="selectGameIndex = i"
            >
              <div class="tag">
                <img draggable="false" src="../assets/images/0f666652.png" />
              </div>
              <img
                draggable="false"
                :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.list}`"
              />
              <div class="itemInfo">
                <div class="name">{{ item.gamename_zh }}</div>
                <div class="price">
                  价值：<span class="primary">￥{{ item.money }}</span>
                </div>
              </div>
            </div>
            <ul
              class="ant-pagination pagination mini"
              unselectable="unselectable"
            >
              <el-pagination
                background
                layout="prev, pager, next"
                :page-count="totalPage"
                :current-page="currentPage"
                @current-change="toPage"
              >
              </el-pagination>
            </ul>
            <div class="css-1ksz97y e1w3ytqh0" @click="handleSelect">确定</div>
          </div>
        </div>
      </div>
      <div
        tabindex="0"
        aria-hidden="true"
        style="width: 0px; height: 0px; overflow: hidden; outline: none"
      ></div>
    </div>
  </div>
</template>

<script>
import { homeApi } from "../api";
import { BASE_IMAGE_URL } from "../common/config.json";
import { getUUID, getSign, getTotalPage } from "../common/utils";

export default {
  name: "ChoiceGame",
  props: {
    hadeChoiceGameFn: Function,
    selectedGameFn: Function,
  },
  data() {
    return {
      BASE_IMAGE_URL,
      selectGameIndex: null,
      currentPage: 1,
      totalPage: 0,
      games: [],
      searchText: "",
    };
  },
  mounted() {
    this.loadData(this.currentPage);
  },
  methods: {
    loadData(page, search = "") {
      homeApi.reqAllgame(getUUID(), page, getSign(), search).then((res) => {
        this.games = res.list;
        this.num = res.num[0]["count(*)"];
        this.totalPage = getTotalPage(this.num, 9);
      });
    },

    handleSelect() {
      if (this.selectGameIndex === null) {
        return;
      }
      this.hadeChoiceGameFn();
      this.selectedGameFn(this.games[this.selectGameIndex]);
    },

    toPage(pageNum) {
      this.currentPage = pageNum;
      this.loadData(pageNum, this.searchText);
      this.selectGameIndex = null;
    },

    searchData() {
      this.currentPage = 1
      this.loadData(this.currentPage, this.searchText);
      this.selectGameIndex = null;
    },
  },
};
</script>

<style scoped>
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-centered {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-centered:before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding: 0;
  pointer-events: none;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 12%),
    0 6px 16px 0 rgba(0, 0, 0, 8%), 0 9px 28px 8px rgba(0, 0, 0, 5%);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 12%), 0 6px 16px 0 rgba(0, 0, 0, 8%),
    0 9px 28px 8px rgba(0, 0, 0, 5%);
  pointer-events: auto;
  height: 100%;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.css-uif7s8 .ant-modal-body {
  background: rgb(34, 38, 42);
  padding: 20px;
  height: 100%;
  overflow: hidden;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.css-1bntj9o {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.css-1fgrwst {
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: 500;
  color: rgb(254, 246, 210);
  margin-bottom: 8px;
  width: 399px;
}
.css-t9u26m {
  width: 255px;
  height: 38px;
  margin-bottom: 26px;
  background: url("../assets/images/60bb448b.png") 0% 0% / contain no-repeat;
}
.ant-input-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: initial;
  border-spacing: 0;
}
.css-t9u26m .ant-input {
  width: 180px;
  height: 38px;
  background-color: transparent;
  border-color: transparent;
  color: rgb(155, 155, 155);
  opacity: 0.9;
  font-weight: 500;
}
.ant-modal-content .ant-input {
  color: rgb(255, 255, 255);
  border-color: transparent;
  background-color: transparent;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  padding-top: 4px;
  padding-right: 11px;
  padding-bottom: 4px;
  padding-left: 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.css-t9u26m .ant-input-group-addon {
  background-color: transparent;
  padding-right: 5px !important;
}
.ant-input-group-addon:last-child,
.ant-input-group-addon:last-child .ant-select .ant-select-selector,
.ant-input-group > .ant-input:last-child,
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-search-enter-button
  + .ant-input-group-addon
  .ant-input-search-button,
.ant-input-search-enter-button
  input
  + .ant-input-group-addon
  .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.css-t9u26m .ant-btn {
  width: 58px;
  height: 30px;
  color: rgb(61, 39, 39);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-color: transparent;
  background: url("../assets/images/7bcc5327.png") 0% 0% / contain no-repeat;
}
.ant-btn {
  margin-left: -15px;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 2%);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 2%);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 4px15px;
  padding-top: 4px;
  padding-right: 15px;
  padding-bottom: 4px;
  padding-left: 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border: 1px solid #d9d9d9;
}
.ant-btn-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 12%);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 5%);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 5%);
}
.ant-btn > span {
  display: inline-block;
}

.css-1qen36e {
  display: flex;
  flex-wrap: wrap;
}
.css-1vl3wo1 {
  width: 206px;
  height: 166px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  border: 2px solid transparent;
}
.css-1vl3wo1.active {
  border: 2px solid rgb(241, 71, 0);
  overflow: hidden;
}
.css-1vl3wo1.active .tag {
  position: absolute;
  top: -20px;
  left: -20px;
  transform: rotate(45deg);
  display: block;
  width: 40px;
  height: 40px;
  background: rgb(241, 71, 0);
}
.css-1vl3wo1.active .tag img {
  width: 18px;
  height: 12px;
  position: absolute;
  bottom: 15px;
  transform: rotate(-45deg);
  left: 24px;
}
.css-1vl3wo1 .tag {
  display: none;
}
.css-1vl3wo1 img {
  height: 116px;
  width: 100%;
}
.css-1vl3wo1 img {
  height: 116px;
  width: 100%;
}
.css-1vl3wo1 .itemInfo {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 50px;
  flex: 1;
  padding: 0px 12px;
  font-size: 14px;
  background: rgb(31, 27, 23);
  color: rgb(217, 204, 185);
  margin-top: -10px;
  transform: translateY(6px);
}
.css-1vl3wo1 .itemInfo .name {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  margin-top: 4px;
}
.css-1vl3wo1 .itemInfo .price {
  font-size: 14px;
  color: rgb(254, 246, 210);
  font-weight: 200;
}
.css-1vl3wo1 .itemInfo .primary {
  color: rgb(254, 246, 210);
  margin-left: 6px;
}
.css-1qen36e .pagination {
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px auto;
}
.ant-pagination,
.ant-pagination ol,
.ant-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
}
.css-1qen36e .pagination .ant-pagination-next,
.css-1qen36e .pagination .ant-pagination-prev {
  background: rgb(64, 65, 66);
  font-size: 14px;
  margin-left: 6px;
}
.css-1qen36e .pagination .ant-pagination-item.ant-pagination-item-active {
  background: transparent;
  border-color: rgb(241, 71, 0) !important;
}
.ant-pagination.mini .ant-pagination-jump-next,
.ant-pagination.mini .ant-pagination-jump-prev {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.css-1qen36e .pagination .ant-pagination-next,
.css-1qen36e .pagination .ant-pagination-prev {
  background: rgb(64, 65, 66);
  font-size: 14px;
  margin-left: 6px;
}
.ant-pagination.mini .ant-pagination-next,
.ant-pagination.mini .ant-pagination-prev {
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}
.css-1qen36e .pagination .ant-pagination-item {
  margin-left: 6px;
  min-width: 22px;
  height: 22px;
  border: 1px solid rgb(64, 65, 66) !important;
  text-align: center;
}
.css-1qen36e .pagination .ant-pagination-item-ellipsis {
  color: rgb(64, 65, 66);
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  padding-top: 0px;
  padding-right: 6px;
  padding-bottom: 0px;
  padding-left: 6px;
  /* color: rgba(0,0,0,.65); */
  -webkit-transition: none;
  transition: none;
  cursor: pointer;
  font-size: 14px;
  color: rgb(184, 184, 184);
}
.ant-pagination:after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: " ";
}
.css-1ksz97y {
  width: 58px;
  height: 30px;
  color: rgb(61, 39, 39);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  border-color: transparent;
  background: url("../assets/images/7bcc5327.png") 0% 0% / contain no-repeat;
  margin: 10px auto 0px;
}

.ant-modal-close{
  color: #fff;
  right: 10px;
  top: 10px;
  font-size: 16px;
}
</style>

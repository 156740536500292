/*
ajax 函数请求模块
返回值: promise对象(异步返回的数据是response.data)
*/
import axios from "axios";
axios.defaults.timeout = 30000
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = false

// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json' //  注意：设置很关键 
    }
    if(window.gameStartState.ip=="116.179.37.216")return
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

export default function ajax(url, data = {}, type = "GET") {
  return new Promise(function (resolve, reject) {
    let promise;
    if (type === "GET") {
      // 准备url query参数数据
      let dataStr = ""; //参数拼接字符串
      // 循环data对象拼接参数
      Object.keys(data).forEach(key => {
        dataStr += key + "=" + data[key] + "&";
      });
      if (dataStr !== "") {
        dataStr = dataStr.substring(0, dataStr.lastIndexOf("&"));
        url = url + "?" + dataStr;
      }
      // 发送GET请求
      promise = axios.get(url);
      // console.log(promise)
    } else {
      // 发送POST请求
      promise = axios.post(url, data);
    }
    promise
      .then(function (response) {
        // 请求成功调用resolve
        resolve(response.data);
      })
      .catch(function (error) {
        // 请求失败调用reject
        reject(error);
      });
  });
}
<template>
  <div>
    
    <Banner :banner="banner" />
    <div class="contnet">
      <superslide :options="options1" class="slideBox">
        <div class="contnet_super">
          <ul>
            <li>
              <img src="../assets/imgs/1634626581_banner_img.png" alt="" />
            </li>

            <li>
              <img src="../assets/imgs/1634626604_banner_img.png" alt="" />
            </li>
          </ul>
        </div>
      </superslide>

      <div class="w">
        <!-- 限时秒杀 -->
        <div class="main_one flex_align">
          <div class="limit_sale">
            <div class="limit_sale_left">
              <div class="limit_text">限时秒杀</div>
              <div class="img">
                <img src="../assets/images/shandian.png" alt="" />
              </div>
              <div class="time">每30分钟/场<span>倒计时</span></div>
              <div class="countdown">
                <span>{{ minute }}</span
                >: <span>{{ second }}</span
                >: <span> {{ milliSecond }}</span>
              </div>
            </div>
            <div class="limit_sale_right">
              <div class="right_scroll_view">
                <!-- 滑动的轮播部分 -->
                <div class="right_scroll_content" ref="limitScroll">
                  <div
                    class="item_content"
                    v-for="item in limitSaleList"
                    :key="item.gameid"
                    @click="
                      $router.push(
                        `/gamedetail/${item.platform}/${item.gameid}`
                      )
                    "
                  >
                    <img
                      :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/myGame/${item.list}`"
                      alt=""
                    />
                    <!-- 游戏名称 -->
                    <div class="game_name">{{ item.gamename_zh }}</div>
                    <!-- 游戏价格 -->
                    <div class="game_price">
                      <div class="discount_text">
                        -{{
                          Math.floor((1 - item.sharemoney / item.money) * 100)
                        }}%
                      </div>
                      <div class="money">
                        <span class="sharemoney"
                          >￥{{ item.money.toFixed(2) }}</span
                        >
                        <span>￥{{ item.sharemoney.toFixed(2) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 两个滚动按钮 -->
              <div
                class="limit_sale_arrow limit_sale_arrow_left"
                @click="scrollLimit('left')"
                v-if="scrollLimitIndex !== 0"
              >
                <img src="../assets/images/left.png" alt="" />
              </div>
              <div
                class="limit_sale_arrow limit_sale_arrow_left"
                @click="scrollLimit('right')"
                v-else
              >
                <img src="../assets/images/left.png" alt="" />
              </div>
              <div
                class="limit_sale_arrow limit_sale_arrow_right"
                @click="scrollLimit('right')"
                v-if="scrollLimitIndex < limitSaleList.length / 4 - 1"
              >
                <img src="../assets/images/right.png" alt="" />
              </div>
              <div
                class="limit_sale_arrow limit_sale_arrow_right"
                @click="scrollLimit('left')"
                v-else
              >
                <img src="../assets/images/right.png" alt="" />
              </div>
            </div>
          </div>
          <!-- <div
            class="main_one_item"
            style="height: auto"
            v-for="item in grid"
            :key="item.id"
          >
            <a
              href="javascript:void(0)"
              @click="
                $router.push(`/gamedetail/${item.platform}/${item.gameid}`)
              "
            >
              <div class="main_one_item_img">
                <img :src="item.img" />
                <img class="img2" :src="item.img2" />
              </div>

              <div class="main_one_item_text" style="display: none">
                <div class="main_one_item_tit">{{ item.gamename_zh }}</div>

                <div class="main_one_item_price">
                  <div class="main_one_item_price_i back_color_BDE960">
                    {{ ((item.sharemoney / item.money - 1) * 100).toFixed(0) }}%
                  </div>

                  <div class="main_one_item_price_i">
                    <div class="line-through">￥{{ item.money }}</div>

                    <div>￥{{ item.sharemoney }}</div>
                  </div>
                </div>
              </div>
            </a>
          </div> -->
        </div>

        <!-- 8.8 专区 -->

        <div style="height: 70px"></div>

        <!-- 热门推荐 -->

        <div class="main_three">
          <div class="main_three_tit flex_align">
            <div class="main_three_tit_icon">
              <img
                src="../assets/imgs/hot_recommend.png"
                alt=""
                class="imglogo"
              />
            </div>

            <div>热门推荐</div>
          </div>

          <div class="main_three_box">
            <div class="main_three_l">
              <swiper
                ref="hotSwiper"
                :options="hotSwiperOptions"
                v-if="hot.length > 0"
                style="height: 100%"
              >
                <swiper-slide
                  class="main_three_l_item swiper-slide"
                  v-for="item in hot"
                  :key="item.id"
                >
                  <img
                    :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/banner/${item.banner}`"
                    alt=""
                    style="height: 83.5%"
                    @click="
                      $router.push(
                        `/gamedetail/${item.platform}/${item.gameid}`
                      )
                    "
                  />
                  <div class="detial_text">
                    <div class="game_name">
                      {{ item.gamename_zh }}
                      <img
                        class="game_icon"
                        :src="imgs[item.platform]"
                        alt=""
                      />
                      <div class="random">
                        {{ item.fiveRandom }}<span>人已购买</span>
                      </div>
                    </div>
                    <div class="game_tag">
                      <span
                        class="tag"
                        v-for="i in item.gamelabel.split(',')"
                        :key="i.id"
                      >
                        {{ i }}
                      </span>
                    </div>
                    <div class="game_info">{{ item.gameinfo }}</div>
                  </div>
                <div class="price_info">
                          <div class="discount_box">
                            <div class="discount_text">
                              -{{
                                Math.floor(
                                  (1 - item.sharemoney / item.money) * 100
                                )
                              }}%
                            </div>
                            <div class="money">
                              <span class="sharemoney"
                                >￥{{ item.money.toFixed(2) }}</span
                              >
                              <span>{{ ((item.sharemoney/item.money)*10).toFixed(1) }}折</span>
                            </div>
                          </div>
                          <div class="save_text">
                            ￥{{
                              (item.sharemoney).toFixed(2)
                            }}元
                          </div>
                        </div>
                  <!-- <div class="main_three_l_item_text">
                    <div class="swiper-slide-tit">
                      {{ item.gamename_zh }}
                    </div>

                    <div class="swiper-slide-price">
                      <div class="swiper-slide-price-item back_color_BDE960">
                        {{
                          ((item.sharemoney / item.money - 1) * 100).toFixed(0)
                        }}%
                      </div>

                      <div class="swiper-slide-price-item">
                        ￥{{ item.sharemoney }}
                      </div>
                    </div>
                  </div> -->
                </swiper-slide>
              </swiper>
            </div>

            <div class="main_three_r">
              <div class="main_three_r_item" v-for="item in hot" :key="item.id">
                <div class="main_three_r_item_tit ti">
                  {{ item.gamename_zh }}
                </div>
                <div class="main_three_r_item_sub_tit ti">
                  {{ item.gamename_en }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 新游上线 -->

        <div class="main_three main_four">
          <div class="main_three_tit flex_align">
            <div class="main_three_tit_icon">
              <img src="../assets/imgs/new_game.png" alt="" class="imglogo" />
            </div>

            <div>新游上线</div>
          </div>
          <div class="main_four_box_content">
            <div class="scroll_view">
              <div class="scroll_box" ref="scrollBox">
                <div
                  class="item_content"
                  v-for="item in newGameList"
                  :key="item.id"
                >
                  <div class="date_row">
                    <div class="date_value">{{ item.date }}</div>
                    <div class="border"></div>
                  </div>
                  <div
                    class="content"
                    @click="
                      $router.push(
                        `/gamedetail/${item.platform}/${item.gameid}`
                      )
                    "
                  >
                    <div class="big_img">
                      <img
                        :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.list}`"
                        alt=""
                      />
                    </div>
                    <div class="small_img">
                      <img
                        :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/myGame/${item.myGame}`"
                        alt=""
                      />
                    </div>
                    <div class="text_content">
                      <div class="game_name">
                        <div class="name">{{ item.gamename_zh }}</div>
                        <div class="type">{{ item.gamename_en }}</div>
                      </div>
                      <div class="game_introduce">{{ item.gameinfo }}</div>
                      <div class="see_game">查看游戏></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="newgame_arrow newgame_arrow_left"
              @click="scroll('left')"
              v-if="scrollIndex !== 0"
            >
              <img src="../assets/images/left.png" alt="" />
            </div>
            <div
              class="newgame_arrow newgame_arrow_left"
              @click="scroll('right')"
              v-else
            >
              <img src="../assets/images/left.png" alt="" />
            </div>
            <div
              class="newgame_arrow newgame_arrow_right"
              @click="scroll('right')"
              v-if="scrollIndex < newGameList.length / 4 - 1"
            >
              <img src="../assets/images/right.png" alt="" />
            </div>
            <div
              class="newgame_arrow newgame_arrow_right"
              @click="scroll('left')"
              v-else
            >
              <img src="../assets/images/right.png" alt="" />
            </div>
          </div>
          <!-- <div class="main_four_box">
            <div class="main_four_box_content">
              <ul class="square-list">
                <li class="square-list-item"></li>

                <li class="square-list-item"></li>

                <li class="square-list-item"></li>

                <li class="square-list-item"></li>
              </ul>

              <div class="main_four_banner">
                <swiper
                  v-if="preOrder.length"
                  ref="mySwiper"
                  :options="swiperOptions"
                >
                  <swiper-slide v-for="item in preOrder" :key="item.id">
                    <div class="main_four_banner_date">{{ item.indate }}</div>
                    <div class="main_four_banner_img">
                      <a href="javascript:void(0)">
                        <img :src="item.img" alt="" />
                        <div class="main_four_banner_modal">
                          <div class="main_four_banner_modal_btn">立即预约</div>
                        </div>
                      </a>
                    </div>

                    <div class="main_four_banner_tit ti">
                      {{ item.gamename_zh }}
                    </div>

                    <div class="main_four_banner_yue">
                      <span>{{ item.precount }}</span> 人已预约
                    </div>
                  </swiper-slide>
                </swiper>

                <div
                  class="swiper-button-prev"
                  @click="slideSwitch('prev')"
                ></div>

                <div
                  class="swiper-button-next"
                  @click="slideSwitch('next')"
                ></div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- 发现游戏 -->

        <div class="main_three main_five" id="start_body">
          <div class="main_three_tit flex_align">
            <div class="main_three_tit_icon">
              <img src="../assets/imgs/find_game.png" class="imglogo" />
            </div>

            <div>发现游戏</div>
          </div>

          <div class="goods_type">
            <div
              class="goods_type_item"
              data-id="0"
              :class="flagIndex === 0 && 'goods_type_item_active'"
              @click="flagIndex = 0"
            >
              全部
            </div>

            <div
              class="goods_type_item"
              :data-id="i + 1"
              v-for="(item, i) in flag.slice(0, 15)"
              :key="i + 1"
              :class="flagIndex === i + 1 && 'goods_type_item_active'"
              @click="flagIndex = i + 1"
            >
              {{ item.gameflag }}
            </div>
          </div>

          <div class="goods_list_box sss0 nsdi active" style="overflow: hidden">
            <div class="flex" v-loading="loading">
              <div class="goods_list">
                <a
                  href="javascript:void(0)"
                  class="index_goods_item"
                  v-for="(item, i) in gameList"
                  :key="item.id"
                  @click="
                    $router.push(`/gamedetail/${item.platform}/${item.gameid}`)
                  "
                >
                  <div class="home-game-left-game-item" :data-id="i">
                    <div class="game-item">
                      <div class="game_vip" v-if="item.platform!='Battle'">{{item.bVipmake==1?"会员免费":"Svip会员免费"}}</div>
                      <div class="game-item-left">
                        <img
                          :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.list}`"
                          alt=""
                          class="game-img"
                        />
                      </div>
                      <div class="game-item-right">
                        <div class="title3">
                          <div class="title-left">
                            <div
                              :title="item.gamename_zh"
                              class="game-name ellipsis"
                            >
                              {{ item.gamename_zh }}
                            </div>
                            <!-- <div class="title-tag" style="display: ">
                              {{ item.platform }}
                            </div> -->
                          </div>
                          <!-- <div class="title-right">
                            原价：{{ item.money }}元
                          </div> -->
                        </div>
                        <ul class="tag-list">
                          <div class="tag-list-left">
                            <div
                              class="tag-list-item"
                              v-for="(tag, i) in item.gamelabel
                                .split(',')
                                .slice(0, 6)"
                              :key="i"
                              :data-id="i"
                            >
                              {{ tag }}
                            </div>
                          </div>
                          <!-- <div class="tag-list-right">
                            <div class="tag-list-item right-tag">
                              {{
                                (
                                  (item.sharemoney / item.money - 1) *
                                  100
                                ).toFixed(0)
                              }}%
                            </div>
                          </div> -->
                        </ul>
                        <!-- <div class="desc ellipsis">{{ item.gameinfo }}</div> -->
                        <div class="sale-info">
                          <div class="count">
                            <span class="span1">{{ item.fiveRandom }}</span>
                            <span class="span2">人当前在线</span>
                          </div>
                          <!-- <div class="price">￥{{ item.sharemoney }}</div> -->
                        </div>
                       <div class="price_info">
                          <div class="discount_box">
                            <div class="discount_text">
                              -{{
                                Math.floor(
                                  (1 - item.sharemoney / item.money) * 100
                                )
                              }}%
                            </div>
                            <div class="money">
                              <span class="sharemoney"
                                >￥{{ item.money.toFixed(2) }}</span
                              >
                              <span>{{ ((item.sharemoney/item.money)*10).toFixed(1) }}折</span>
                            </div>
                          </div>
                          <div class="save_text">
                            ￥{{
                              (item.sharemoney).toFixed(2)
                            }}元
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div class="goods_intro">
                <div
                  class="goods_intro_item"
                  v-for="(item, i) in gameList"
                  :key="i"
                  :class="i === 0 && 'goods_intro_item_active'"
                >
                  <div class="goods_intro_item_tit flex_align">
                    <div class="goods_item_title">
                      <a href="" class="ti"> {{ item.gamename_zh }}</a>
                    </div>
                  </div>

                  <div
                    class="goods_intro_item_content"
                    v-html="item.infodetail"
                  ></div>
                  <div v-if="item.infodetail.indexOf('<img') < 0">
                    <div
                      class="goods_img"
                      v-for="img in item.smallDetails.split(',')"
                      :key="img"
                    >
                      <img
                        :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/smallDetails/${img}`"
                        alt=""
                      />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <a href="javascript:void(0)" class="model-btn" @click="loadMore"
              >查看更多</a
            >
          </div>
        </div>
        <div id="end_body" style="position: relative; top: -200px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import $ from "jquery";
import Banner from "../components/Banner";
import { homeApi } from "../api";
import { getUUID, getSign, getTotalPage, getFiveDigits } from "../common/utils";
import { BASE_IMAGE_URL } from "../common/config.json";
import grid_data from "../data/home/grid.json";
import preOrder_data from "../data/home/preOrder.json";
export default {
  name: "Home",
  components: {
    Banner,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      BASE_IMAGE_URL,
      superShow: false,
      options1: {
        mainCell: "ul",
        autoPlay: true,
        effect: "leftMarquee",
        vis: 2,
        interTime: 50,
        trigger: "click",
        mouseOverStop: false,
      },
      banner: [], // 轮播广告
      grid: [], // 四个优惠游戏
      preference: [], // 8.8专区
      hot: [], // 热门推荐
      preOrder: [], // 预购
      gameList: [], // 游戏列表(发现游戏)
      flag: [], // 游戏标签
      platform: [], // 游戏平台
      flagIndex: 0,
      preOrderPage: 1,
      preOrderTotalPage: 0,
      gameListPage: 1,
      gameListTotalPage: 0,
      threeIndex: 0,
      loading: false,
      newGameList: [],
      limitSaleList: [],
      scrollIndex: 0,
      scrollLimitIndex: 0,
      newGame: [
        { gameid: 1245620, platform: "Steam" },
        { gameid: 1498570, platform: "Steam" },
        { gameid: 133, platform: "EPIC" },
        { gameid: 1517290, platform: "Steam" },
        { gameid: 705, platform: "Origin" },
        { gameid: 271590, platform: "Steam" },
        { gameid: 255710, platform: "Steam" },
      ],
      limitSale: [
        { gameid: 578080000, platform: "Steam" },
        { gameid: 376210, platform: "Steam" },
        { gameid: 1222680, platform: "Steam" },
        { gameid: 728880, platform: "Steam" },
        { gameid: 1203220, platform: "Steam" },
        { gameid: 1174180, platform: "Steam" },
        { gameid: 860510, platform: "Steam" },
        { gameid: 1446780, platform: "Steam" },
        // { gameid: 883710, platform: "Steam" },
        // { gameid: 1097150, platform: "Steam" },
        // { gameid: 1196590, platform: "Steam" },
        // { gameid: 314160, platform: "Steam" },
      ],
      // 分钟
      minute: 0,
      // 秒
      second: 0,
      // 毫秒
      milliSecond: 0,
      timer1: null,
      imgs: {
        Steam: require("../assets/imgs/STEAM.png"),
        UPlay: require("../assets/imgs/UPLAY.png"),
        Origin: require("../assets/imgs/ORIGIN.png"),
        EPIC: require("../assets/imgs/EPIC.png"),
        Battle: require("../assets/imgs/Battlenet.png"),
      },
    };
  },
  created(){
  this.loadData2()
  },
  mounted() {
    // 加载数据
    // this.loadData(this.preOrderPage);
    // this.getGift();
    // homeApi.reqGameListAll().then(res=>{
    //   console.log(res,"resssss")
    // })
    this.getTime();
  },
  computed: {
    hotSwiperOptions() {
      let obj = {
        effect: "fade",
      };
      return obj;
    },
    swiperOptions() {
      let _this = this;
      let obj = {
        slidesPerView: 4,
        spaceBetween: 30,
        simulateTouch: false,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          click(e) {
            if (e.target.className == "main_four_banner_modal_btn") {
              _this.$message({
                type: "success",
                message: "预约成功",
              });
            }
          },
        },
      };
      return obj;
    },
  },
  watch: {
    flagIndex() {
      this.getGameList(this.gameListPage, null, true);
    },
  },
  methods: {
    getFiveDigits,
    // 打开投诉弹窗
    clickComplaint() {
      this.complaintIsShow = true;
    },
    closeComplaint() {
      this.complaintIsShow = false;
    },
    loadData() {
      this.grid = grid_data;
      this.preOrder = preOrder_data;
      let _list = this.newGame.map((i) => `${i.platform}_${i.gameid}`);
      homeApi.reqNewList(`('${_list.join("','")}')`).then((res) => {
        let list = [];
        this.newGame.forEach((i) => {
          let item = res.find((it) => i.gameid === it.gameid);
          item && list.push(item);
        });
        this.newGameList = list.map((i) => {
          // i.gamelabel=i.gamelabel.split(",").join("·")
          return { ...i, date: i.indate.substring(5, 10).replace("-", ".") };
        });
      });

      let _limitList = this.limitSale.map((i) => `${i.platform}_${i.gameid}`);
      homeApi.reqNewList(`('${_limitList.join("','")}')`).then((res) => {
        let list = [];
        this.limitSale.forEach((i) => {
          let item = res.find((it) => i.gameid === it.gameid);
          item && list.push(item);
        });
        this.limitSaleList = list.map((i) => {
          return { ...i, date: i.indate.substring(5, 10).replace("-", ".") };
        });
      });
    },
    loadData2(){
      // 由后台定义数据的游戏列表接口
      homeApi.reqAllGameList().then(res=>{
        this.getGameList(this.gameListPage, null, true);
        const {is_exhibition_banner,is_exhibition_hot,is_time_limit,is_new_game}=res
        // 首页轮播图
        this.banner=is_exhibition_banner
        // 限时秒杀
        this.limitSaleList=is_time_limit.map(i=>{return {...i,date: i.indate.substring(5, 10).replace("-", ".")}})
        // 热门推荐
        this.hot=is_exhibition_hot.map((i) => {
          return { ...i, fiveRandom: getFiveDigits() };
        });
        // 新游上线
        this.newGameList=is_new_game.map(i=>{return{...i,date: i.indate.substring(5, 10).replace("-", ".")}})
          this.$nextTick(() => this.initSwiper());
      })
    },
    scroll(type) {
      if (type === "left") {
        this.scrollIndex -= 1;
        this.$refs.scrollBox.style = `transform: translateX(-${
          this.scrollIndex * 995
        }px);transition: all 300ms ease 0s;`;
      } else {
        this.scrollIndex += 1;
        this.$refs.scrollBox.style = `transform:translateX(-${
          this.scrollIndex * 995
        }px);transition: all 300ms ease 0s;`;
      }
    },
    getGift() {
      var _list = [];
      const ar1 = [
        { gameid: 1517290, platform: "Steam" },
        { gameid: 265120, platform: "Steam" },
        { gameid: 1551360, platform: "Steam" },
        { gameid: 283, platform: "Origin" },
        { gameid: 513, platform: "Origin" },
        { gameid: 346110, platform: "Steam" },
        { gameid: 1644960, platform: "steam" },
        { gameid: 31, platform: "Origin" },
      ];

      const ar2 = [
        { gameid: 255710, platform: "Steam" },
        { gameid: 1259420, platform: "Steam" },
        { gameid: 815370, platform: "Steam" },
        { gameid: 242760, platform: "Steam" },
        { gameid: 517630, platform: "steam" },
        { gameid: 648350, platform: "steam" },
      ];

      ar1.forEach((item) => {
        _list.push(`${item.platform}_${item.gameid}`);
      });

      ar2.forEach((item) => {
        _list.push(`${item.platform}_${item.gameid}`);
      });

      homeApi.reqNewList(`('${_list.join("','")}')`).then((res) => {
        let bannerArr = [];
        let hotArr = [];

        // 轮播图
        ar1.forEach((row) => {
          row = res.find((resItem) => {
            return resItem.gameid == row.gameid;
          });
          bannerArr.push(row ? { ...row, gameposition: "banner2" } : {});
        });
        this.banner = bannerArr;

        ar2.forEach((row) => {
          row = res.find((resItem) => {
            return resItem.gameid == row.gameid;
          });
          hotArr.push(row ? { ...row, gameposition: "hot" } : {});
        });
        this.hot = hotArr.map((i) => {
          return { ...i, fiveRandom: getFiveDigits() };
        });

        this.$nextTick(() => this.initSwiper());
      });
    },

    getGameList(page, search = "", isInit) {
      this.loading = true;
      const flag =
        this.flagIndex === 0 ? "" : this.flag[this.flagIndex - 1].gameflag;
      homeApi
        .reqGamelist(getUUID(), page, getSign(), 4, search, "", flag, "px","",1)
        .then((res) => {
          res.list = res.list.map((i) => {
            return { ...i, fiveRandom: getFiveDigits() };
          });
          if (page === 1) {
            this.gameList = res.list;
          } else {
            this.gameList = [...this.gameList, ...res.list];
          }
          // this.flag = res.flag;
          this.flag = [
            { gameflag: "动作" },
            { gameflag: "多人" },
            { gameflag: "冒险" },
            { gameflag: "模拟" },
            { gameflag: "开放世界" },
            { gameflag: "策略" },
            { gameflag: "角色扮演" },
            { gameflag: "生存" },
            { gameflag: "合作" },
            { gameflag: "射击" },
            { gameflag: "单人" },
            { gameflag: "独立" },
            { gameflag: "剧情丰富" },
            { gameflag: "第一人称射击" },
            { gameflag: "玩家对战" },
          ];
          this.num = res.num[0]["count(*)"];
          this.gameListTotalPage = getTotalPage(this.num, 4);
          isInit &&
            this.$nextTick(() => {
              this.loading = false;
              this.initGameInfo();
            });
        });
    },

    loadMore() {
      this.$router.push("/gamecenter");
    },

    slideSwitch(type) {
      if (type === "prev") {
        this.$refs.mySwiper.swiper.slidePrev();
      } else if (type === "next") {
        this.$refs.mySwiper.swiper.slideNext();
      }
    },
    // 预约
    appointment() {
      this.$message({
        type: "success",
        message: "预约成功",
      });
    },

    initSwiper() {
      let that = this;
      $(".goods_type_item").click(function () {
        $(".goods_type_item").removeClass("goods_type_item_active");
        $(this).addClass("goods_type_item_active");
        var id = $(this).attr("data-id");
        $(".nsdi").removeClass("active");
        $(".sss" + id).addClass("active");
      });
      this.$nextTick(() => {
        $(function () {
          $(".main_three_r_item")
            .eq(0)
            .addClass("main_three_r_item_active")
            .children(".main_three_r_item_tit")
            .addClass("main_three_r_item_tit_active");

          $(".main_three_r_item").mouseenter(function () {
            let i = $(this).index();
            that.$refs["hotSwiper"].swiper.slideTo(i);
            $(this)
              .addClass("main_three_r_item_active")
              .siblings()
              .removeClass("main_three_r_item_active");
            $(this)
              .children(".main_three_r_item_tit")
              .addClass("main_three_r_item_tit_active");
            $(this)
              .siblings()
              .children(".main_three_r_item_tit")
              .removeClass("main_three_r_item_tit_active");
          });
        });
      });
    },

    initGameInfo() {
      $(".index_goods_item").mouseenter(function () {
        $(this)
          .children(".home-game-left-game-item")
          .addClass("home-game-left-game-item_active");
        $(this)
          .siblings()
          .children(".home-game-left-game-item")
          .removeClass("goods_list_item_active");
        let i = $(this).index();
        $(".goods_intro_item")
          .siblings()
          .removeClass("goods_intro_item_active");
        $(".goods_intro_item").eq(i).addClass("goods_intro_item_active");

        var a = $(this)
          .parent(".goods_list")
          .siblings(".goods_intro")
          .offset().top;
        if (
          a <= $(window).scrollTop() &&
          a < $(window).scrollTop() + $(window).height()
        ) {
          let top = $(window).scrollTop() - a + 70;
          $(this)
            .parent(".goods_list")
            .siblings(".goods_intro")
            .children(".goods_intro_item")
            .eq(i)
            .css({ top: top + "px" });
        } else {
          $(".goods_intro_item")
            .eq(i)
            .css({ top: 0 + "px" });
        }
      });
    },
    // 倒计时
    countDown(endTime) {
      let startTime = new Date().getTime();
      let time;
      if (endTime > startTime) {
        time = endTime - startTime;
      } else {
        window.localStorage.removeItem("time");
        this.getTime();
      }
      this.minute =
        Math.floor(time / 1000 / 60) >= 10
          ? Math.floor(time / 1000 / 60)
          : "0" + Math.floor(time / 1000 / 60);
      this.second =
        Math.floor((time / 1000) % 60) >= 10
          ? Math.floor((time / 1000) % 60)
          : "0" + Math.floor((time / 1000) % 60);
      this.milliSecond = new Date().getMilliseconds() % 60;
    },
    getTime() {
      clearInterval(this.timer1);
      let time = new Date(window.localStorage.getItem("time")).getTime();
      if (!time) {
        time = new Date().getTime() + 1000 * 60 * 30;
        window.localStorage.setItem("time", new Date(time).toLocaleString());
      }
      this.timer1 = setInterval(() => {
        this.countDown(time);
      }, 10);
    },
    scrollLimit(type) {
      if (type === "left") {
        this.scrollLimitIndex -= 1;
        this.$refs.limitScroll.style = `transform: translateX(-${
          this.scrollLimitIndex * 850
        }px);transition: all 300ms ease 0s;`;
      } else {
        this.scrollLimitIndex += 1;
        this.$refs.limitScroll.style = `transform:translateX(-${
          this.scrollLimitIndex * 850
        }px);transition: all 300ms ease 0s;`;
      }
    },
  },
};
</script>


<style scoped lang="less">
@import url("../assets/css/reset.css");
@import url("../assets/css/public.css");
@import url("../assets/css/font_2442414_fx489h9ah1d.css");
@import url("../assets/css/font_2442491_or1abnb55db.css");
@import url("../assets/css/index.css");
@import url("../assets/css/swiper-bundle.min.css");

@font-face {
  font-family: "iconfont";
  src: url("../assets/css/font_2442414_fx489h9ah1d.woff2?t=1625642978036")
      format("woff2"),
    url("../assets/css/font_2442491_or1abnb55db.woff2?t=1626839359807")
      format("woff2");
}
.goods_type {
  &:hover {
    .goods_type_item {
      color: #fff;
    }
  }
}

.model-btn {
  margin-top: 15px;
  border: 2px solid #05dafa;
  border-radius: 3px;
  &:hover {
    border-color: #f07d06;
    background-color: #e15304;
  }
}
.detial_text {
  position: relative;
  width: 100%;
  height: 80px;
  background-color: #353637;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 5px;
  text-align: left;
  .game_name {
    font-weight: 700;
    color: #ddd;
    display: flex;
    align-items: center;
    .game_icon {
      margin-left: 5px;
      width: 14px;
      height: 14px;
    }
    .random {
      margin-right: 6px;
      color: #fe9248;
      font-size: 16px;
      font-weight: 600;
      margin-left: 30px;
      span {
        color: #ddd;
        font-size: 12px;
        margin-left: 5px;
        font-weight: 400;
      }
    }
  }
  .game_tag {
    margin: 5px 0;
    .tag {
      border: 1px solid #aaa;
      color: #aaa;
      line-height: normal;
      background-color: transparent;
      height: 20px;
      font-size: 10px;
      line-height: 20px;
      font-weight: 500;
      border-radius: 4px;
      background: #2d2d2e;
      margin-right: 5px;
      padding: 0 5px;
    }
  }
  .game_info {
    width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    text-align: start;
    color: #ddd;
  }
}

.game-item {
  &:hover {
    .price_info {
      transform: translateY(-1px);
    }
  }
}
.game-item-right,
.main_three_l {
  position: relative;
  .price_info {
    width: 130px;
    border: 2px solid rgba(0, 0, 0, 0);
    position: absolute;
    right: -5px;
    top: 50%;
    transition: all 0.3s linear;

    .discount_box {
      display: flex;
      height: 30px;
      .discount_text {
        width: 65px;
        height: 100%;
        font-size: 20px;
        color: #a4d007;
        background: #4c6b22;
        text-align: center;
        line-height: 30px;
        border-top-left-radius: 3px;
      }
      .money {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
        background-color: rgba(46, 68, 80);
        color: #acdbf5;
        font-family: cursive;
        border-top-right-radius: 3px;

        span {
          width: 100%;
          height: 14px;
          line-height: 14px;
          letter-spacing: 1px;
          margin-left: 3px;
        }
        .sharemoney {
          text-decoration: line-through;
          letter-spacing: 0px;
          font-size: 12px;
          margin-left: 12px;
        }
      }
    }
    .save_text {
      height: 25px;
      background-color: #cc7a00;
      text-align: center;
      color: #000;
      line-height: 25px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      font-size: 14px;
    }
  }
}
.main_three_l {
  .price_info {
    width: 140px;
    height: 80px;
    left: -2px;
    top: 355px;
  }
}
.limit_sale {
  width: 1100px;
  height: 307px;
  display: flex;
  .limit_sale_left {
    width: 190px;
    height: 307px;
    background: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(#0e2448),
      to(#1285b6)
    );
    flex: 0 0 auto;
    .limit_text {
      width: 100%;
      height: 124px;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      line-height: 124px;
      letter-spacing: 3px;
    }
    .img {
      display: block;
      width: 46px;
      margin: 0 auto 30px;
    }
    .time {
      letter-spacing: 1px;
      text-align: center;
      font-weight: 700;
      span {
        margin-left: 20px;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .countdown {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-size: 20px;
      font-weight: 700;
      span {
        text-align: center;
        width: 30px;
        height: 30px;
        background-color: #252531;
        border: 1px solid rgba(0, 0, 0, 0.2);
        margin: 0 5px;
      }
    }
  }
  .limit_sale_right {
    flex: 1;
    position: relative;
    .limit_sale_arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 70px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.06);
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 10px;
        height: 18px;
      }
    }
    .limit_sale_arrow_left {
      left: 5px;
    }
    .limit_sale_arrow_right {
      right: -40px;
    }
    .right_scroll_view {
      width: 835px;
      height: 100%;
      margin: 0 0 0 40px;
      overflow: hidden;
    }
    .right_scroll_content {
      display: flex;
      align-items: center;
      height: 100%;
      .item_content {
        flex-shrink: 0;
        width: 193px;
        height: 240px;
        margin-right: 20px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          transition: all 300ms ease 0s;
        }
        .game_name {
          position: absolute;
          top: 75%;
          width: 100%;
          text-align: center;
          font-size: 14px;
          height: 30px;
          line-height: 30px;
          background-color: rgba(37, 37, 49, 0.7);
        }
        .game_price {
          width: 140px;
          height: 30px;
          line-height: 24px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          .discount_text {
            width: 65px;
            height: 100%;
            font-size: 20px;
            color: #a4d007;
            background: #4c6b22;
            text-align: center;
            line-height: 30px;
          }
          .money {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 13px;
            background-color: rgba(46, 68, 80);
            color: #acdbf5;
            span {
              width: 100%;
              height: 14px;
              line-height: 14px;
              letter-spacing: 1px;
              margin-left: 5px;
            }
            .sharemoney {
              text-decoration: line-through;
              letter-spacing: 0px;
              font-size: 12px;
              margin-left: 15px;
            }
          }
        }
        cursor: pointer;
        &:hover {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
.main_four {
  background: url("../assets/images/background.png") no-repeat 100% 100%;
  background-size: 100% 100%;
}
.main_four_box_content {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 324px;
  overflow: hidden;
  position: relative;
  .newgame_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 70px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.06);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 10px;
      height: 18px;
    }
  }
  .newgame_arrow_left {
    left: 5px;
  }
  .newgame_arrow_right {
    right: 5px;
  }

  .scroll_view {
    margin: 0 auto;
    width: 980px;
    height: 100%;
    overflow: hidden;
    position: relative;
    .scroll_box {
      position: absolute;
      left: 0px;
      top: 0px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      transition: all 300ms ease 0s;
      .item_content {
        width: 233px;
        height: 304px;
        margin-right: 16px;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          .see_game {
            color: #fff !important;
          }
          .big_img {
            transform: scale(1.1);
          }
        }
        .content {
          width: 100%;
          border-radius: 5px;
          overflow: hidden;
          .big_img {
            width: 100%;
            height: 121px;
            transition: all 300ms ease 0s;
          }
          .small_img {
            width: 58px;
            height: 80px;
            border-radius: 4px;
            border: 2px solid rgb(255, 255, 255);
            overflow: hidden;
            position: absolute;
            left: 10px;
            top: 45%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text_content {
            background: rgb(53, 53, 53);
            height: 129px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .game_name {
              margin: 20px 0 0 35px;
              .name {
                font-size: 14px;
                color: rgb(255, 255, 255);
                line-height: 14px;
                margin-bottom: 8px;
                max-width: 106px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
              .type {
                font-size: 12px;
                color: rgb(209, 209, 209);
                line-height: 12px;
                height: 14px;
                margin-bottom: 4px;
                max-width: 106px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
            }
            .game_introduce {
              font-size: 14px;
              color: rgb(255, 255, 255);
              line-height: 14px;
              max-width: 206px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              margin-top: 10px;
            }
            .see_game {
              height: 36px;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-top: 1px solid rgba(216, 216, 216, 0.04);
              margin-top: 12px;
              font-size: 12px;
              color: rgb(209, 209, 209);
              line-height: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .date_row {
    height: 50px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 4px;
    .date_value {
      font-size: 16px;
      font-weight: 600;
    }
    .border {
      width: 140px;
      margin-left: 12px;
      border-bottom: 1px dashed rgba(117, 117, 117, 0.6);
      border-top: 1px dashed rgba(117, 117, 117, 0.6);
    }
  }
}
.banner .swiper-slide:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.3;
  z-index: 2;
}

.banner .swiper-slide-active:after {
  opacity: 0;
}

.swiper-slide-active a {
  position: relative;
  z-index: 3;
}

.banner {
  padding: 0 !important;
}
.contnet_super {
  top: 100px !important;
}

.main_three_tit_icon {
  display: flex;
}

.main_one_item_img {
  position: relative;
  height: auto;
}

.main_one_item .img2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  transition: all 1s;
}

.main_one_item:hover .img2 {
  opacity: 1;
  display: block;
}
.main_three_l_item {
  cursor: pointer;
}
</style>


<template>
  <header class="head">
    <div class="head_w">
      <div class="head_box">
        <div class="head_box_l">
          <div class="logo">
            <a href="javascript:void(0)" @click="$router.push('/')">
              <img :src="logo" alt="" />
            </a>
          </div>

          <div class="head_nav">
            <div class="head_nav_item">
              <router-link to="/">
                <img src="../assets/imgs/home.png" alt="" />
                <!-- <i class="iconfont">&#xe61f;</i> -->
                <div class="head_nav_item_text">首页</div>
              </router-link>
            </div>

            <div class="head_nav_item">
              <router-link to="/gamecenter">
                <img src="../assets/imgs/game_center.png" alt="" />
                <!-- <i class="iconfont" style="color: #ffffff">&#xe997;</i> -->
                <div class="head_nav_item_text">游戏中心</div>
              </router-link>
            </div>

            <div class="head_nav_item">
              <router-link href="javascript:void(0)" to="/myassets">
                <!-- <i class="iconfont">&#xe7c7;</i> -->
                <i class="action_icon iconfont icon-zhangdan"></i>
                <div class="head_nav_item_text">我的订单</div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="head_box_r">
          <div class="search_block row_box search_block_min">
            <div class="search_addon addon1">
              <i class="iconfont icon-icon_search"></i>
            </div>
            <!-- <div class="search_con"> -->
            <el-input
              class="search_input"
              placeholder="搜索游戏"
              v-model.trim="searchText"
              clearable
              @clear="toSearchFn(searchText)"
              @keyup.enter.native="toSearchFn(searchText)"
            />
            <!-- @change="toSearchFn(searchText)" -->
            <!-- </div> -->
            <a
              class="search_addon endadd"
              href="javascript:void(0)"
              @click="toSearchFn(searchText)"
            >
              <!-- <i class="iconfont icon-close"></i> -->
              搜索
            </a>
          </div>

          <div class="head_item" style="display: none">
            <img src="../assets/images/img2.9cc3ad5.png" alt="" />
          </div>
          <div class="head_nav_item">
            <img
              src="../assets/images/tousu.png"
              alt=""
              class="complaint"
              @click="clickComplaint"
            />
          </div>
          <div class="head_nav_item">
            <router-link href="javascript:void(0)" to="/membercenter">
              <!-- <i class="iconfont">&#xe8b1;</i> -->
              <img src="../assets/imgs/member_center.png" alt="" />
              <div class="head_nav_item_text">会员中心</div>
            </router-link>
          </div>

          <div class="head_nav_item">
            <a href="javascript:void(0)" @click="serviceIframe">
              <!-- <i class="iconfont">&#xea09;</i> -->
              <i class="action_icon iconfont icon-kefu"></i>
              <div class="head_nav_item_text">联系客服</div>
            </a>
          </div>

          <div class="head_nav_item download" @click="getuserfirst">
            <a @click="downFile">
              <div class="head_nav_item_text">下载客户端</div>
              <div class="game">免费领取游戏</div>
            </a>
          </div>

          <div class="head_nav_item">
            <a href="javascript:void(0)" @click="showLogin" v-if="!user.uuid">
              <i class="iconfont">&#xe657;</i>

              <div class="head_nav_item_text">登录</div>
            </a>

            <div class="action_txt row_box" v-if="user.uuid">
              <div class="user_img">
                <img
                  :src="user.himg"
                  class="user-head"
                  alt=""
                  v-if="user.himg"
                />
                <img
                  src="../assets/images/doge.png"
                  class="user-head"
                  alt=""
                  v-if="!user.himg"
                />
              </div>
              <p
                class="action_txt"
                @mouseenter="showDropdownMenu = true"
                @mouseleave="showDropdownMenu = false"
              >
                <span class="username">{{ user.username }}</span>
                <!-- <i class="action_icon iconfont icon-arrow-down-filling"></i> -->
                <!-- 是会员增加  class类名=> vip -->
                <i
                  class="action_icon iconfont icon-huiyuan"
                  :class="{
                    vip: user && user.bVip != 0,
                    zvip: user && user.vip_status == 2,
                  }"
                  style="margin-left: 4px"
                ></i>
                <i class="action_icon iconfont icon-menu"></i>
              </p>
            </div>
            <el-collapse-transition>
              <ul
                @mouseenter="showDropdownMenu = true"
                @mouseleave="showDropdownMenu = false"
                class="
                  ant-dropdown-menu
                  ant-dropdown-menu-light
                  ant-dropdown-menu-root
                  ant-dropdown-menu-vertical
                "
                role="menu"
                tabindex="0"
                v-show="showDropdownMenu"
              >
                <li
                  class="ant-dropdown-menu-button"
                  v-if="user && user.bVip == 0"
                >
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/membercenter')"
                  >
                    <div class="menu-button">开通会员</div>
                  </a>
                </li>
                <li
                  class="
                    ant-dropdown-menu-item ant-dropdown-menu-item-only-child
                  "
                  role="menuitem"
                >
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/nameplate')"
                  >
                    <i class="iconfont icon-xiazai"></i>
                    <span>个人中心</span>
                  </a>
                </li>
                <li
                  class="
                    ant-dropdown-menu-item ant-dropdown-menu-item-only-child
                  "
                  role="menuitem"
                  v-if="false"
                >
                  <router-link to="/cdk">
                    <i class="icon i-yuechi"></i>
                    <span>兑换CDK</span>
                  </router-link>
                </li>
                <li
                  class="
                    ant-dropdown-menu-item ant-dropdown-menu-item-only-child
                  "
                  role="menuitem"
                  v-if="false"
                >
                  <router-link to="/utility-manager">
                    <i class="icon i-gongju"></i>
                    <span>辅助工具</span>
                  </router-link>
                </li>
                <li
                  class="
                    ant-dropdown-menu-item ant-dropdown-menu-item-only-child
                  "
                  role="menuitem"
                  v-if="false"
                >
                  <router-link to="/help-center">
                    <i class="icon i-bangzhu"></i>
                    <span>帮助中心</span>
                  </router-link>
                </li>
                <li
                  class="
                    ant-dropdown-menu-item ant-dropdown-menu-item-only-chi
                    change_account
                  "
                  role="menuitem"
                  @click="showLogin"
                >
                  <i class="el-icon-switch-button"></i>
                  <span>切换账户</span>
                </li>
              </ul>
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { homeApi, userApi } from "../api";
import { EventBus } from "../common/event-bus.js";
import { getUUID } from "../common/utils";
export default {
  name: "Head",
  // props: {
  //   showLogin: Function,
  //   searchHotText: String,
  //   user: Object,
  //   toSearchFn: Function,
  //   clearSearch: Function,
  //   openNewWindow: Function,
  //   uploadsUrl: String,
  //   fileName: String,
  // },
  props: [
    "showLogin",
    "searchHotText",
    "user",
    "toSearchFn",
    "clearSearch",
    "openNewWindow",
    "uploadsUrl",
    "fileName",
    "file",
  ],
  data() {
    return {
      searchText: "",
      showDropdownMenu: false,
      // uploadsUrl: "https://download.zzzzu.com/v1/Steamalypc_v1.0.0.1367.exe",
      logo: "https://imgcloud.huifangkuai.com/Display/logo.png",
      flag: false,
    };
  },
  mounted() {
    this.logo =
      window.location.href.indexOf("stm.qiaohai68.cn") > 0 ||
      window.location.href.indexOf("steam.uuuzz.com") > 0
        ? "https://imgcloud.huifangkuai.com/Display/logo_v1.png"
        : "https://imgcloud.huifangkuai.com/Display/logo.png";
    //  userApi.reqUploads("1").then((res) => {
    //     let _res = res[0];
    //     if (_res) {
    //       this.uploadsUrl = _res.downloadurl;
    //     }
    //   });
    let that = this;
    setTimeout(() => {
      that.getChannelDownloadUrl();
    }, 200);
  },
  watch: {
    searchHotText(value) {
      this.searchText = value;
    },
    $route() {
      if (this.$route.path.indexOf("/gamecenter") === -1) {
        this.searchText = "";
        this.clearSearch(this.searchText);
      }
    },
  },

  methods: {
    // 获取logo
    getChannelDownloadUrl() {
      let that = this;
      let url = window.location.origin.split("https://")[1];
      homeApi.getChannelDownloadUrl(url).then((res) => {
        if (res) {
          if (res.exlogo) {
            that.logo = res.exlogo;
          }
        }
      });
    },
    downFile() {
      let url = window.location.origin.split("https://")[1];

      homeApi.getChannelDownloadUrl(url).then((res) => {
        if (res) {
          console.log(res.dowload_url);
          console.log(this.fileName);
          var a = document.createElement("a");
          a.href = res.dowload_url.replace(/https/,'http').replace(/http/,'https');
          a.download = this.fileName;
          a.click();
        }
      });
      // console.log(this.file)
      // let that = this;
      // if (!that.file) return;
      // var a = document.createElement("a");
      // a.href = that.file;
      // a.download = that.fileName;
      // a.click();
    },
    serviceIframe() {
      EventBus.$emit("showServiceIframe", true);
    },
    clickComplaint() {
      EventBus.$emit("showcomplaint");
    },
    getuserfirst() {
      let url = "";
      let arr = [];
      let urls = window.localStorage.getItem("_conversionTypes")
        ? JSON.parse(window.localStorage.getItem("_conversionTypes"))
        : "";
      if (urls) {
        url = urls && urls[0].logidUrl;
        arr = JSON.stringify(urls);
      } else {
        url = window.location.href;
        arr = JSON.stringify([{ newType: 10, logidUrl: url }]);
      }
      userApi.reqGettime().then((time) => {
        homeApi
          .reqGetUserFirst(
            this.$gameStartState.ip,
            url,
            time,
            arr,
            this.$gameStartState.belong,
            getUUID(),
            0,
            1
          )
          .then(() => {});
      });
    },
  },
};
</script>

<style scoped lang="less">
@import url("../assets/css/icon.css");

.head_nav_item {
  position: relative;
  a {
    cursor: pointer;
  }
  img {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }
  .complaint {
    width: auto;
    height: auto;
    cursor: pointer;
    margin-top: 20px;
  }
}

.head_nav_item .action_txt {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  opacity: 0.8;
  height: 100%;
}
.username {
  width: 80px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.head_nav_item .user_img {
  margin-right: 5px;
}

.head_nav_item .user-head {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.head_nav_item .action_txt {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  opacity: 0.8;
}

.head_nav_item .action_txt i {
  font-size: 18px;
  margin-right: 4px;
}

.search_block {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 300px;
  height: 34px;
  overflow: hidden;
  background: #313233;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 17px;
}

.search_block_min {
  width: 250px;
  height: 24px;
  margin-left: 20px;
}

.search_block .search_addon {
  width: 20px;
  height: 26px;
}

.search_block .addon1 {
  margin: 0px 10px 0px 12px;
}

.addon1 .iconfont {
  font-size: 20px;
  line-height: 28px;
  color: #dedede;
}

.search_block .search_input {
  flex: 1 1 0%;
  display: block;
  overflow: hidden;
  height: 20px;
  line-height: 20px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  padding-right: 10px;
  box-sizing: border-box;
  color: rgb(195, 198, 209);
}

.search_block .endadd {
  flex: 0 0 48px;
  cursor: pointer;
  user-select: none;
  color: rgb(255, 255, 255);
  text-align: center;
  background: linear-gradient(270deg, #cc3930, #f95244);
  font-size: 14px;
  height: 26px;
  line-height: 24px;
}
</style>

<template>
  <div class="mMine">
      <div class="userInfo"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped src="../assets/css/h5/mMine.css">
</style>
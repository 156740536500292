import Vue from 'vue'
import App from './App.vue'

import router from "./router/index.js"

import VueSuperSlide from 'vue-superslide'
import VueMarquee from 'vue-marquee-component';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(VueMarquee);
Vue.use(Vant);
Vue.use(VueSuperSlide)

import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI) //使用elementUI


Vue.config.productionTip = false
Vue.prototype.$gameStartState = window.gameStartState
Vue.prototype.$WxLogin = window.WxLogin

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <div class="content">
    <div class="sc-hOqqkJ iWjqsL">
      <div class="sc-crrsfI iDhzRL title large">
        <div class="title-icon">
          <img src="../assets/images/icon17.png" alt="" />
        </div>
        <div class="title-content">个人中心</div>
        <div class="title-extra"></div>
      </div>
      <div class="sc-idOhPF gmcnav user-box">
        <div class="list">
          <div class="list-header">基本资料</div>
          <div class="list-item">
            <div class="list-item-icon">
              <img src="../assets/images/usericon1.png" alt="" />
            </div>
            <div class="list-item-label">账户ID：</div>
            <div class="list-item-value">{{ securityUUID }}</div>
            <div class="list-item-extra"></div>
          </div>
          <div class="list-item">
            <div class="list-item-icon">
              <img src="../assets/images/usericon2.png" alt="" />
            </div>
            <div class="list-item-label">头像：</div>
            <div class="list-item-value">
              <span class="ant-avatar ant-avatar-circle ant-avatar-image">
                <img
                  :src="userInfo.userimg"
                  v-if="
                    userInfo.userimg && userInfo.userimg.indexOf('http') != -1
                  "
                />
                <img src="../assets/images/doge.png" v-else />
              </span>
            </div>
          </div>
          <div class="list-item">
            <div class="list-item-icon">
              <img src="../assets/images/usericon3.png" alt="" />
            </div>
            <div class="list-item-label">昵称：</div>
            <div class="list-item-value">{{ userInfo.username }}</div>
            <div class="list-item-extra">
              <div
                class="
                  sc-dlfnbm
                  cvOASq
                  my-button
                  ellipsis
                  ml
                  primary
                  ghost
                  edit-btn
                "
                @click="showUpdateUserName = true"
              >
                修改昵称
              </div>
            </div>
          </div>
          <div class="list-item">
            <div class="list-item-icon">
              <img src="../assets/images/usericon4.png" alt="" />
            </div>
            <div class="list-item-label">注册时间：</div>
            <div class="list-item-value">{{ userInfo.firstdate }}</div>
            <div class="list-item-extra">
              <div
                class="sc-dlfnbm cvOASq my-button ellipsis ml primary ghost"
                @click="showAuthentication"
              >
                实名认证
              </div>
            </div>
          </div>
          <!-- <div class="list-item">
            <div class="list-item-icon">
              <img src="../assets/images/usericon5.png" alt="" />
            </div>
            <div class="list-item-label">登记地址：</div>
            <div class="list-item-value ellipsis" title="暂无地址">
              {{
                userInfo.address && userInfo.address.indexOf("null") === -1
                  ? userInfo.address
                  : "暂无地址"
              }}
            </div>
            <div class="list-item-extra">
              <div class="sc-dlfnbm cvOASq my-button ellipsis ml primary ghost">
                <router-link to="/address">管理收货地址</router-link>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="list">
                    <div class="list-header">安全与隐私</div>
                    <div class="list-item">
                        <div class="list-item-icon"><img src="../assets/images/usericon6.png" alt=""></div>
                        <div class="list-item-label">绑定手机号：</div>
                        <div class="list-item-value">123123123</div>
                    </div>
                </div> -->
      </div>
    </div>

    <div class="utility_manager nameplate_platform" v-if="false">
      <div class="game_platform">
        <div class="utility_title">
          我的游戏
          <router-link to="/my-game">
            <p>全部游戏 ></p>
          </router-link>
        </div>
        <div class="platform_body">
          <div class="platform_item STEAM">
            <i class="icon i-steam"></i>
            <span>STEAM</span>
            <div class="platform_item_bottom">
              <p>
                拥有：<span class="owned">2</span><span class="all">/2087</span>
              </p>
            </div>
          </div>
          <div class="platform_item ORIGIN">
            <i class="icon i-origin"></i>
            <span>ORIGIN</span>
            <div class="platform_item_bottom">
              <p>
                拥有：<span class="owned">0</span><span class="all">/80</span>
              </p>
            </div>
          </div>
          <div class="platform_item UPLAY">
            <i class="icon i-uplay"></i>
            <span>UPLAY</span>
            <div class="platform_item_bottom">
              <p>
                拥有：<span class="owned">0</span><span class="all">/90</span>
              </p>
            </div>
          </div>
          <div class="platform_item EPIC">
            <i class="icon i-EPIC"></i>
            <span>EPIC</span>
            <div class="platform_item_bottom">
              <p>
                拥有：<span class="owned">0</span><span class="all">/70</span>
              </p>
            </div>
          </div>
          <div class="platform_item MORE">
            <i class="icon i-more"></i>
            <span>其他游戏</span>
            <div class="platform_item_bottom">
              <p>
                拥有：<span class="owned">0</span><span class="all">/66</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改昵称 -->
    <div
      role="document"
      class="edit_username sc-eLgOdN kFoAyH sc-hOqqkJ bKiBMa"
      style="width: 520px; transform-origin: 794px -41.5px"
      v-show="showUpdateUserName"
    >
      <div
        tabindex="0"
        aria-hidden="true"
        style="width: 0px; height: 0px; overflow: hidden; outline: none"
      ></div>
      <div class="ant-modal-content">
        <button type="button" aria-label="Close" class="ant-modal-close">
          <span class="ant-modal-close-x" @click="showUpdateUserName = false">
            <span
              role="img"
              aria-label="close"
              class="anticon anticon-close ant-modal-close-icon"
            >
              <i
                class="iconfont icon-close icon-close2"
                style="color: #ffffff"
              ></i>
            </span>
          </span>
        </button>
        <div class="ant-modal-body">
          <input
            maxlength="14"
            type="text"
            class="ant-input"
            v-model="userInfo.username"
          />
          <div
            class="sc-dlfnbm cvOASq my-button ellipsis ml primary cancel2_btn"
            @click="updateUserName"
          >
            确定
          </div>
        </div>
      </div>
      <div
        tabindex="0"
        aria-hidden="true"
        style="width: 0px; height: 0px; overflow: hidden; outline: none"
      ></div>
    </div>
  </div>
</template>

<script>
import { userApi } from "../api";
import { getUUID, getSign } from "../common/utils";
import { BASE_IMAGE_URL } from "../common/config.json";
import { EventBus } from "../common/event-bus.js";
import MD5 from "js-md5";

export default {
  name: "Nameplate",
  data() {
    return {
      BASE_IMAGE_URL,
      userInfo: {},
      showUpdateUserName: false,
    };
  },
  activated() {
    this.loadData();
  },
  computed: {
    securityUUID() {
      return this.userInfo.uuid
        ? MD5(this.userInfo.uuid).toUpperCase().substring(0, 20)
        : "";
    },
  },
  methods: {
    loadData() {
      userApi.reqUserInfo(getUUID(), 0, getSign()).then((res) => {
        this.userInfo = res.userinfo[0];
      });
    },

    updateUserName() {
      const { username } = this.userInfo;
      if (username === "") {
        return;
      }
      userApi
        .reqUpdateUserInfo(getUUID(), 0, getSign(), username)
        .then((res) => {
          if (res.code != 0) {
            return this.$message.error(res.msg);
          } else {
            let user = JSON.parse(localStorage.getItem("USER"));
            user.username = username;
            localStorage.setItem("USER", JSON.stringify(user));
            EventBus.$emit("uploadUserInfo", true);
            this.showUpdateUserName = false;
          }
        });
    },
    showAuthentication() {
      EventBus.$emit("showAuthentication", true);
    },
  },
};
</script>

<style scoped>
@import url("../assets/css/nameplate.css");

.content {
  max-width: 1134px;
  margin: 60px auto;
}

.iWjqsL .title {
  margin-bottom: 20px;
}

.iDhzRL {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.iDhzRL .title-icon {
  margin-right: 6px;
}

.iDhzRL .title-content {
  font-size: 18px;
}

.iWjqsL .user-box {
  padding: 20px 40px;
}

.iWjqsL .list {
  margin-bottom: 20px;
}

.iWjqsL .list-header {
  font-size: 14px;
  font-weight: 600;
}

.iWjqsL .list-item {
  padding: 18px 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    transparent 50%
  );
  background-size: 12px 1px;
  background-repeat: repeat-x;
  background-position: center bottom;
}

.iWjqsL .list-item-icon {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.iWjqsL .list-item-label {
  width: 84px;
  flex: 0 0 84px;
  margin-right: 14px;
  color: rgb(211, 211, 211);
}

.iWjqsL .list-item-value {
  flex: 1 1 0%;
  font-weight: 600;
}

.iWjqsL .list-item-extra {
  padding: 0px 20px;
}

.ant-avatar-image {
  background: transparent;
}

.ant-avatar {
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
}

.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.iWjqsL .list-item-extra .my-button {
  width: 84px;
  height: 26px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 400;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
  background-image: linear-gradient(270deg, #cc3930, #f95244);
  opacity: 0.8;
}

.iWjqsL .list-item-extra .my-button:hover {
  opacity: 1;
}

.my-button a {
  line-height: 26px;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.edit_username {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  position: absolute;
}

.bKiBMa .ant-modal-content {
  padding: 40px 40px 0px;
  text-align: center;
}

.kFoAyH .ant-modal-content {
  /* border-radius: 10px; */
  background-color: #18181a;
}

.ant-modal-content {
  position: relative;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 12%),
    0 6px 16px 0 rgba(0, 0, 0, 8%), 0 9px 28px 8px rgba(0, 0, 0, 5%);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 12%), 0 6px 16px 0 rgba(0, 0, 0, 8%),
    0 9px 28px 8px rgba(0, 0, 0, 5%);
  pointer-events: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125 em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.bKiBMa .ant-modal-content .ant-input {
  margin-bottom: 20px;
}

.kFoAyH .ant-input,
.kFoAyH .ant-input-number {
  width: 100%;
  color: rgb(255, 255, 255);
  border-color: transparent;
  background: rgb(60, 69, 104);
}

.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cvOASq.ml {
  width: 91px;
  height: 36px;
  font-size: 16px;
  line-height: 33px;
  font-weight: 500;
}

.cvOASq.primary {
  /* background: var(--bg-color); */
  opacity: 0.8;
  background-image: linear-gradient(270deg, #cc3930, #f95244);
}

.cvOASq.primary:hover {
  opacity: 1;
}

.cvOASq {
  color: rgb(255, 255, 255);
  touch-action: manipulation;
  display: inline-flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0px 8px;
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease 0s;
}
</style>

<template>
  <div>
    <div class="h5_container" v-loading="loading" v-if="!errorParam">
      <div class="h5_header" :class="colorClass">确认订单</div>
      <div class="h5_name">商品名：{{ payParam.gamename }}</div>
      <div class="h5_content">
        <p>金额：</p>
        <div class="h5_money">
          <span>￥</span>
          <span class="total">{{ payParam.xfmoney }}元</span>
        </div>
      </div>
      <div class="h5_button">
        <div class="button" :class="colorClass" @click="getPayFn()">支付</div>
      </div>
      <div class="lead_loading" v-if="lead_loading">
        <div>
          <img
            class="background"
            src="https://imgcloud.huifangkuai.com/Display/stm/wexin_log.e945c72b.png"
          />
          <img
            class="background1"
            src="https://imgcloud.huifangkuai.com/Display/stm/wexin_log.e945c72b1.png"
          />
        </div>
      </div>
      <div class="lead_loading" v-else-if="zfb_lead_loading">
        <div>
          <img
            class="background"
            src="https://imgcloud.huifangkuai.com/Display/stm/alipay_log.e945c72b.png"
          />
          <img
            class="background1"
            src="https://imgcloud.huifangkuai.com/Display/stm/alipay_log.e945c72b1.png"
          />
        </div>
      </div>
    </div>
    <div class="h5_container errorStatus" v-else>
      <div class="icon">
        <i class="el-icon-warning" style="font-size: 100px; color: #e26a6b"></i>
      </div>
      <div class="error_desc">
        请使用{{ this.colorClass == "zfb" ? "支付宝" : "微信" }}扫码，进行支付
      </div>
    </div>
  </div>
</template>

<script>
import { payApi, userApi } from "../api";
import { getUUID, getSign } from "../common/utils";
// import { EventBus } from "../common/event-bus";
export default {
  name: "H5",
  data() {
    return {
      imgs: {},
      progressCurrent: 1,
      payParam: {},
      colorClass: "",
      loading: true,
      lead_loading: false,
      timer: "",
      isPay: false,
      errorParam: false,
      zfb_lead_loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.payParam = this.$route.query;
    userApi.reqOppay({ flag: 1, pid: this.$route.query.pid }).then((res) => {
      var _res = res.data;
      let paydetail = JSON.parse(_res.url).paydetail;

      if (paydetail == "alipay") {
        if (res.zfbbneila === 0) {
          this.zfb_lead_loading = true;
        }
      } else {
        if (res.wxbneila === 0) {
          this.isWeixin();
        }
      }
      //  if (res.wxbneila === 0) {
      //     this.isWeixin();
      //   }
      this.payParam = JSON.parse(_res.url);
      this.payParam.sceneType = this.getH5Os();
      this.colorClass = this.payParam.paydetail === "alipay" ? "zfb" : "wx";
      // this.isTrueParam();
      this.$nextTick(() => {
        this.loading = false;
        // this.getPayFn()
      });
    });
  },
  methods: {
    isWeixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // 当前浏览器是微信打开
        this.lead_loading = true;
        return true; // 是微信端
      } else {
        this.lead_loading = false;
        return false;
      }
    },
    isTrueParam() {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // 当前浏览器是微信打开
        if (this.colorClass === "zfb") {
          this.errorParam = true;
          return false;
        } else {
          this.lead_loading = true;
          return true; // 是微信端
        }
      } else if (ua.match(/Alipay/i) == "alipay") {
        this.lead_loading = false;
        if (this.colorClass === "wx") {
          this.errorParam = true;
          return false;
        } else {
          return true;
        }
      } else {
        this.lead_loading = false;
        return false;
      }
    },
    getH5Os() {
      var u = navigator.userAgent;
      let os;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        os = "Android";
      } else if (u.indexOf("iPhone") > -1) {
        os = "iOS";
      } else {
        os = "Wap";
      }
      return os;
    },

    getPayFn() {
      this.getPayType((payInterface) => this[`payService_${payInterface}`]());
    },
    // 获取当前可用的支付接口
    getPayType(fn) {
      payApi.reqGetPayType(getUUID(), 0, getSign()).then((res) => {
        fn(res.msg);
      });
    },
    // 支付服务2(返回的是网络链接) , 方式二调用服务端接口分析
    // payService_JK() {
    //   if (this.isPay) {
    //     console.log("不能点");
    //     return false;
    //   }
    //   this.isPay = true;
    //   this.loading = true;
    //   clearTimeout(this.timer);
    //   // 第二个接口金额单位是分需要处理一下
    //   const { xfmoney } = this.payParam;
    //   // const newXfmoney = xfmoney * 100;
    //   // 判断是哪个支付平台
    //   const paydetail = this.payParam.paydetail === "alipay" ? 1 : 2;
    //   let newXfmoney=0
    //   if (paydetail===2) {
    //     // 微信
    //       newXfmoney = Math.floor(xfmoney);
    //   } else {
    //     // 支付宝
    //     newXfmoney = xfmoney * 100;
    //   }
    //   userApi.reqGettime().then((time) => {
    //     this.payParam.paytime = time;
    //     userApi
    //       .reqOppay({ flag: 2, pid: this.$route.query.pid, xfdate: time })
    //       .then((res) => {
    //         console.log(res);
    //           // payApi
    //           //   .reqJkpayop({
    //           //     ...this.payParam,
    //           //     xfmoney: newXfmoney,
    //           //     paydetail,
    //           //   })
    //           //   .then((res) => {
    //           //     // const tradeNo = res.split("=")[1];
    //           //     // 获取链接参数订单号, 调用支付宝和微信对应接口
    //           //     // 微信是url 直接跳转
    //           //     this.timer = setTimeout(() => {
    //           //       console.log("可以点了");
    //           //       this.isPay = false;
    //           //       this.loading = false;
    //           //     }, 2000);
    //           //     if (paydetail == 2) {
    //           //       window.location.href = res;
    //           //     } else {
    //           //       // 支付宝是表单，手动操作
    //           //       document.querySelector("body").innerHTML = res;
    //           //       document.forms[0].submit();
    //           //     }
    //           //   });
    //         if (paydetail===2) {
    //           // 微信支付使用聚合平台的通道
    //           payApi
    //             .reqJHpayop({
    //               ...this.payParam,
    //               xfmoney: newXfmoney,
    //               paydetail,
    //             })
    //             .then((res) => {
    //               // const tradeNo = res.split("=")[1];
    //               // 获取链接参数订单号, 调用支付宝和微信对应接口
    //               // 微信是url 直接跳转
    //               this.timer = setTimeout(() => {
    //                 console.log("可以点了");
    //                 this.isPay = false;
    //                 this.loading = false;
    //               }, 2000);
    //               if (paydetail == 2) {
    //                 window.location.href = res;
    //               } else {
    //                 // 支付宝是表单，手动操作
    //                 document.querySelector("body").innerHTML = res;
    //                 document.forms[0].submit();
    //               }
    //             });
    //         } else {
    //           payApi
    //             .reqJkpayop({
    //               ...this.payParam,
    //               xfmoney: newXfmoney,
    //               paydetail,
    //             })
    //             .then((res) => {
    //               // const tradeNo = res.split("=")[1];
    //               // 获取链接参数订单号, 调用支付宝和微信对应接口
    //               // 微信是url 直接跳转
    //               this.timer = setTimeout(() => {
    //                 console.log("可以点了");
    //                 this.isPay = false;
    //                 this.loading = false;
    //               }, 2000);
    //               if (paydetail == 2) {
    //                 window.location.href = res;
    //               } else {
    //                 // 支付宝是表单，手动操作
    //                 document.querySelector("body").innerHTML = res;
    //                 document.forms[0].submit();
    //               }
    //             });
    //         }
    //       });
    //   });
    // },
    // payService_JK() {
    //   if (this.isPay) {
    //     console.log("不能点");
    //     return false;
    //   }
    //   this.isPay = true;
    //   this.loading = true;
    //   clearTimeout(this.timer);
    //   // 第二个接口金额单位是分需要处理一下
    //   const { xfmoney } = this.payParam;
    //   const newXfmoney = xfmoney * 100;
    //   // 判断是哪个支付平台
    //   const paydetail = this.payParam.paydetail === "alipay" ? 1 : 2;
    //   userApi.reqGettime().then((time) => {
    //     this.payParam.paytime = time;
    //     userApi
    //       .reqOppay({ flag: 2, pid: this.$route.query.pid, xfdate: time })
    //       .then((res) => {
    //         console.log(res);
    //           payApi
    //             .reqJkpayop({
    //               ...this.payParam,
    //               xfmoney: newXfmoney,
    //               paydetail,
    //             })
    //             .then((res) => {
    //               // const tradeNo = res.split("=")[1];
    //               // 获取链接参数订单号, 调用支付宝和微信对应接口
    //               // 微信是url 直接跳转
    //               this.timer = setTimeout(() => {
    //                 console.log("可以点了");
    //                 this.isPay = false;
    //                 this.loading = false;
    //               }, 2000);
    //               if (paydetail == 2) {
    //                 window.location.href = res;
    //               } else {
    //                 // 支付宝是表单，手动操作
    //                 document.querySelector("body").innerHTML = res;
    //                 document.forms[0].submit();
    //               }
    //             });
    //       });
    //   });
    // },

    payService_JK() {
      if (this.isPay) {
        console.log("不能点");
        return false;
      }
      this.isPay = true;
      this.loading = true;
      clearTimeout(this.timer);
      // 第二个接口金额单位是分需要处理一下
      const { xfmoney } = this.payParam;
      const newXfmoney = xfmoney * 100;
      // 判断是哪个支付平台
      const paydetail = this.payParam.paydetail === "alipay" ? 1 : 3;
      userApi.reqGettime().then((time) => {
        this.payParam.paytime = time;
        userApi
          .reqOppay({ flag: 2, pid: this.$route.query.pid, xfdate: time })
          .then((res) => {
            console.log(res);
            if (this.payParam.paydetail === "wxpay") {
              payApi
                .reqWypayop({
                  ...this.payParam,
                  xfmoney: newXfmoney,
                  paydetail,
                  city: this.$gameStartState.city,
                })
                .then((res) => {
                  // const tradeNo = res.split("=")[1];
                  // 获取链接参数订单号, 调用支付宝和微信对应接口
                  // 微信是url 直接跳转
                  this.timer = setTimeout(() => {
                    console.log("可以点了");
                    this.isPay = false;
                    this.loading = false;
                  }, 2000);
                  if (paydetail == 3) {
                    window.location.href = res;
                    // window.location.href =
                    //   res.trade_wechat_jsapi_pay_response.cashier_url;
                    // window.location.href=res.code_qr_url
                  } else {
                    // 支付宝是表单，手动操作
                    document.querySelector("body").innerHTML = res;
                    document.forms[0].submit();
                    // window.location.href =
                    //   res.trade_alipay_qrcode_pay_response.qr_code;
                  }
                });
            } else {
              payApi
                .reqJkpayop({
                  ...this.payParam,
                  xfmoney: newXfmoney,
                  paydetail,
                  city: this.$gameStartState.city,
                })
                .then((res) => {
                  // const tradeNo = res.split("=")[1];
                  // 获取链接参数订单号, 调用支付宝和微信对应接口
                  // 微信是url 直接跳转
                  this.timer = setTimeout(() => {
                    console.log("可以点了");
                    this.isPay = false;
                    this.loading = false;
                  }, 2000);
                  if (paydetail == 2) {
                    // window.location.href = res;
                    window.location.href =
                      res.trade_wechat_jsapi_pay_response.cashier_url;
                  } else {
                    // 支付宝是表单，手动操作
                    // document.querySelector("body").innerHTML = res;
                    // document.forms[0].submit();
                    window.location.href = res;
                  }
                });
            }
          });
      });
    },
    // payService_CNS() {
    //   if (this.isPay) {
    //     console.log("不能点");
    //     return false;
    //   }
    //   this.isPay = true;
    //   this.loading = true;
    //   clearTimeout(this.timer);
    //   // 第二个接口金额单位是分需要处理一下
    //   const { xfmoney } = this.payParam;
    //   // const newXfmoney = xfmoney * 100;
    //   // 判断是哪个支付平台
    //   const paydetail = this.payParam.paydetail === "alipay" ? 1 : 2;
    //   let newXfmoney=0
    //   if (paydetail===2) {
    //     // 微信
    //       newXfmoney = Math.floor(xfmoney);
    //   } else {
    //     // 支付宝
    //     newXfmoney = xfmoney * 100;
    //   }
    //   userApi.reqGettime().then((time) => {
    //     this.payParam.paytime = time;
    //     userApi
    //       .reqOppay({ flag: 2, pid: this.$route.query.pid, xfdate: time })
    //       .then((res) => {
    //         console.log(res);
    //           // payApi
    //           //   .reqJkpayop({
    //           //     ...this.payParam,
    //           //     xfmoney: newXfmoney,
    //           //     paydetail,
    //           //   })
    //           //   .then((res) => {
    //           //     // const tradeNo = res.split("=")[1];
    //           //     // 获取链接参数订单号, 调用支付宝和微信对应接口
    //           //     // 微信是url 直接跳转
    //           //     this.timer = setTimeout(() => {
    //           //       console.log("可以点了");
    //           //       this.isPay = false;
    //           //       this.loading = false;
    //           //     }, 2000);
    //           //     if (paydetail == 2) {
    //           //       window.location.href = res;
    //           //     } else {
    //           //       // 支付宝是表单，手动操作
    //           //       document.querySelector("body").innerHTML = res;
    //           //       document.forms[0].submit();
    //           //     }
    //           //   });
    //         if (paydetail===2) {
    //           // 微信支付使用聚合平台的通道
    //           payApi
    //             .reqJHpayop({
    //               ...this.payParam,
    //               xfmoney: newXfmoney,
    //               paydetail,
    //             })
    //             .then((res) => {
    //               // const tradeNo = res.split("=")[1];
    //               // 获取链接参数订单号, 调用支付宝和微信对应接口
    //               // 微信是url 直接跳转
    //               this.timer = setTimeout(() => {
    //                 console.log("可以点了");
    //                 this.isPay = false;
    //                 this.loading = false;
    //               }, 2000);
    //               if (paydetail == 2) {
    //                 window.location.href = res;
    //               } else {
    //                 // 支付宝是表单，手动操作
    //                 document.querySelector("body").innerHTML = res;
    //                 document.forms[0].submit();
    //               }
    //             });
    //         } else {
    //           payApi
    //             .reqJkpayop({
    //               ...this.payParam,
    //               xfmoney: newXfmoney,
    //               paydetail,
    //             })
    //             .then((res) => {
    //               // const tradeNo = res.split("=")[1];
    //               // 获取链接参数订单号, 调用支付宝和微信对应接口
    //               // 微信是url 直接跳转
    //               this.timer = setTimeout(() => {
    //                 console.log("可以点了");
    //                 this.isPay = false;
    //                 this.loading = false;
    //               }, 2000);
    //               if (paydetail == 2) {
    //                 window.location.href = res;
    //               } else {
    //                 // 支付宝是表单，手动操作
    //                 document.querySelector("body").innerHTML = res;
    //                 document.forms[0].submit();
    //               }
    //             });
    //         }
    //       });
    //   });
    // },
    payCallBack_JK() {
      let count = 0;
      // 轮询支付成功状态
      const timer = setInterval(() => {
        payApi
          .reqJkweblunxun(
            this.payParam.Uuid,
            0,
            this.payParam.sign,
            this.payParam.paytime
          )
          .then((res) => {
            if (res.orderNo) {
              clearInterval(timer);
              count = 0;
              const timer2 = setInterval(() => {
                payApi
                  .reqJklunxun(
                    this.payParam.Uuid,
                    0,
                    this.payParam.sign,
                    res.orderNo
                  )
                  .then((res) => {
                    console.log(res);
                    if (res.msg === 1) {
                      this.paySuccess();
                      clearInterval(timer2);
                    } else if (count >= 60) {
                      clearInterval(timer2);
                    } else {
                      count++;
                    }
                  });
              }, 5000);
            } else if (count >= 60) {
              clearInterval(timer);
            } else {
              count++;
            }
          });
      }, 5000);
    },
  },
};
</script>

<style scoped>
.h5_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f8f8f8;
  color: #333;
  z-index: 999999999;
}

.lead_loading {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}
.lead_loading div {
  text-align: center;
  /* margin-top: 5%; */
  /* padding: 0 5%; */
  width: 100%;
  height: 100%;
}
.lead_loading div img {
  width: 100%;
  height: 80%;
}
.h5_header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.lead_loading div .background1 {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.lead_loading div .background {
  width: 100%;
  height: 80%;
}
.h5_name {
  padding: 20px 24px;
  background-color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.h5_content {
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
  padding: 20px 24px;
  font-weight: bold;
  background-color: #fff;
}
.h5_content p {
  font-size: 20px;
}
.h5_money {
  padding: 24px 0;
}
.h5_content .h5_money span {
  font-size: 18px;
  font-weight: bold;
}
.h5_content .h5_money .total {
  font-size: 36px;
  margin-left: 16px;
}
.h5_button {
  padding: 20px 24px;
}
.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border-radius: 4px;
}
.button:active {
  opacity: 0.8;
}
.zfb {
  background-color: #39c3f2;
}
.wx {
  background-color: #1ed76d;
}
.errorStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}
.error_desc {
  padding-top: 30px;
  font-size: 18px;
  font-family: "幼圆";
}
</style>

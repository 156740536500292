<template>
  <div class="mGameCenter">
    <div class="goods_box_l">
      <div class="main_three_tit flex_align">
        <img src="https://imgcloud.huifangkuai.com/Display/logo.png" alt="" />
        <div class="search_block row_box search_block_min">
          <div class="search_addon addon1">
            <i class="iconfont icon-icon_search"></i>
          </div>
          <!-- <div class="search_con"> -->
          <el-input
            class="search_input"
            placeholder="搜索游戏"
            v-model="search"
            @clear="toSearchFn(search)"
            @change="toSearchFn(search)"
            clearable
          />

          <!-- </div> -->
          <a
            class="search_addon endadd"
            href="javascript:void(0)"
            @click="toSearchFn(search)"
          >
            <!-- <i class="iconfont icon-close"></i> -->
            搜索
          </a>
        </div>
      </div>

      <div class="goods_l_content">
        <!-- 排序 -->
        <div class="goods_screen">
          <div class="goods_screen_item">
            <a
              href="javascript:void(0)"
              :class="sortType === 'px' && 'active'"
              @click="sortType = 'px'"
              >热销</a
            >
          </div>

          <div class="goods_screen_item">
            <a
              href="javascript:void(0)"
              :class="sortType === 'discount' && 'active'"
              @click="sortType = 'discount'"
              >折扣</a
            >

            <span>
              <i class="iconfont iconshangjiantou" style="display: none"></i>

              <i class="iconfont down icon-xiajiantou"></i>
            </span>
          </div>

          <div class="goods_screen_item">
            <a
              href="javascript:void(0)"
              :class="sortType === 'indate' && 'active'"
              @click="sortType = 'indate'"
              >最新</a
            >
          </div>

          <div class="goods_screen_item">
            <a
              href="javascript:void(0)"
              :class="sortType === 'sharemoney' && 'active'"
              @click="sortType = 'sharemoney'"
              >价格</a
            >

            <span>
              <i class="iconfont iconshangjiantou" style="display: none"></i>

              <i class="iconfont down icon-xiajiantou"></i>
            </span>
          </div>
        </div>

        <!-- 列表 -->

        <div
          class="goods_list"
          :style="loading && 'min-height: 752px;'"
          v-if="list.length > 0 && !loading"
        >
          <a
            href="javascript:void(0)"
            v-for="item in list"
            :key="item.id"
            @click="
              $router.push(`/mGamedetail/${item.platform}/${item.gameid}`)
            "
          >
            <div
              class="home-game-left-game-item home-game-left-game-item_active"
              :data-id="item.id"
            >
              <div class="game-item">
                <div class="game_vip" v-if="item.platform != 'Battle'">
                  {{ item.bVipmake == 1 ? "会员免费" : "Svip会员免费" }}
                </div>
                <div class="game-item-left">
                  <img
                    :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.list}`"
                    alt=""
                    class="game-img"
                  />
                </div>
                <div class="game-item-right">
                  <div class="title3">
                    <div class="title-left">
                      <div :title="item.gamename_zh" class="game-name ellipsis">
                        {{ item.gamename_zh }}
                      </div>
                      <!-- <div class="title-tag" style="display: ">
                              {{ item.platform }}
                            </div> -->
                    </div>
                    <!-- <div class="title-right">
                            原价：{{ item.money }}元
                          </div> -->
                  </div>
                  <ul class="tag-list">
                    <div class="tag-list-left">
                      <div
                        class="tag-list-item"
                        v-for="(tag, i) in item.gamelabel.split(',').slice(0, 6)"
                        :key="i"
                        :data-id="i"
                      >
                        {{ tag }}
                      </div>
                    </div>
                    <div class="tag-list-right">
                      <!-- <div
                              class="tag-list-item right-tag"
                              v-if="item.bSharemake === 1"
                            >
                              {{ ((item.sharemoney/item.money -1 )* 100).toFixed(0) }}%
                            </div> -->
                    </div>
                  </ul>
                  <!-- <div class="desc ellipsis">{{ item.gameinfo }}</div> -->
                  <div class="sale-info">
                    <div class="count">
                      <span class="span1">{{ item.buynum }}</span>

                      <span class="span2">人当前在线</span>
                    </div>

                    <!-- <div class="price">
                            {{
                              item.bSharemake !== 1
                                ? "暂未开启购买"
                                : "￥" + item.sharemoney
                            }}
                          </div> -->
                  </div>
                  <div class="price_info">
                    <div class="discount_box">
                      <div class="discount_text">
                        <div class="discount_text_money">
                          ￥{{ item.money.toFixed(2) }}
                        </div>
                        <div class="discount_text_time">
                          {{
                            ((item.sharemoney / item.money) * 10).toFixed(1)
                          }}折
                        </div>
                      </div>
                      <div class="money">
                        <span class="sharemoney"
                          >￥{{ item.sharemoney.toFixed(2) }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>

          <div class="loading-list" v-show="loading"></div>
        </div>

        <div class="no-game" v-else-if="loading">
          <img :src="imgs.no_game" alt="" />
          <h4>加载中...</h4>
        </div>

        <div class="no-game" v-else>
          <img :src="imgs.no_game" alt="" />
          <h4>没有找到您想要的游戏~</h4>
          <router-link to="/">
            <p>去首页看看</p>
          </router-link>
        </div>
      </div>
      <div class="topagebox">
        <button
          class="to_page_button"
          @click="toPage(currentPage == 1 ? 1 : currentPage - 1)"
        >
          {{ currentPage == 1 ? "第一页" : "上一页" }}
        </button>
        <button
          class="to_page_button"
          @click="
            toPage(currentPage === totalPage ? totalPage : currentPage + 1)
          "
        >
          {{ currentPage == totalPage ? "最后页" : "下一页" }}
        </button>
      </div>
      <div class="goods_page">
        <el-pagination
          background
          layout="prev, pager, next"
          style="text-align: center"
          :current-page="currentPage"
          :page-count="totalPage"
          @current-change="toPage"
          :pager-count="5"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 底部菜单栏 -->
    <!-- <TabBar :active="1"></TabBar> -->
  </div>
</template>
<script>
import { homeApi } from "../api";
import { BASE_IMAGE_URL } from "../common/config.json";
// import TabBar from "../components/tabBar.vue";
import {
  getUUID,
  getSign,
  getTotalPage,
  getFiveDigits,
  isMobile,
} from "../common/utils";
export default {
  // components: {
  //   TabBar,
  // },
  data() {
    return {
      sortType: "px", // 分类排序默认热销(热销:px,折扣:discount,最新:indate,返利:sharemoney,价格:money)
      imgs: {
        no_game: require("../assets/images/no_game.png"),
        wx: require("../assets/images/wx.png"),
        zfb: require("../assets/images/zfb.png"),
      },
      pageSize: 6, // 每页条数
      totalPage: 0, // 页数
      currentPage: 1,
      BASE_IMAGE_URL,
      noData: false,
      loading: false,
      num: 0,
      search: "",
      list: [], //游戏列表
    };
  },
  methods: {
    getFiveDigits,
    loadData(page, search = "") {
      this.loading = true;
      homeApi
        .reqGamelist(
          getUUID(),
          page,
          getSign(),
          this.pageSize,
          search,
          "",
          "",
          this.sortType,
          1,
          1
        )
        .then((res) => {
          this.list = res.list.map((i) => {
            i.buynum = getFiveDigits();
            return i;
          });
          this.noData = res.list.length <= 0;
          this.num = res.num[0]["count(*)"];
          this.totalPage = getTotalPage(this.num, this.pageSize);
          this.loading = false;
        });
    },
    toPage(pageNum) {
      this.currentPage = pageNum;
      this.loadData(pageNum, this.search);
      window.scrollTo(0, 0);
    },
    toSearchFn(search) {
      this.currentPage = 1;
      this.loadData(this.currentPage, search);
    },
  },
  watch: {
    sortType() {
      this.toPage(1);
    },
  },
  created() {
    if (!isMobile()) {
      this.$router.push("/gamecenter");
    }
    this.loadData(this.currentPage);
  },
};
</script>
<style lang="less" scoped>
.topagebox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;
  font-size: 12px;
  .to_page_button {
    color: #83807f;
    background-color: #404040;
    padding: 2px 5px;
    border-radius: 15px;
  }
}
.game_vip {
  padding: 0 2px 0 6px;
  height: 17px;
  line-height: 15px;
  color: #ffcf65;
  background-color: transparent;
  border-color: #ffcf65;
}
* {
  box-sizing: border-box;
}
a.active {
  color: #21eaf1;
}
.mGameCenter {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .search_block {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 300px;
    height: 34px;
    overflow: hidden;
    background: #313233;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 17px;
  }

  .search_block_min {
    width: 60vw;
    height: 6vw;
    margin-left: 20px;
  }

  .search_block .search_addon {
    width: 20px;
    height: 26px;
  }

  .search_block .addon1 {
    line-height: 100%;
    margin: 0 2vw;
  }

  .addon1 .iconfont {
    font-size: 20px;
    line-height: 28px;
    color: #dedede;
  }

  .search_block .search_input {
    flex: 1 1 0%;
    display: block;
    overflow: hidden;
    height: 20px;
    line-height: 20px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    padding-right: 10px;
    box-sizing: border-box;
    color: rgb(195, 198, 209);
  }

  .search_block .endadd {
    flex: 0 0 48px;
    cursor: pointer;
    user-select: none;
    color: rgb(255, 255, 255);
    text-align: center;
    background: linear-gradient(270deg, #cc3930, #f95244);
    font-size: 14px;
    height: 26px;
    line-height: 24px;
  }
  .main_three_tit {
    padding: 0;
    padding-top: 1vw;
    padding-left: 2vw;
    img {
      width: 36%;
      height: auto;
    }
  }
  .goods_box_l {
    width: 100%;
    .goods_l_content {
      .goods_screen {
        padding: 2vw;
        padding-left: 6vw;
        .goods_screen_item {
          margin-right: 8vw;
        }
      }
      .goods_list {
        width: 98%;
        margin: auto;
        a {
          background: #464646 !important;
          margin-bottom: 5px;
          border-radius: 3px;
        }
        .game-item {
          padding: 2vw;
          padding-left: 1vw;
          display: flex;
          align-items: center;
          &::after {
            display: none;
          }
          .game-item-left {
            flex: none;
            width: 34vw;
            height: 20vw;
          }
          .game-item-right {
            flex: 1;
            height: auto;
            min-height: 24vw;
            padding-left: 3vw;
            .sale-info {
              margin-top: 2.5vw;
              .count {
                font-size: 12px;
              }
            }
            .price_info {
              border: 2px solid rgba(0, 0, 0, 0);
              box-sizing: border-box;
              border-radius: 3px;
              // min-width: 26vw;
              position: absolute;
              right: 0;
              bottom: 0;
              overflow: hidden;
              transition: all 0.3s linear;
              .discount_box {
                display: flex;
                height: 8vw;

                .discount_text {
                  // min-width: 16vw;
                  height: 100%;
                  font-size: 10px;
                  font-weight: bold;
                  color: #a4d007;
                  background: #4c6b22;
                  text-align: center;
                  // line-height: 7vw;
                  // border-top-left-radius: 3px;
                  border-radius: 3px 0px 0px 3px;
                  .discount_text_money {
                    text-decoration: line-through;
                    transform: scale(0.9);
                    transform-origin: left;
                  }
                  .discount_text_time {
                    transform: scale(0.9);
                    transform-origin: left;
                  }
                }
                .money {
                  height: 100%;
                  // flex: 1;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 12px;
                  background-color: rgba(46, 68, 80);
                  color: #acdbf5;
                  // border-top-right-radius: 3px;
                  border-radius: 0px 3px 3px 0px;
                  padding: 0.5vw;
                  span {
                    height: 3vw;
                    line-height: 3vw;
                    letter-spacing: 1px;
                    // margin-left: 3px;
                  }
                  .sharemoney {
                    // width: 13vw;
                    // text-decoration: line-through;
                    letter-spacing: 0px;
                    font-size: 10px;
                    transform: scale(0.9);
                    transform-origin: left;
                    // margin-left: 1vw;
                  }
                }
              }

              .save_text {
                // padding: 0.2vw;
                background-color: #cc7a00;
                text-align: center;
                color: #000;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                font-size: 12px;
              }
            }
            .tag-list {
              margin: 1vw 0;
              height: 6vw;
              .tag-list-left {
                display: flex;
                flex-wrap: wrap;
                width: 100vw;
                height: 100%;
                overflow: hidden;
                .tag-list-item {
                  font-size: 12px;
                }
              }
            }

            .title3 {
              .title-left {
                .game-name {
                  max-width: 150px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
  .goods_page {
    padding: 3vw;
    /deep/.el-pagination {
      padding: 0;
    }
  }
}
</style>
<template>
  <div class="contnet">
    <!-- 无缝滚动图 -->
    <superslide :options="options1" class="slideBox">
      <div class="contnet_super">
        <ul>
          <li>
            <img src="../assets/imgs/1634626581_banner_img.png" alt="" />
          </li>
          <li>
            <img src="../assets/imgs/1634626604_banner_img.png" alt="" />
          </li>
        </ul>
      </div>
    </superslide>

    <div class="goods">
      <div class="w">
        <div class="goods_box">
          <div class="goods_box_l">
            <div class="main_three_tit flex_align">
              <div class="main_three_tit_icon" style="">
                <img
                  src="../assets/imgs/find_game.png"
                  alt=""
                  style=""
                  class="imglogo"
                />
              </div>

              <div>全部游戏</div>
            </div>

            <div class="goods_l_content">
              <!-- 排序 -->

              <div class="goods_screen">
                <div class="goods_screen_item">
                  <a
                    href="javascript:void(0)"
                    :class="sortType === 'px' && 'active'"
                    @click="sortType = 'px'"
                    >热销</a
                  >
                </div>

                <div class="goods_screen_item">
                  <a
                    href="javascript:void(0)"
                    :class="sortType === 'discount' && 'active'"
                    @click="sortType = 'discount'"
                    >折扣</a
                  >

                  <span>
                    <i
                      class="iconfont iconshangjiantou"
                      style="display: none"
                    ></i>

                    <i class="iconfont down icon-xiajiantou"></i>
                  </span>
                </div>

                <div class="goods_screen_item">
                  <a
                    href="javascript:void(0)"
                    :class="sortType === 'indate' && 'active'"
                    @click="sortType = 'indate'"
                    >最新</a
                  >
                </div>

                <!-- <div class="goods_screen_item">
                  <a
                    href="javascript:void(0)"
                    :class="sortType === 'sharemoney' && 'active'"
                    @click="sortType = 'sharemoney'"
                    >返利</a
                  >
                </div> -->

                <div class="goods_screen_item">
                  <a
                    href="javascript:void(0)"
                    :class="sortType === 'sharemoney' && 'active'"
                    @click="sortType = 'sharemoney'"
                    >价格</a
                  >

                  <span>
                    <i
                      class="iconfont iconshangjiantou"
                      style="display: none"
                    ></i>

                    <i class="iconfont down icon-xiajiantou"></i>
                  </span>
                </div>
              </div>

              <!-- 列表 -->

              <div
                class="goods_list"
                :style="loading && 'min-height: 752px;'"
                v-if="list.length > 0 && !loading"
              >
                <a
                  href="javascript:void(0)"
                  v-for="item in list"
                  :key="item.id"
                  @click="
                    $router.push(`/gamedetail/${item.platform}/${item.gameid}`)
                  "
                >
                  <div
                    class="
                      home-game-left-game-item home-game-left-game-item_active
                    "
                    :data-id="item.id"
                  >
                    <div class="game-item">
                      <div class="game_vip" v-if="item.platform != 'Battle'">
                        {{ item.bVipmake == 1 ? "会员免费" : "Svip会员免费" }}
                      </div>
                      <div class="game-item-left">
                        <img
                          :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.list}`"
                          alt=""
                          class="game-img"
                        />
                      </div>
                      <div class="game-item-right">
                        <div class="title3">
                          <div class="title-left">
                            <div
                              :title="item.gamename_zh"
                              class="game-name ellipsis"
                            >
                              {{ item.gamename_zh }}
                            </div>
                            <!-- <div class="title-tag" style="display: ">
                              {{ item.platform }}
                            </div> -->
                          </div>
                          <!-- <div class="title-right">
                            原价：{{ item.money }}元
                          </div> -->
                        </div>
                        <ul class="tag-list">
                          <div class="tag-list-left">
                            <div
                              class="tag-list-item"
                              v-for="(tag, i) in item.gamelabel
                                .split(',')
                                .slice(0, 5)"
                              :key="i"
                              :data-id="i"
                            >
                              {{ tag }}
                            </div>
                          </div>
                          <div class="tag-list-right">
                            <!-- <div
                              class="tag-list-item right-tag"
                              v-if="item.bSharemake === 1"
                            >
                              {{ ((item.sharemoney/item.money -1 )* 100).toFixed(0) }}%
                            </div> -->
                          </div>
                        </ul>
                        <!-- <div class="desc ellipsis">{{ item.gameinfo }}</div> -->
                        <div class="sale-info">
                          <div class="count">
                            <span
                              class="span1"
                              >{{(!item.buynum || item.buynum &lt; 10000) ? getFiveDigits() : item.buynum}}</span
                            >
                            <span class="span2">人当前在线</span>
                          </div>

                          <!-- <div class="price">
                            {{
                              item.bSharemake !== 1
                                ? "暂未开启购买"
                                : "￥" + item.sharemoney
                            }}
                          </div> -->
                        </div>
                        <div class="price_info">
                          <div class="discount_box">
                            <div class="discount_text">
                              -{{
                                Math.floor(
                                  (1 - item.sharemoney / item.money) * 100
                                )
                              }}%
                            </div>
                            <div class="money">
                              <span class="sharemoney"
                                >￥{{ item.money.toFixed(2) }}</span
                              >
                              <span
                                >{{
                                  ((item.sharemoney / item.money) * 10).toFixed(
                                    1
                                  )
                                }}折</span
                              >
                            </div>
                          </div>
                          <div class="save_text">
                            ￥{{ item.sharemoney.toFixed(2) }}元
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

                <div class="loading-list" v-show="loading"></div>
              </div>

              <div class="no-game" v-else-if="loading">
                <img :src="imgs.no_game" alt="" />
                <h4>加载中...</h4>
              </div>

              <div class="no-game" v-else>
                <img :src="imgs.no_game" alt="" />
                <h4>没有找到您想要的游戏~</h4>
                <router-link to="/">
                  <p>去首页看看</p>
                </router-link>
              </div>
            </div>

            <div class="goods_page">
              <el-pagination
                background
                layout="prev, pager, next"
                style="text-align: center"
                :current-page="currentPage"
                :page-count="totalPage"
                @current-change="toPage"
              >
              </el-pagination>
            </div>
          </div>

          <div class="goods_box_r">
            <div class="main_three_tit flex_align">
              <div class="main_three_tit_icon main_three_tit_icon1">
                <img
                  src="../assets/imgs/choice_game.png"
                  alt=""
                  class="imglogo"
                />
              </div>

              <div>选择游戏</div>
            </div>

            <!-- 游戏平台 -->

            <div class="goods_box_r_item">
              <div class="goods_type_title">游戏平台</div>

              <div class="goods_type_list">
                <a href="javascript:void(0)">
                  <div
                    class="goods_type_list_item"
                    :class="platformIndex == 0 && 'goods_type_list_item_active'"
                    @click="changePlatformIndex(0)"
                  >
                    全部平台
                  </div>
                </a>

                <a
                  href="javascript:void(0)"
                  v-for="(item, i) in platform"
                  :key="i"
                >
                  <div
                    class="goods_type_list_item"
                    :class="
                      platformIndex === i + 1 && 'goods_type_list_item_active'
                    "
                    @click="changePlatformIndex(i + 1)"
                  >
                    {{ item.platform == "Alone" ? "其他" : item.platform }}
                  </div>
                </a>
              </div>
            </div>

            <!-- 游戏分类 -->

            <div class="goods_box_r_item">
              <div class="goods_type_title">游戏分类</div>

              <div class="goods_type_list">
                <a href="javascript:void(0)">
                  <div
                    class="goods_type_list_item"
                    :class="flagIndex === 0 && 'goods_type_list_item_active'"
                    @click="changeFlagIndex(0)"
                  >
                    全部游戏
                  </div>
                </a>

                <a
                  href="javascript:void(0)"
                  v-for="(item, i) in flag.slice(0, 15)"
                  :key="i"
                >
                  <div
                    class="goods_type_list_item"
                    :class="
                      flagIndex === i + 1 && 'goods_type_list_item_active'
                    "
                    @click="changeFlagIndex(i + 1)"
                  >
                    {{ item.gameflag }}
                  </div>
                </a>
              </div>
            </div>
            <!-- 游戏图片 -->
            <div class="goods_box_r_gameimg">
              <div class="big_img">
                <img
                  :src="`${BASE_IMAGE_URL}/Steam/814380/Ranner/1.jpg`"
                  alt=""
                />
              </div>
              <div class="small_img">
                <div
                  :class="item.class"
                  v-for="(item, index) in smallImgArr"
                  :key="item.gameid"
                  @click="clickJump(item, index)"
                >
                  <img :src="item.src" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="增值服务"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-click-escape="false"
      :before-close="handleClose"
      class="member_dialog"
    >
      <div class="member_dialog_container vip-level">
        <div class="vip_paytype">
          <div class="paytype_contianer">
            <div class="pay-select">
              <div class="pay-select-title">付款方式</div>
              <div class="pay-type">
                <el-radio v-model="payment" :label="1">借记卡/信用卡</el-radio>
              </div>
              <div class="ant-radio-group ant-radio-group-outline">
                <label
                  class="
                    ant-radio-wrapper
                    ant-radio-wrapper-checked
                    ant-radio-wrapper-disabled
                  "
                >
                  <span class="ant-radio ant-radio-checked ant-radio-disabled">
                    <span class="ant-radio-inner"></span>
                  </span>
                  <div class="radio-desc">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAACVUlEQVRIS83TXUhTcRjH8e9xy+aWL0MnS0MXminYi7moDKyksshkYpFoUJBR0C4sujTwQrCwUiTDiwqLIiMvLJRGRkZqGAwMEhTCWi+o2WTqppvT7YTnohIXeQSlc3MOnP9zPv/n9z+PIIqiyDJewn8PVj+yMj7ukTJJMERStC9FVj6yOwzaUoEoCr+QJ7VHyc1IWDAqC/wy7CQ+uxYmRxCmJ5g9/JKa81TlrFsa8FbLe06XtoBzCMHvRUxPI/vUYSwH9EsDlt/u5NKNdsjbBdowFBo1jVnRmAyapQHPXWnlZoMVzAVUGLUUrw8lSqVYMDa7UNYZPn3TT5vNSbU7nN78WJIjgmVhskHZXw9QELDDusfdvO0ZYFNSNOZj6SiVQQGtV4Nu7n5wEatRYk4JQ6/+HW91zximeDWG0BVzagOCxsJ6ivM2UlrbTnPNETxeH1ERISgVQcz4/KQm6ujo/kqTV4PH56fHMU1OnJrdq1WoFAJKQaCgbRhLtn7OJv4a6aqMa2xI1OGc9KKP1JC8NpLm1/2Und1JWV0HVy9k0WDpxbN/B59dMzim/BxcE8Kg28eo1892nYr7/S6GCuPmJTOvwyG7i80Fd+i6dwJDTDiqbZU0VuZxseolTdfzMV9+jm1wDOuDkxif/aA+U4dRtxJT63epQ6t9iuTwYF4MuOnKjfk3aBsYk+I6fihVWmzp/EifbUR6Linayt4zD6V7TmYi5d0OStO00ru+US+NnyakMUnVBmOf8mGKnz+fssbC7pik4903THuSFv3DygIXrfxRuOzgT6b+A9rsg+bTAAAAAElFTkSuQmCC"
                      alt=""
                    /><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAACwklEQVRIS+2TXUjTYRTGH5vL4Qe6OVGGbs0ya36NXGrm8oPK7MsorVkWhoklSTdeVCgNvGiGhKIWgYIXhhM17EOdYWkkOSxs5tRlizmaojlxpta0bf/YWwjRurO88b16eTnnPOf5nfc4URRF4T8ep3XB1aa9RkitZtjGHoCafv3TEFMAWnAOufZoplDTpUUEj4nQAC/c79EhOYIDSSwf6k+zqOzQ4P3EF7gznHElZTv2hnFIXunjIQwZTIjewsbFfcEroH5z+N00ClVTLoTuH0FPHwVoDBwueYbWtwbU5++BUjuN8vYRVGRFQbSZDfGNdtBoThAFsmGYWYQsIxKS3XwoP0xjV1EbEbE3qio56ljQ/hpT2Aop5y72pxfhMz0cAXmN8PZwgeFOOhHveDeBl9IDeNJvQMkjNbITg1CdG0sKWqw2ONM2QFL+Ag29Y+Cx3aA3LmK+9jTcGXQS88cMpY0qyFpU0F73QZ2Wi2v1/Sg+KUTh8Qj45TZgas6M2ZoM4iJF1kmKxAt8UXYuCsJNLOKUn9+MeIEftnE8UfVUg66iZCSE+DkWtM9MLFUgS8xD14gRk3PfMFaRBgadBmZ2PXw9GZi8d4okdw5OoKDuDQb0s/By3QhdxQnIHg4S5/YRWGw2nK3qwc2MHbiaGuZY0I6FnSOH1UphYcmCM3GBqLssRvfQJBKLO5AczkFLQRIpZsdkXrbCP68RMwtLBPWRW89h+roMf5YrLFaKNJwqCiA5DpHaH9Nud6O5T08CeosPIibIB5WKEeTX9pGfeEzEJTiFPBaM82Zop+YRyWfhQtJWXKpRIpLvjVCuF8lvUuoJHWO15O+CCtU45L06sD1cUJq5kwTKX+mgGBhHZlwg/FluKGsfhmZ8jrhMEPiSr1/WNkzECw6FIJTL/LUeaqgNJlSejyaxa7T4K1vy7y/rDled8Q/XZ03a6n3J9gAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAACbklEQVRIS82US2gTURSG/3snmaZtYpMmLWOlMtQUs4i1Wim29uGiSBCFqBu7EFF0I8EHBEQISN0IYlFxpSJkK3YRJMWF6MZsBEULWbgQDFhIKbEmMWle85DcYCDmpk4Rinczw/3/c757ztw5RNd1HVu4yH8HVBPfUFpYhLacBHF1Q5yZgPngCOuJllqClnwLPZ8EsfaD9s+Adrk37FfLCnVFQT50B4X5R4CiNiRp80+i/aIJ6uqbxuREgLD7DMyjcwA1ccEtgbmrN1F48LQ5iOpwnEiB9mooH+gBKGny0IGTEKceGgcqn+L4sc/HDWgfzsO6N8O0yoAN6k4r1yf6noNK400at8Jc8BYK84+5iRyzKZjEcu0bdggoj/ZyfcLgLMyH7hoDZo6dRXnxNTeR69wKiKbVNAIUp7dzfVQag+hbMAbMnr6E0rMX3ETO8yugag2oCwSlSenfgev3nyB/bY6baNvRNbT1FJmm2kVUhp1cn2noMkz7rxurUEtnsCaPQc9kmwLMcgn26e9sv7zHAc1p4dxkEeLxl6AOjzFg1VWKvkLWfwFQG//BqtZ5+CfEcRXKYFczjAgwT9yDsOsUt/INR1vl/RJygRCUdx/rwXSHhI7QFYhHbKh8uA3kl+sacQ7BPHIDtG+KC2P3zMgs1VZWoXz+Aurqhsnb2CY9+xX6ehLEJoN09rUE/RZaAiORCIrFIjweD+LxOLxeL3K5HKLRKPx+P4tPp9PsKUkS87jdtTkai8UQDAY319JwOMwCUqkUA8uyjEQiAbvdzkAul6t+GIvFwrQq0Gq1svdAIIDq/p/LUEv/2qdNGLYc+Att0AbamzzyOQAAAABJRU5ErkJggg=="
                      alt=""
                    /><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAACv0lEQVRIS+1UXUiTURh+tum+/evMOYeaFuIsDS1D7UeslH7IiFjgRZkXEhEYBtVFdOWFmFD0Z1BUIGRUkJSkMDNTyZHEqMSGFktLJ5rTuZ9v+zb3bcY5kBrYnSVE5+acm/M87/O8z/sKUDU4h794BP8Jl9vtlbW0dKMSHZ99iJYKkZMkweP3HuxfL4fNxSM7gaFirfYgUjWR4ENAzzCH9Dgx2ga92LZGCgUjhM0ZRKxChG4rh/JcFZr7WWTEM+gbC4CdDWNeYX6yBPfLdHjywYNPk7O4YdCi4PoI2k8mob5nBqcL1bjaPQPziB8NR3Q4/9yOE1ujKcijdx5sXyvFA7MbZ3aqKWHBtVHMXEzFLZOTFlJtnMZXR3CB8OExHVWwI1WGauMU8lOkyEuWwjTkw6iLnyfssvrQcjwRbCCMS50OlGQo8HE8gBaLF989PJ5WJGDCzaPR7MbZXTEwj/qRGc9g323bAqFCLMSVQxrUtDtgyFJCIxeBAFdsicLlVw7otWIczVFRa3uGOBSnyVDzYhqlm1RQMALcfO1EbYmGglvGA7BOBVFVqKatOdU0CSKmrHEcE57QgsLFaSTep8REwubkqR333rhQnqeC0xem/ZtiQzANczi4QQF/cA6tFhaGbCWFID3bky6HJFKAVouXviNEQN1LB/z83NKEnZVJaHjrptYoGSFqS2Jxt9eFdK0YxgEvKgvUMA1xICuKFObiQtCqIvCsn0ViVAQydQxaLCy9iQNdVg5NfR5a0JJjQQiJpaTJE+4Q6g1xNDCbV0tA7O/9xsHFhUFSTQJyrtmOC7tX0T9tAz4Upcmwd52c9jFeJcLhLCUO3Bn7NTRLWUoIiaUkfeW5UVQxGQEnF54fE32cGE4uRENGzhd7EEV6GS2UhKlYL6OjVNcxDT78G4XLvV0W463spvmTyn5i//sKfwAZzFEOBYDJ3QAAAABJRU5ErkJggg=="
                      alt=""
                    /><span>暂不支持中国大陆地区</span>
                  </div>
                </label>
              </div>
              <div class="pay-type">
                <el-radio v-model="payment" :label="1">移动支付</el-radio>
              </div>
            </div>
            <div class="pay-content" style="margin: 15px auto">
              <div class="pay-content-payType">
                <button
                  class="payType-item zfb-pay"
                  :class="payType === 0 && 'active'"
                  @click="payType = 0"
                >
                  <!-- <span class="payType-tips payType-tips_solid">推荐</span> -->
                  <span
                    class="payType-tips"
                    v-if="Number($gameStartState.zfbCharges) != 1"
                    >{{ $gameStartState.zfbChargesText }}</span
                  >
                  <span v-else class="payType-tips payType-tips_solid"
                    >推荐</span
                  >
                  <img :src="imgs.zfb" alt="" /> 支付宝
                </button>
                <button
                  class="payType-item wx-pay"
                  :class="payType === 1 && 'active'"
                  @click="payType = 1"
                >
                  <span
                    class="payType-tips"
                    v-if="Number($gameStartState.wxCharges) != 1"
                    >{{ $gameStartState.wxChargesText }}</span
                  >
                  <img :src="imgs.wx" alt="" />微信支付
                </button>
              </div>
              <div class="pay-amount" style="margin: 10px 0 20px">
                输入金额：
                <div style="display: flex; align-items: flex-end">
                  <span style="font-size: 14px; margin-right: 5px">￥</span
                  ><el-input-number
                    size="small"
                    style="width: 80px"
                    v-model="xfmoney"
                    :controls="false"
                    @change="handleChange"
                    :min="1"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </div>
              <div class="pay-content-button" @click="getQrCode">去支付</div>
              <p class="pay-amount">
                应付金额
                <span>
                  <span>￥</span
                  >{{
                    payType === 0
                      ? (xfmoney * $gameStartState.zfbCharges).toFixed(2)
                      : (xfmoney * $gameStartState.wxCharges).toFixed(2)
                  }}
                </span>
              </p>
              <p class="pay-tips">
                支付即代表您同意<span @click="showAgreement = true"
                  >《购买服务协议》</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { homeApi } from "../api";
import { getUUID, getSign, getTotalPage, getFiveDigits } from "../common/utils";
import { BASE_IMAGE_URL } from "../common/config.json";
import { EventBus } from "../common/event-bus";
import { getPayParam, checkUser, getUser } from "../common/utils";
export default {
  name: "Gamecenter",
  props: {
    search: String,
  },
  data() {
    return {
      superShow: false,
      options1: {
        mainCell: "ul",
        autoPlay: true,
        effect: "leftMarquee",
        vis: 2,
        interTime: 50,
        trigger: "click",
        mouseOverStop: false,
      },
      BASE_IMAGE_URL,
      list: [], //游戏列表
      sortType: "px", // 分类排序默认热销(热销:px,折扣:discount,最新:indate,返利:sharemoney,价格:money)
      loading: false,
      noData: false, // 判断有没有数据
      num: 0, // 列表数量
      flag: [], //游戏分类
      platform: [], //平台
      flagIndex: 0,
      platformIndex: 0,
      pageSize: 10, // 每页条数
      totalPage: 0, // 页数
      currentPage: 1,
      imgs: {
        no_game: require("../assets/images/no_game.png"),
        wx: require("../assets/images/wx.png"),
        zfb: require("../assets/images/zfb.png"),
      },
      gameImgList: [],
      smallImgArr: [
        {
          class: "img_h152",
          platform: "Steam",
          gameid: "601150",
          src: `${BASE_IMAGE_URL}/Steam/601150/Ranner/1.jpg`,
        },
        { class: "img_h152", src: require("../assets/images/vipImg.png") },
        {
          class: "img_h152",
          platform: "Steam",
          gameid: "1446780",
          src: `${BASE_IMAGE_URL}/Steam/1446780/Ranner/1.jpg`,
        },
        {
          class: "img_h310",
          platform: "Steam",
          gameid: "1174180",
          src: `${BASE_IMAGE_URL}/Steam/1174180/Ranner/1.jpg`,
        },
        {
          class: "img_h152",
          platform: "Steam",
          gameid: "1506830",
          src: `${BASE_IMAGE_URL}/Steam/1506830/Ranner/1.jpg`,
        },
      ],
      dialogVisible: false,
      payType: 0,
      xfmoney: 10,
      payment: 1,
      canSearch: true,
    };
  },
  computed: {
    // 支付参数对象
    payParam() {
      // 组装支付参数
      const payParam = {
        type: 4,
        gameid: 1,
        gamename: "增值服务",
        num: 1,
        bShare: 1,
        paydetail: this.payType === 0 ? "alipay" : "wxpay",
        xfmoney:
          this.payType === 0
            ? (this.xfmoney * this.$gameStartState.zfbCharges).toFixed(2)
            : (this.xfmoney * this.$gameStartState.wxCharges).toFixed(2),
        lastmoney: 0,
        info: 0,
        platform: "",
        goodsinfo: 0,
        versionName: "",
      };
      return getPayParam(payParam);
    },
  },
  mounted() {
    EventBus.$on("reSearch", () => {
      if (this.canSearch) {
        this.loadData(this.currentPage, this.search);
        this.canSearch = false;
        let that = this;
        setTimeout(() => {
          that.canSearch = true;
        }, 3000);
      } else {
        this.$message({
          type: "info",
          message: "请勿频繁操作！",
        });
      }
    });
    this.loadData(this.currentPage, this.search);
  },
  activated() {
    // this.loadData(this.currentPage, this.search);
  },
  watch: {
    search(value) {
      this.currentPage = 1;
      this.loadData(this.currentPage, value);
    },

    platformIndex() {
      this.toPage(1);
    },

    flagIndex() {
      this.toPage(1);
    },

    sortType() {
      this.toPage(1);
    },
  },
  methods: {
    getFiveDigits,

    loadData(page, search = "") {
      this.loading = true;
      const platform =
        this.platformIndex === 0
          ? null
          : this.platform[this.platformIndex - 1].platform;
      const flag =
        this.flagIndex === 0 ? null : this.flag[this.flagIndex - 1].gameflag;
      homeApi
        .reqGamelist(
          getUUID(),
          page,
          getSign(),
          this.pageSize,
          search,
          platform,
          flag,
          this.sortType,
          1,
          1
        )
        .then((res) => {
          this.list = res.list;
          this.noData = res.list.length <= 0;
          // if (this.noData) {
          // 调用回传请求
          homeApi.reqPostSearchLog({ gamename: this.search, uuid: getUUID() });
          // }
          // this.flag = res.flag;
          this.flag = [
            { gameflag: "动作" },
            { gameflag: "多人" },
            { gameflag: "冒险" },
            { gameflag: "模拟" },
            { gameflag: "开放世界" },
            { gameflag: "策略" },
            { gameflag: "角色扮演" },
            { gameflag: "生存" },
            { gameflag: "合作" },
            { gameflag: "射击" },
            { gameflag: "单人" },
            { gameflag: "独立" },
            { gameflag: "剧情丰富" },
            { gameflag: "第一人称射击" },
            { gameflag: "玩家对战" },
          ];
          // this.platform = res.platform;
          this.platform = [
            { platform: "Steam" },
            { platform: "Origin" },
            { platform: "UPlay" },
            { platform: "Epic" },
            { platform: "Battle" },
            { platform: "拳头" },
            { platform: "Alone" },
          ];
          this.num = res.num[0]["count(*)"];
          this.totalPage = getTotalPage(this.num, this.pageSize);
          this.loading = false;
        });
    },

    changeFlagIndex(index) {
      this.flagIndex = index;
    },

    changePlatformIndex(index) {
      this.platformIndex = index;
    },

    toPage(pageNum) {
      this.currentPage = pageNum;
      this.loadData(pageNum, this.search);

      window.scrollTo(0, 0);
    },
    clickJump(item, index) {
      if (index == 1) {
        this.$router.push("/membercenter");
      } else if (index == 4) {
        // 展示增值服务弹窗
        this.dialogVisible = true;
      } else {
        let url = `/gamedetail/${item.platform}/${item.gameid}`;
        this.$router.push(url);
      }
    },
    handleChange(currentValue, oldValue) {
      if (!currentValue) {
        this.xfmoney = oldValue;
        console.log(this.xfmoney);
      }
    },
    getQrCode() {
      if (!checkUser()) {
        this.dialogVisible = false;
        this.$alert("请先登录");
        return;
      }
      this.user = getUser() ? getUser() : { bVip: 0 };
      console.log(this.payParam, "steam");
      EventBus.$emit("showPayQrcode", this.payParam);
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
@import url("../assets/css/reset.css");
@import url("../assets/css/public.css");
@import url("../assets/css/index.css");
@import url("../assets/css/swiper-bundle.min.css");
@import url("../assets/css/goods.css");

/deep/ .el-dialog__body {
  height: 500px !important;
  overflow-y: scroll;
  color: #9b8e70;
  padding-top: 0 !important;
}

.member_dialog_container {
  justify-content: center;
  height: 470px;
  background: #292929;
  border-radius: 10px;
  margin: 0 auto;
  padding: 18px 0 0 14px;
  display: flex;
  margin-top: 10px;
  .pay-select-title {
    opacity: 0.7;
    margin-bottom: 14px;
    color: #9b8e70;
  }
  /deep/.el-radio__inner {
    border: 1px solid #c8443a !important;
    background-color: transparent;
    &::after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  .pay-amount {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 14px;
    color: #d3d3d2;
    margin-top: 4px;
    span {
      font-size: 24px;
      color: #ef4c25;
      span {
        font-size: 12px;
      }
    }
    /deep/.el-input__inner {
      background-color: #373839;
      border: 1px solid #cc3930;
      color: #fff;
    }
  }
  .pay-tips {
    margin-top: 10px;
  }
}
.payType-tips {
  position: absolute;
  right: -12px;
  top: -10px;
  transform: scale(0.9);
  font-size: 12px;
  line-height: 16px;
  color: #ff4b16;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff4b16;
  padding: 0 9px;
  background-color: #292929;
  border-radius: 0 4px 0 12px;
}
.payType-item.active {
  border-color: #c8443a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payType-item {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: transparent;
  outline: none;
  height: 40px;
  border: 1px solid #666666;
  position: relative;
  box-sizing: border-box;
  width: calc(100% / 2 - 5px);
  margin: 0;
  position: relative;
}
.goods_box_r_gameimg {
  width: 380px;
  div {
    box-sizing: border-box;
  }
  margin-top: 10px;
  img {
    width: 100%;
    height: 100%;
  }
  .big_img {
    height: 405px;
    cursor: pointer;
    margin-bottom: 6px;
    overflow: hidden;
    border-radius: 4px;
    &:hover {
      border: 2px solid #2ebfff;
    }
  }
  .small_img {
    display: flex;
    flex-flow: column wrap;
    height: 474px;
    position: relative;
    .img_h152 {
      width: 188px;
      height: 152px;
      cursor: pointer;
      margin-bottom: 6px;
      margin-right: 6px;
      overflow: hidden;
      border-radius: 4px;
      &:hover {
        border: 2px solid #2ebfff;
      }
    }
    .img_h310 {
      width: 188px;
      height: 310px;
      cursor: pointer;
      margin-bottom: 6px;
      overflow: hidden;
      border-radius: 4px;
      &:hover {
        border: 2px solid #2ebfff;
      }
    }
  }
}
.game-item {
  height: 152px !important;
  &:hover {
    .price_info {
      transform: translateY(-1px);
    }
  }
}
.game-item-right {
  position: relative;
  .price_info {
    border: 2px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    border-radius: 3px;
    width: 130px;
    position: absolute;
    right: -5px;
    top: 50%;
    transition: all 0.3s linear;
    .discount_box {
      display: flex;
      height: 30px;
      .discount_text {
        width: 65px;
        height: 100%;
        font-size: 20px;
        color: #a4d007;
        background: #4c6b22;
        text-align: center;
        line-height: 30px;
        border-top-left-radius: 3px;
      }
      .money {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
        background-color: rgba(46, 68, 80);
        color: #acdbf5;
        font-family: cursive;
        border-top-right-radius: 3px;

        span {
          width: 100%;
          height: 14px;
          line-height: 14px;
          letter-spacing: 1px;
          margin-left: 3px;
        }
        .sharemoney {
          text-decoration: line-through;
          letter-spacing: 0px;
          font-size: 12px;
          margin-left: 12px;
        }
      }
    }
    .save_text {
      height: 25px;
      background-color: #cc7a00;
      text-align: center;
      color: #000;
      line-height: 25px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      font-size: 14px;
    }
  }
}
.goods {
  margin-top: 60px;
}

.game-item {
  height: 188px;
}

.goods_type_title {
  font-size: 16px;
}

.home-game-left-game-item .game-item:hover::after {
  display: none;
}

.goods_screen_item a {
  color: hsla(0, 0%, 100%, 0.7);
}

.link_page {
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  margin: 0 -1px 0 0px;
  position: relative;
  z-index: 9;
  text-decoration: none;
  color: hsla(0, 0%, 100%, 0.7);
  padding: 8px 14px;
  /* border: 1px solid #ccc; */
}

.link_page_active {
  color: #20f7fd;
}

.main_three_tit_icon {
  width: 28px;
  height: 28px;
  margin-right: 7px;
  img {
    margin-top: -15px;
    vertical-align: middle;
  }
}
.main_three_tit_icon1 {
  width: 26px;
  height: 26px;
}
img {
  /* margin-inline: 10px; */
}

// .imglogo {
//   margin-top: -40px;
// }

.no_data,
.no-game {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
}

.no_data img,
.no-game img {
  transform: scale(0.6);
  width: auto;
  height: auto;
}

.no_data h4,
.no-game h4 {
  text-align: center;
  margin: 10px 0;
}

a:link {
  color: #ffffff;
}

a.active {
  color: #21eaf1;
}

.no-game p {
  color: #ef4b24;
}

.goods_box_r_item {
  max-height: 500px;
  overflow-y: auto;
}

.loading-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
</style>

import { EventBus } from "../common/event-bus.js"

/**
 * @desc 函数防抖
 * @param fn 函数
 * @param timerDelay 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
function debounce(fn, timerDelay, immediate) {
  let timer;
  return function () {
    let that = this;
    let args = arguments;

    if (timer) clearTimeout(timer);
    if (immediate) {
      var callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, timerDelay)
      if (callNow) fn.apply(that, args)
    }
    else {
      timer = setTimeout(function () {
        fn.apply(that, args)
      }, timerDelay);
    }
  }
}


// function debounce(func, wait = 3000, immediate = false) {
//   // 清除定时器
//   if (timeout !== null) clearTimeout(timeout);
//   // 立即执行，此类情况一般用不到
//   if (immediate) {
//     var callNow = !timeout;
//     timeout = setTimeout(function () {
//       timeout = null;
//     }, wait);
//     if (callNow) typeof func === "function" && func();
//   } else {
//     // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
//     timeout = setTimeout(function () {
//       typeof func === "function" && func();
//     }, wait);
//   }
// }

// 时间戳版(节流函数)
function throttle(fn, delay) {
  let flag = true;
  return () => {
    if (!flag) return;
    flag = false;
    setTimeout(() => {
      fn();
      flag = true;
    }, delay);
  };
}

// 获取url的参数
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false)
}

// 格式化时间
function formateDate(time) {
  let date = time ? new Date(time) : new Date()
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
}

//利用原生Js获取操作系统版本
function getOS() {
  var userAgent = navigator.userAgent.toLowerCase();
  var version = 'other';
  if (userAgent.indexOf('win') > -1) {
    version = 'Windows';
    if (userAgent.indexOf('windows nt 5.0') > -1) {
      version = 'Windows 2000';
    } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
      version = 'Windows XP';
    } else if (userAgent.indexOf('windows nt 6.0') > -1) {
      version = 'Windows Vista';
    } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
      version = 'Windows 7';
    } else if (userAgent.indexOf('windows 8') > -1) {
      version = 'Windows 8';
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
      version = 'Windows 10';
    } else {
      version = 'Windows';
    }
  } else if (userAgent.indexOf('iphone') > -1) {
    version = 'Iphone';
  } else if (userAgent.indexOf('mac') > -1) {
    version = 'Mac';
  } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
    version = 'Unix';
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      version = 'Android';
    } else {
      version = 'Linux';
    }
  } else {
    version = 'other';
  }
  return version;
}

// 保存用户登录状态
function setUser(user) {
  localStorage.setItem("USER", JSON.stringify(user));
}

// 获取用户登录状态
function getUser() {
  // 正式环境读取缓存
  const userJson = localStorage.getItem("USER");
  if (userJson) {
    return JSON.parse(userJson);
  }
  return null;
}

function removeUser() {
  localStorage.removeItem('USER')
}

function checkUser() {
  const user = getUser()
  if (user === null) {
    console.log('login')
    EventBus.$emit("showLogin", true)
    return false
  }
  return true
}

// 获得uuid
function getUUID() {
  const user = getUser();
  if (user) {
    return user.uuid;
  }
  return null;
}

// 获得request请求sign
function getSign() {
  const user = getUser();
  if (user) {
    return user.token;
  }
  return null;
}

// 分页,跳转方法
function toPage(type, page, target) {
  if (type === "pre") {
    if (target.currentPage <= 1) {
      return;
    }
    // this.loadData(this.currentPage--)
  } else if (type === "next") {
    if (target.currentPage >= target.totalPage) {
      return;
    }
    // this.loadData(this.currentPage++)
  } else if (page) {
    if (page >= 1 && page <= target.totalPage && page != target.currentPage) {
      target.currentPage = page;
      // this.loadData(page)
    }
  }
  // console.log(page, this.currentPage)
}

// 获取总页数
function getTotalPage(totalCount, pageSize) {
  return totalCount % pageSize === 0
    ? totalCount / pageSize
    : Math.floor(totalCount / pageSize);
}
function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}
// 获取支付参数
// Uuid：用户uuid
// sign：登录返回的tonken
// type: 商品类型游戏0，会员1，交换卡2，加速器3
// gameid: 游戏传游戏id（游戏礼包多个游戏用","隔开），会员传会员等级，交换卡传888，加速器传时常（单位小时）
// gamename: 游戏传游戏名称, 会员传会员等级名称, 交换卡传交换卡, 加速器传加速器名称
// num： 游戏数量传1，会员数量传1，交换卡数量用户选择，加速器数量用户选择
// bShare：游戏传0/1(0:独享游戏/1:共享游戏),其他传0
// paydetail: 支付方式(alipay,wxpay),固定
// xfmoney: 支付金额
// lastmoney: 预留金额以后使用, 暂时传0
// info: 预留字段以后使用, 暂时传''
function getPayParam(payParam) {
  if (!payParam) {
    // 测试数据
    payParam = {
      type: 1,
      gameid: 10056,
      gamename: "绝地求生",
      num: 1,
      bShare: 1,
      paydetail: "alipay",
      xfmoney: "1.00",
      lastmoney: 0,
      info: "",
    }
  }
  return { ...payParam, Uuid: getUUID(), sign: getSign() }
}

// 获取五位随机数
function getFiveDigits() {
  return '123456'.split('').reduce((pre) => {
    if (pre === '') {
      const random = Math.floor(Math.random() * 10)
      pre += random === 0 ? random + 1 : random
    } else {
      pre += Math.floor(Math.random() * 10)
    }
    return pre
  }, '')
}

export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

export function setLocalOcpc(url) {
  localStorage.setItem("_conversionTypes", JSON.stringify([{ "logidUrl": url, "newType": 10 }]));
}
export function setLocalOcpcForm(url) {
  localStorage.setItem("_conversionFormTypes", JSON.stringify([{ "logidUrl": url, "newType": 3 }]));
}
// export function setLocalOcpc(url) {
//   let obj = {
//     "logidUrl": url,
//     "newType": 10
//   }
//   let arr = localStorage.getItem("_conversionTypes") ? JSON.parse(localStorage.getItem("_conversionTypes")) : []
//   if (arr.length >= 100) {
//     arr.shift()
//   }
//   arr = [...arr, obj]
//   localStorage.setItem("_conversionTypes", JSON.stringify(unique(arr)));
// }

export function unique(array) {
  var obj = {};
  return array.filter(function (item) {
    // console.log(typeof item + JSON.stringify(itemarray))
    if (Object.prototype.hasOwnProperty.call(obj, typeof item + JSON.stringify(item))) {
      return false;
    }
    return (obj[typeof item + JSON.stringify(item)] = true);
  });
}


export {
  debounce,
  throttle,
  getQueryVariable,
  formateDate,
  getOS,
  setUser,
  getUser,
  removeUser,
  checkUser,
  getUUID,
  getSign,
  toPage,
  getTotalPage,
  getPayParam,
  getFiveDigits,
  isMobile
};

<template>
  <div class="content">
    <div class="sc-TmcTc cJbABh">
      <div class="sc-kEjbxe jbJDWL" v-show="false">
        <div class="account-title">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAABDlBMVEVHcEwDi/+X4/9p3P84//9EuP8W3v8g//8Agv45yv9Ayv8f9f4Bhv8Cif+W5v9A2P84wf9O1/9Ly/8Tx/0MrP4f+P4f+f4g+/4Agf8AhP8+1f812f48zf9n1P83sPsb5/4In/8Puf4DjP8a6fsFkf4c5f8a3v0Y2f4Di/4Jof8Lpv5y0f87vf45vf8b6P8b5P0Hnf8X2PwW1P4c6f0a4/0Z3f0X2P0Jof4d7v0Div4HlP4Kpv4f9P0Chf4EkP4W0v0Hm/4g+f0Uzf0Nsf4MrP7v9vsTyP0Ot/4Swv0QvP42v/860f811v081f80u/43w/8Vq/wqyPxGzfxt0Pqb0/rf6/TP7fc0qPgmvf39/f2fJf41AAAAM3RSTlMAdEp1BA0YH5v+1LxR6D/T8Kenz8/HZf2NSu/v73XutL7PXUb1ktDr/OHyU73dOJmuys6hfkEUAAAA/0lEQVQoz73SWVPCMBSG4VZDsbYFVFbRYVUB912r1iqggGgLFdT//0dsToKeaHOpz00v3plMk/kU5a/FCmp+AcmrZuy7FqybXyxzVs3GZZQqP9lyHMcGDlbXIKu2/TbpglcbUyEfuO5XdrEy5PK5RBNy+uoH3/fh22K5I/BfmNTuzja9fLotGHCpxeX1RJgrj9i75wWBF6J5juY+NtLH3Y9A1/U1nld6WJin0zHkpXnID9joiVtN8nwvGD4zNNNfO7oVDZliMZkgYT48k9iCZymdSmxC3j+R2IC8dyxRY3soXUQyCJ+LcR0ho83GRrJG7k6Qy2QJnjLR4ohGlP/wCVepfifDSiZmAAAAAElFTkSuQmCC"
            class="img"
            alt=""
          />我的资产
        </div>
        <div class="account-content">
          <div class="purse">
            <span class="my-purse">我的钱包</span
            ><span class="purse-detail">查看钱包明细&gt;&gt;</span>
          </div>
          <div class="money">
            <div class="my-money">
              <div class="title">钱包余额：</div>
              <div class="num">¥ {{ balance }}</div>
            </div>
            <div class="withdraw">
              <div class="title">可提现余额：</div>
              <div class="num">¥ {{ withdrawal }}</div>
              <div
                class="sc-dlfnbm cvOASq my-button ellipsis ml btn_type1 btn_txt"
              >
                提现
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-list-container">
        <div class="order-list-title">
          <img
            alt=""
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAABvFBMVEVHcEzM+v579v+s/P/2/v/k/P/g+Pv8/v6q8P/2//+3/P/G/v9b7/5D5v8Jo/8KrP4Aev8p1/081f+Z8/5c6v9w9P9P7P9n9v5G6P9E8f8Tyv8Fkv////////+I9/8/4v9L6v+A8/9G5/+P+/9V7/9G6/9J7P+68/ZI8/9G8f9E7P+L7/oAmf8Flf4Ejv8Lqv8Tzv8Tx/9m4f8GlP+Z9PsBhf46z/4+1P9n4v1F2f5B4v8xzP0wzP0/2v+f+f9K2f1Z9v5Q8v/k+frq8/VO9/8AuP8Ho/8Inv4Pvv8Ch/4PuP8Lq/8ElP8Sxf4X0/4W2/se4/4Imf4Qu/+D6vgTx//o9/cAhf8Dif8TyP5J2v8Fk/9R4f9h6P9F3/+j+P1q4/3i8vK+8PHp+vqG7vaw9/vT8fSE8/oJof4Mrv4JpP4Inv4LqP4MrP4Hmf4Glv4Ns/4Otv4Fkv492v43w/4Rwv0Ejv45zP481v8+3/8Lq/4Puf46z/470v8OsP4Tx/0CiP4Qvf44x/584viH6PkHnP4Kpf4Rv/0UzP45yf74+PhA5f4Qu/4W1f0Vz/0ozPwxvv0Y3P4lwv0iuf0ctPw4Ci0DAAAAiHRSTlMANGVCJTD+KwEuPTiX72MhB/74To5ztoHq9kePDhlZ+cVe5lOl3NHn4en3/BGnYPQ2VQjMOcb25XbI3/v+7Er+rsDb/sgSS/t27s63tdTpOevmwyVtIXnhscA0qYbURvXHydXuufTX///////////////////////////////////////////+KnFySAAAAeJJREFUKM+t0+dTGlEQAPDjFB5HDaFNQMBOtWGLzsReY5oplhRSNEGBiJ4ooGJBUelg/mH33btDzOiMH9yvv9l9u3u3FPVoIVWrzKrJe1Ct6p72p4IdXXeYp/3Vxt+Afz0YSoRN/9mT9radLV7Xwpvvb6N59gjrGa/ZY3W19h5VNA66dxz5WMULvAYqGlmq4l7QLVB/Kp64Km6f7Pb1DzzXij7VEV7cgWSixW3Q/b7+ZV2DTIwsHHeTplPBENbL3YOnLwYUtE+MEJdvFvQfp/vA375AdYRqMX8W1nFFNDr8fdDw1SpjEOIWPR8gI5WJsqeSxnoFXQPVpdjb+IHLWIcxK1ub9UMinqeIZsugyXE2BtxiMDbJGcKejvhaAtZRhsrJdAzY5RiF0Xim5kKg2fPCAccZYOefVfw4YVUiDBopRKNsMp3JuCXPnPaxG1ZzeliIsgI77GM3xamXWC80LLybdrncEseE/VelNYqaAT3Ma2IxW3Lc5Vz5PTHy84dPGIyiOkEv8hqbTalsbGkdHVEYaS0kM8JHfQ2a02CrNzQrjHqrTy7md47DBJorDRKjm4ZkIlCmrvJPmN7kSqV3Op2etmpr5BgRY6k6AO/brk6v9ENPjwjVcmHx3ncrXMPSh5zVNcJVreqOSSPSAAAAAElFTkSuQmCC"
            class="img"
          />订单详情
        </div>
        <!-- <div class="table">
          <ul class="row row-title">
            <li class="table-colmun column-title">时间</li>
            <li class="table-colmun column-title">订单号</li>
            <li class="table-colmun column-title">商品名称</li>
            <li
              class="table-colmun column-title column-title-num"
              style="flex: 0 0 6%"
            >
              数量
            </li>
            <li
              class="table-colmun column-title column-title-money"
              style="flex: 0 0 10%"
            >
              金额
            </li>
            <li class="table-colmun column-title" style="flex: 0 0 10%">
              状态
            </li>
            <li class="table-colmun column-title">操作</li>
          </ul>
          <ul class="row" v-for="item in orderList" :key="item.id">
            <li class="table-colmun column-title">{{ item.xfdate }}</li>
            <li class="table-colmun column-title">{{ item.selfbill }}</li>
            <li class="table-colmun column-title">
              {{
                item.selfbill.indexOf("gm") != -1 &&
                item.gname &&
                item.paydetail == "self"
                  ? "赠-" + item.gname
                  : item.gamename
              }}
            </li>
            <li
              class="table-colmun column-title column-title-num"
              style="flex: 0 0 6%"
            >
              {{ item.number }}
            </li>
            <li
              class="table-colmun column-title column-title-money"
              style="flex: 0 0 10%"
            >
              {{ item.xfmoney }}
            </li>
            <li class="table-colmun column-title" style="flex: 0 0 10%">
              {{ item.bStatus ? "已付款" : "未付款" }}
            </li>
            <li
              class="table-colmun column-title"
              @click="showOrderContent(item)"
            >
              <a
                data-v-fae5bece=""
                href="javascript:void(0)"
                class="btn_type1 btn_txt"
                style="display: block; margin: 0 auto"
                >查看订单</a
              >
            </li>
          </ul>
          <div class="no-data" v-if="tradeinfo.length === 0">暂无数据...</div>
        </div> -->
        <div class="table">
          <ul class="row row-title">
            <li class="table-colmun column-title" style="flex: 0 0 4%">序号</li>
            <li class="table-colmun column-title" style="flex: 0 0 25%">
              商品名称
            </li>
            <li class="table-colmun column-title">时间</li>
            <li
              class="table-colmun column-title column-title-money"
              style="flex: 0 0 10%"
            >
              金额
            </li>
            <li class="table-colmun column-title" style="flex: 0 0 10%">
              状态
            </li>
            <!-- <li class="table-colmun column-title">订单号</li> -->
            <!-- <li
              class="table-colmun column-title column-title-num"
              style="flex: 0 0 6%"
            >
              数量
            </li> -->

            <li class="table-colmun column-title">操作</li>
            <li class="table-colmun column-title">退款</li>
          </ul>
          <ul class="row" v-for="(item, index) in orderList" :key="item.id">
            <li class="table-colmun column-title" style="flex: 0 0 4%">
              {{ index + 1 }}
            </li>
            <!-- <li class="table-colmun column-title">
              {{
                item.selfbill.indexOf("gm") != -1 &&
                item.gname &&
                item.paydetail == "self"
                  ? "正版-" + item.gname
                  : item.gamename
              }}
            </li> -->
            <!-- <el-tooltip
             
              class="item"
              effect="dark"
              :content="
                item.selfbill.indexOf('gm') != -1 &&
                item.gname &&
                item.paydetail == 'self'
                  ? '正版-' + item.gname + '订单号 ' + item.selfbill
                  : item.gamename + '订单号 ' + item.selfbill
              "
              placement="top"
            > -->
            <li
              class="table-colmun column-title"
              style="justify-content: center; flex: 0 0 25%"
            >
              <p>
                {{
                  item.gname &&
                  item.paydetail == "self"
                    ? "正版-" + item.gname.replace("赠送","正版")
                    : item.gamename.replace("赠送","正版")
                }}
              </p>
              <p>{{ "订单号 " + item.selfbill }}</p>
            </li>
            <!-- </el-tooltip> -->

            <li class="table-colmun column-title">{{ item.xfdate }}</li>
            <!-- <li
              class="table-colmun column-title column-title-num"
              style="flex: 0 0 6%"
            >
              {{ item.number }}
            </li> -->

            <li
              class="table-colmun column-title column-title-money"
              style="flex: 0 0 10%"
            >
              {{ item.xfmoney }}
            </li>
            <li class="table-colmun column-title" style="flex: 0 0 10%">
              {{ item.bStatus ? "已付款" : "未付款" }}
            </li>
            <li
              class="table-colmun column-title"
              @click="showOrderContent(item)"
            >
              <a
                data-v-fae5bece=""
                href="javascript:void(0)"
                class="btn_type1 btn_txt"
                style="display: block; margin: 0 auto"
                >查看订单</a
              >
            </li>
            <li
              class="table-colmun column-title"
              @click="showFefundDialog(item)"
            >
              <a
                data-v-fae5bece=""
                href="javascript:void(0)"
                class="btn_type1 btn_txt"
                style="display: block; margin: 0 auto"
                >申请退款</a
              >
            </li>
          </ul>
          <div class="no-data" v-if="tradeinfo.length === 0">
            <!-- <img :src="imgs.no_data" alt="" /> -->
            暂无数据...
          </div>
        </div>
        <div class="page-wrapper">
          <el-pagination
            background
            layout="prev, pager, next"
            style="text-align: center"
            :page-count="totalPage"
            @current-change="toPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="订单详情"
      class="order_dialog"
      :visible.sync="orderDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-click-escape="false"
    >
      <div class="order_dialog_container">
        <el-form
          ref="form"
          label-width="120px"
          v-if="orderDialogData.type === 1"
        >
          <el-form-item label="订单编号">
            {{ orderDialogData.selfbill }}
          </el-form-item>
          <el-form-item label="商品名称">
            {{
              orderDialogData.paydetail == "self"
                ? "赠送游戏-" + orderDialogData.gname
                : orderDialogData.gamename
            }}
          </el-form-item>
          <el-form-item label="支付状态">
            {{ orderDialogData.bStatus ? "已付款" : "未付款" }}
          </el-form-item>
        </el-form>
        <el-form ref="form" label-width="120px" v-else>
          <el-form-item label="订单编号">
            {{ orderDialogData.selfbill }}
          </el-form-item>
          <el-form-item label="商品名称">
            {{
              orderDialogData.paydetail == "self" &&
              orderDialogData.selfbill.indexOf("gm") !== -1
                ? "赠送游戏-" + orderDialogData.gname
                : orderDialogData.gamename
            }}
          </el-form-item>
          <el-form-item label="支付状态">
            {{ orderDialogData.bStatus ? "已付款" : "未付款" }}
          </el-form-item>
          <el-form-item label="加速器名称" v-if="orderDialogData.speedname">
            {{ orderDialogData.speedname || "-" }}
          </el-form-item>
          <el-form-item label="加速器CDK" v-if="orderDialogData.speedname">
            <div
              v-for="(cdk, i) in orderDialogData.cdkcode
                ? orderDialogData.cdkcode.split(',')
                : '-'"
              :key="i"
            >
              <span style="margin-right: 10px">{{ cdk }}</span>
              <el-button
                class="tag-read"
                type="warning"
                size="mini"
                :data-clipboard-text="cdk"
                @click="copy"
                >复制CDK激活码</el-button
              >
            </div>
          </el-form-item>
        </el-form>
        <div>
          <button class="show_customer" @click="upload">下载客户端开始畅玩</button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="退款提示"
      class="refund_dialog"
      :visible.sync="refundDialogIsShow"
      width="540px"
      :close-on-click-modal="false"
      :close-on-click-escape="false"
      :before-close="
        () => {
          refundDialogIsShow = false;
        }
      "
    >
      <p>订单正在退款中</p>
      <div class="footer">
        <button class="show_customer" @click="showCostumer">联系客服</button>
        <button class="confirm" @click="refundDialogIsShow = false">
          确定
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { userApi, homeApi } from "../api";
import { getUUID, getSign, getTotalPage } from "../common/utils";
import { EventBus } from "../common/event-bus.js";
export default {
  name: "MyAssets",
  data() {
    return {
      money: {},
      tradeinfo: [],
      pageSize: 10,
      currentPage: 1,
      totalPage: 0,
      orderDialogVisible: false,
      orderDialogData: {},
      refundDialogIsShow: false,
      uploadsUrl: "",
    };
  },
  activated() {
    this.loadData();
  },
  computed: {
    balance() {
      return (this.money.fmoney || 0).toFixed(2);
    },
    withdrawal() {
      return (this.money.tmoney || 0).toFixed(2);
    },
    orderList() {
      const compare = (key) => {
        return (a, b) => (b[key] < a[key] ? -1 : 1);
      };
      return [...this.tradeinfo]
        .sort(compare("xfdate"))
        .filter(
          (item, i) =>
            i >= this.pageSize * (this.currentPage - 1) &&
            i < this.pageSize * this.currentPage
        );
    },
  },
  methods: {
    loadData() {
      userApi.reqGetPayInfo(getUUID(), 0, getSign()).then((res) => {
        // console.log(res)
        this.money = res.usermoney[0];
        this.tradeinfo = res.tradeinfo;
        this.totalPage = getTotalPage(this.tradeinfo.length, this.pageSize);
      });
      // 获取下载客户端的url
      homeApi.reqUploads("1").then((res) => {
        this.uploadsUrl = res[0].childurl;
      });
    },
    showFefundDialog() {
      this.refundDialogIsShow = true;
    },
    showCostumer() {
      EventBus.$emit("showServiceIframe", true);
      this.refundDialogIsShow = false;
    },
    toPage(pageNum) {
      this.currentPage = pageNum;
    },
    showOrderContent(data) {
      this.orderDialogData = data;
      console.log(data);
      if (
        (data.selfbill.indexOf("js") !== -1 ||
          data.selfbill.indexOf("vp") !== -1 ||
          data.selfbill.indexOf("gm") !== -1) &&
        data.bStatus
      ) {
        //&& data.bStatus
        userApi
          .reqGetPaydetail(
            getUUID(),
            0,
            getSign(),
            data.selfbill,
            data.cdkcode ? `'${data.cdkcode.split(",")[0]}'` : "null"
          )
          .then((res) => {
            this.orderDialogData = {
              ...data,
              type: 2,
              cdkcode: res.payinfo[0].cdkcode,
              speedname:
                res.speedname && res.speedname.length > 0
                  ? res.speedname[0].speedname
                  : "",
            };
            this.orderDialogVisible = true;
          });
      } else {
        this.orderDialogData.type = 1;
        this.orderDialogVisible = true;
      }
    },
    copy() {
      const clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        this.$message.success("复制成功");
        console.log(e);
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message.error("复制失败");
        console.log(e);
        clipboard.destroy();
      });
    },
    upload() {
      window.location.href = this.uploadsUrl;
    },
  },
};
</script>

<style scoped lang="less">
.order_dialog {
  div {
    width: 100%;
    text-align: center;
    button {
      margin-top: 5px;
      padding: 5px 10px;
      height: 32px;
      background-color: #d03b32;
      color: #fff;
      border-radius: 3px;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }
  }
}

.refund_dialog {
  /deep/.el-dialog__body {
    padding: 10px;
    height: 110px !important;
    text-align: center;
    color: #999da0 !important;
    .footer {
      margin-top: 20px;
      .show_customer {
        width: 90px;
        height: 32px;
        background-color: #22262a;
        border: 1px solid #ef4c25;
        color: #ef4c25;
        border-radius: 3px;
        cursor: pointer;
      }
      .confirm {
        width: 90px;
        height: 32px;
        background-color: #ef4c25;
        color: #fff;
        border: none;
        margin-left: 20px;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
}
.cJbABh {
  border-radius: 10px;
  min-height: 571px;
  padding: 40px;
  padding-bottom: 0;
  position: relative;
  margin-top: 20px;
}

.jbJDWL .account-title {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 18px;
}

.jbJDWL .account-title .img {
  margin-right: 6px;
}

.jbJDWL .account-content {
  background: #373839;
  width: 323px;
  height: 186px;
  opacity: 1;
  border-radius: 10px;
  padding: 20px;
  margin-top: 14px;
}

.jbJDWL .account-content .purse {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.jbJDWL .account-content .purse .my-purse {
  font-size: 16px;
}

.jbJDWL .account-content .purse .purse-detail {
  font-size: 12px;
  opacity: 0.8;
  cursor: pointer;
}

.jbJDWL .account-content .money {
  display: flex;
}

.jbJDWL .account-content .money .my-money,
.jbJDWL .account-content .money .withdraw {
  flex: 1 1 0%;
}

.jbJDWL .account-content .money .title {
  margin-bottom: 10px;
}

.jbJDWL .account-content .money .num {
  font-size: 22px;
  font-weight: 600;
  color: rgb(254, 146, 72);
}

.jbJDWL .account-content .money .my-button {
  margin-top: 10px;
  width: 60px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 12px;
  font-weight: 500;
  text-align: center;
}

.cvOASq.primary {
  background: linear-gradient(rgb(0, 171, 255), rgb(0, 116, 255));
}

.cJbABh .order-list-container .order-list-title {
  display: flex;
  width: 1020px;
  -webkit-box-align: center;
  align-items: center;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 600;
}

.cJbABh .order-list-container .order-list-title .img {
  margin-right: 6px;
}

.cJbABh .order-list-container .table {
  position: relative;
  background: #373839;
  width: 1020px;
  min-height: 411px;
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
}

.cJbABh .order-list-container .table .row {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 53px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}

.cJbABh .order-list-container .table .row .table-colmun {
  position: relative;
  padding: 0px 5px;
  flex: 1 1 0%;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.cJbABh .order-list-container .table .row .table-colmun p {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 20px;
  text-align: left;
}

.cJbABh .order-list-container .table .no-data {
  text-align: center;
  margin-top: 100px;
}

.cJbABh .order-list-container .page-wrapper {
  text-align: right;
  margin-top: 20px;
}

.cJbABh .table .row .table-colmun:last-child {
  border: none;
}

.option {
  color: blue;
  cursor: pointer;
}

img {
  width: auto;
  height: auto;
}

.btn_type1 {
  background: linear-gradient(
    270deg,
    rgb(204, 57, 48) 0%,
    rgb(249, 82, 68) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.33) 0px 6px 6px 0px;
  border-radius: 6px;
  padding: 0 8px;
  color: #fff;
  cursor: pointer;
}

.btn_type1.btn_txt {
  width: 82px;
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.btn_type1.btn_txt:hover {
  opacity: 0.8;
}
</style>

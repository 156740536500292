/* 支付请求模块 */
import ajax from '../ajax.js';
import { BASE_REQUEST_URL_DEV, BASE_REQUEST_URL_PROD } from '../../common/config.json';
const BASE_REQUEST_URL = process.env.NODE_ENV === 'production' ? BASE_REQUEST_URL_PROD : BASE_REQUEST_URL_DEV;
// import {  BASE_REQUEST_URL_PROD,BASE_REQUEST_URL_TEST } from '../../common/config.json'
// const BASE_REQUEST_URL = process.env.NODE_ENV === 'production' ? BASE_REQUEST_URL_PROD : BASE_REQUEST_URL_TEST

// 获取支付方式
const reqGetPayType = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/getpaytype`, { Uuid, page, sign }, 'POST');

// 生成支付二维码(接口1)
const reqPayoptbk = (payParam) => ajax(`${BASE_REQUEST_URL}/payoptbk`, { ...payParam, paymethod: '展示站' }, 'POST');

// 生成支付二维码(接口2,基础)
const reqJkpayop = (payParam) => ajax(`${BASE_REQUEST_URL}/${localStorage.getItem('zfbpayAPI')}`, { ...payParam, paymethod: '展示站' }, 'POST');
// const reqJkpayop = (payParam) => ajax(`${BASE_REQUEST_URL}/jkpayop`, { ...payParam , paymethod:'主站' }, 'POST');

// 生成支付二维码(接口2, 支付宝)
const reqJkopdetailaplipay = (tradeNo) => ajax(`${BASE_REQUEST_URL}/jkopdetailaplipay`, { tradeNo }, 'POST');

// 获取微信支付的倍率
const reqWxMultiple = () => ajax(`${BASE_REQUEST_URL}/getWxfl`);

// 聚合平台微信支付
const reqJHpayop = (payParam) => ajax(`${BASE_REQUEST_URL}/cnspay`, { ...payParam, paymethod: '展示站' }, 'POST');

// 虎皮椒平台微信支付
const reqHPpayop = (payParam) => ajax(`${BASE_REQUEST_URL}/hbjPay`, { ...payParam, paymethod: '展示站' }, 'POST');

// 天天支付接口
const reqTTpayop = (payParam) => ajax(`${BASE_REQUEST_URL}/wx_ttpay`, { ...payParam, paymethod: '展示站' }, 'POST');

// 浩瀚支付接口
// const reqHHpayop=(payParam)=>ajax(`${BASE_REQUEST_URL}/hhpay`, { ...payParam , paymethod:'展示站' }, 'POST');
// 网银支付接口
const reqWypayop = (payParam) => ajax(`${BASE_REQUEST_URL}/${localStorage.getItem('wxpayAPI')}`, { ...payParam, paymethod: '展示站' }, 'POST');
// const reqWypayop=(payParam)=>ajax(`${BASE_REQUEST_URL}/wy_pay`, { ...payParam , paymethod:'主站' }, 'POST');
// 生成支付二维码(接口2, 微信)
const reqJkopdetailwxpay = (tradeNo) => ajax(`${BASE_REQUEST_URL}/jkopdetailwxpay`, { tradeNo }, 'POST');

// 获取订单号
const reqJkweblunxun = (Uuid, page, sign, paytime) => ajax(`${BASE_REQUEST_URL}/jkweblunxun`, { Uuid, page, sign, paytime }, 'POST');

// 获取订单支付装填(接口2)
const reqJklunxun = (Uuid, page, sign, orderNo) => ajax(`${BASE_REQUEST_URL}/jklunxun`, { Uuid, page, sign, orderNo }, 'POST');
//获取支付宝微信是否可以支付
const getPayStatus = () => ajax(`${BASE_REQUEST_URL}/getPayStatus`);
//获取商品会员列表
const getAllVip = () => ajax(`${BASE_REQUEST_URL}/allVip`);
export default {
  getPayStatus,
  reqGetPayType,
  reqPayoptbk,
  reqJkpayop,
  reqJkopdetailaplipay,
  reqJkopdetailwxpay,
  reqJkweblunxun,
  reqJklunxun,
  reqJHpayop,
  reqHPpayop,
  reqTTpayop,
  reqWxMultiple,
  // reqHHpayop,
  reqWypayop,
  getAllVip
};
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyIwUBckgYl0IHKDlQZxj6wVYoq/ASLydvZIdronrPVjQfO2WIuHjR2i2OdEQDOAEPbNDNhY7fj0HTR2CYuI7ZKf4//9ekbxMGUgRoGiYNBk9CUdxQUGlr4U58If8txy/Czk24GJ37k6nngSKbcOLZaZAHejlqy32R5UafH5XSLPDf23nt/M+XfXCMREbHKRcqtG0d4NwXnx1U/appkmfG+TwW7pb4WTkyM/lVjZwn6vZdCdLJ/0ZwHHaDV18UFIfm+U1SjfCSvzrJ6M01NaBVwVbHDSfGuZHQhjaPdnXCuDLdgULQgKUWFe9T0QbQkHLHebwzARaezxQYQaKESRIMQIDAQAB'
const privateKey = "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDIjBQFySBiXQgcoOVBnGPrBViir8BIvJ29kh2uies9WNB87ZYi4eNHaLY50RAM4AQ9s0M2Fjt+PQdNHYJi4jtkp/j//16RvEwZSBGgaJg0GT0JR3FBQaWvhTnwh/y3HL8LOTbgYnfuTqeeBIptw4tlpkAd6OWrLfZHlRp8fldIs8N/bee38z5d9cIxERscpFyq0bR3g3BefHVT9qmmSZ8b5PBbulvhZOTIz+VWNnCfq9l0J0sn/RnAcdoNXXxQUh+b5TVKN8JK/OsnozTU1oFXBVscNJ8a5kdCGNo92dcK4Mt2BQtCApRYV71PRBtCQcsd5vDMBFp7PFBhBooRJEgxAgMBAAECggEAUxJLPPZKFtSohotTS7FHZlPpmkIo6ThQMvVynBj/O4MtoYIc3cgqCCtkyGBEQMEOoKw/3AirjigjfRsLJoDFz3UQ3KHgf4e7EgHbY1HQMkx+Dvjr/fb+dc0+6da6pPMgY7rIc3v2Z91m6zrASKVmea27A2i0sgqw6Ifn18fRyggHxq4WhY2dSw7WWC4ziU2NZQ8fCoLdZAz7DS2hX4NvR1VEL+HQSquXMUk8kKAHhFd+569wtSTA+zRnuK18FD4kdVRK5x2z7cEqVS6lAZ7iY7PuUfWmQSFLsx7isr9Nb3anVP5Susmjks5rb/5oFVQ+gv1x1oZFcPN/qnkRTlNdfQKBgQD9Fc/8msdmLVoKdHYQ3p6CuA39MUjXYgp5fGNGSzVzZKAwS9WV24pxDIBC9F/SSbx7L3m5CPhcqcaokTIgWt14Fmo+M9aIx7rm3XrP5t3oKl6KkhcGIDxf528K63gEN+s26KVzYD1VhgfF6V9fOGTFcziFtxHDX500R4i3AsFHswKBgQDK211PhSC6UXsCnKRIwmSUeH91qbLq7OafywIj0Gt6GfWR5AZOMu41o6gZj8RXb7eHsXOP8lzJYsaWq25o6LHSMqElSCV3JzpvIUAD1Zhfg1SXzw9Fa7dox2WQza5ilrf+dL7BZHDB4wg1zYNqLZAS3eDCC/9UqTmd5XL2h1c+iwKBgGZ7cK9PVbMwZieZpT2LiBBSy4ddb6uXzboH/oM2bTx+4Mbtzl1Seyrv7vgt0KdsqGARh27ADSIotYMI2I11ffzkxrzNbPASQMK/qL2LNQyQK5xdL+mRICGNq892LjE3G44uguDHdc6t6XPg3nfGdDfoQlkTGqRMkXHcDaZd/To1AoGAdRxda0oVEdt2Y5Sg92xaeqhyFTCqHRw1LG0byCdL1QrljZsR1NVsVkUsB83K8RuK3eihKaomzRCcS2Ge9SvZdNtL0uUq0/YusveEKHKIbCO6k4WzcEV2diQfS5Ciq0IN6PXnJmjg+94g4EbMJmE1et7jIcOhXXPw0moji5Wo1W8CgYBvxlPOVtDp6S80v5aJpqn+4qj77kctFdaNKTMJMq2nM6XDD5rK1sYR5qMXTD2/8OGQMe4EKKJiaFHR1J5V2REXYF5aGRa3URHXhfbFBrdOuv71mJfkOeDKUvE+qZy/Lcdefm5zuzYRpBhv34+KLKVnRvtgdGqKtd9GZhOvj8NNqQ=="

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

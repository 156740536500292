<template>
  <div class="certification">
    <div class="certification_top">
      <p>亲爱的玩家：</p>
      <p>
        我们致力于为用户提供最专业、最有保障、最健康的游戏服务。根据中华人民共和国文化部《互联网文化管理暂行规定》和《网络游戏管理暂行办法》对于网络游戏市场的相关规定及要求，游戏用户需要登记并认证如下个人信息，未实名认证的用户将不能启动游戏，同时对未成年人不提供任何游戏服务。
      </p>
    </div>
    <p class="tips">
      注册信息只能提交一次，不可修改，请慎重填写。该信息仅用于实名认证不会泄露于任何第三方
    </p>
    <div class="certification_form">
      <el-input
        prefix-icon="el-icon-user"
        v-model="cardName"
        placeholder="请输入您的真实姓名"
      ></el-input>
      <el-input
        prefix-icon="el-icon-postcard"
        v-model="cardNo"
        placeholder="请输入您的身份证号"
      ></el-input>
      <div class="cvOASq primary button" @click="realNameAuth">开始认证</div>
    </div>
  </div>
</template>
<script>
import { userApi } from "../api";
import { getUUID, getSign, getUser, setUser } from "../common/utils";
export default {
  name: "Authentication",
  props: {
    hideAuthentication: Function,
  },
  data() {
    return {
      cardName: "",
      cardNo: "",
    };
  },
  mounted() {},
  methods: {
    realNameAuth() {
      if (this.cardNo === "" || this.cardName === "") {
        return;
      }
      userApi
        .reqCertification(getUUID(), 0, getSign(), this.cardName, this.cardNo)
        .then((res) => {
          // console.log(res)
          const user = { ...getUser(), bCard: 1 };
          setUser(user);
          this.hideAuthentication();
          this.$message({
            type: "success",
            message: res.msg,
          });
        });
    },
  },
};
</script>
<style scoped>
.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #fff;
}
.wrapper > input {
  margin: 10px;
}
</style>
<template>
  <div class="contnet" ref="detail">
    <div class="goods_detail" v-if="smallDetails.gameid">
      <div class="goods_detail_top">
        <div class="goods_video">
          <video
            :src="playVideo"
            :autoplay="videoAutoPlay"
            loop="loop"
            muted="muted"
            class="video"
            id="video"
          ></video>
        </div>
        <img
          src="../assets/images/shadow.60c4c8c.png"
          alt=""
          class="goods_video_misp_img"
        />
        <div class="goods_detail_banner_text">
          <div class="w goods_detail_banner_text_box">
            <div class="game-info">
              <div class="game-name">
                <div class="chinese ti" id="goods_name">
                  {{ smallDetails.gamename_zh }}
                </div>
                <div class="english ti" id="color">
                  {{ smallDetails.gamename_en }}
                </div>
              </div>
              <div class="lq">
                <div class="count">
                  <div class="title">当日在线数量</div>
                  <div class="num">
                    <span v-for="(item, i) in buynum.split('')" :key="i">{{
                      item
                    }}</span>
                  </div>
                  <div class="tip">
                    库存预计<span class="hour">20</span>小时内售完
                  </div>
                </div>
                <div class="fl-list">
                  <div class="fl-item">
                    <div class="title">送加速器</div>
                    <div class="sub-title">免费加速</div>
                    <div class="sub-title">极速发货</div>
                  </div>
                  <div class="fl-item">
                    <div class="title">正版保证</div>
                    <div class="sub-title">官方正版</div>
                    <div class="sub-title">拒绝盗版</div>
                  </div>
                  <div class="fl-item">
                    <div class="title">售后无忧</div>
                    <div class="sub-title">24h在线</div>
                    <div class="sub-title">被盗包赔</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-list2">
              <div class="btn12">
                <div class="price-info2">
                  <div class="text2">
                    <img
                      src="https://imgcloud.huifangkuai.com/Display/text.png"
                      alt=""
                    />
                  </div>
                  <div class="price2" id="price2">
                    {{ "￥" + discountPrice }}
                  </div>
                </div>
                <div class="discount-info2">
                  <div class="yh2">
                    优惠
                    <span class="discount2" id="brand"
                      >{{ discountRatio }}%</span
                    >
                  </div>
                  <div class="source2" id="yuanjia">
                    原价：{{ smallDetails.money }}元
                  </div>
                </div>
              </div>
              <div class="coundDown" v-if="showTimeCount">
                <span>距离降价结束：</span>
                <span style="font-size: 16px">{{ timeCountText }}</span>
              </div>
              <div class="btn32 vip" @click="$router.push('/membercenter')">
                <!-- {{smallDetails.bVipmake == 1 ?'会员免费':'会员爆款'}} -->
                会员免费玩
                <img
                  class="accelerator_tips"
                  src="https://imgcloud.huifangkuai.com/Display/accelerator.png"
                />
              </div>
              <div class="btn22" @click="openPayModal('game')">
                <span
                  class="price2"
                  id="price"
                  style="font-weight: 600; color: #f3ff1a; margin-right: 4px"
                >
                  {{
                    smallDetails.bSharemake !== 1
                      ? "暂未开启购买"
                      : "￥" + discountPrice
                  }}</span
                >
                <span class="buy2" style="display: ">{{
                  smallDetails.bSharemake !== 1 ? "" : "立即购买"
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="goods_detail_banner">
          <div
            class="w goods_detail_banner_box game_detail_list_1"
            style="z-index: 6"
          >
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  :data-video="item.vedio"
                  :data-gameid="item.gameid"
                  v-for="(item, i) in goods_list"
                  :key="i"
                >
                  <a href="javascript:void(0)">
                    <!-- {{i}}--{{goodsCurrentIndex}} -->
                    <div class="slider" ref="goods_slider">
                      <div class="border">
                        <img
                          :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.abbreviation}`"
                        />
                      </div>

                      <div class="slider_text">{{ item.gamename_zh }}</div>
                      <!-- {{ item.gamename_en }} -->
                      <!-- <div class="goods_detail_banner_img">
                        <img
                          :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/abbreviation/${item.abbreviation}`"
                        />
                      </div>
                       <div class="goods_detail_banner_info">
                        <div class="goods_detail_banner_info_t ti">
                          {{ item.gamename_zh }}
                        </div>
                        <div class="goods_detail_banner_info_p ti">
                          {{ item.gamename_en }}
                        </div>
                      </div> -->
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="swiper-button-prev"
              @click="slideSwitch('goods_swiper', 'prev')"
            ></div>
            <div
              class="swiper-button-next"
              @click="slideSwitch('goods_swiper', 'next')"
            ></div>
          </div>
        </div>

        <div class="shadow"></div>
      </div>
      <br />
      <br />

      <div class="goods_detail_fixed">
        <a href="javascript:void(0)" @click="$router.push('/gamecombination')">
          <img
            src="https://imgcloud.huifangkuai.com/Display/stm/stm35476751_classify_img.167328ac.png"
            alt=""
          />
        </a>
      </div>

      <div class="goods_detail_banner">
        <div class="w goods_detail_banner_box">
          <div class="swiper-container">
            <div class="goods_azbz">
              <img src="../assets/imgs/steps.png" alt="" />
              <span> 安装步骤</span>
            </div>
            <ul class="step-list">
              <div class="step-item">
                <div class="title">01</div>
                <img src="../assets/imgs/mg.16dceac6.1.png" alt="" />
              </div>
              <div class="step-item">
                <div class="title">02</div>
                <img src="../assets/imgs/mg.16dceac6.2.png" alt="" />
              </div>
              <div class="step-item">
                <div class="title">03</div>
                <img src="../assets/imgs/mg.16dceac6.3.png" alt="" />
              </div>

              <div class="step-item">
                <a @click="uploadExe" class="btn">纯净安装</a>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div
        class="goods_detail_banner"
        style="padding: 100px; padding-top: 0; position: relative"
      >
        <div class="w goods_detail_banner_box">
          <div class="swiper-container">
            <div class="goods_azbz">
              <img src="../assets/imgs/preferential.png" alt="" />
              <span> 特惠合集</span>
            </div>
            <ul class="collect-list">
              <div
                class="collection-container"
                style="height: 246px"
                v-for="(item, i) in showDiscountMore ? 8 : 2"
                :key="i"
              >
                <div class="game-group">
                  <div class="game-swiper" style="width: 370px; height: 144px">
                    <!-- <swiper
                      ref="3DSwiper"
                      :options="SwiperOptions3D"
                      v-if="abbreviation.length > 0"
                      style="padding-top: 10px"
                    >
                      <swiper-slide
                        class="swiper-slide"
                        v-for="(item, j) in abbreviation.slice(
                          i * 3,
                          (i + 1) * 3
                        )"
                        :key="j + 1"
                      >
                        <img
                          class="swiper_img"
                          :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/abbreviation/${item.abbreviation}`"
                          alt=""
                        />
                      </swiper-slide>
                    </swiper> -->
                    <div
                      class="img"
                      v-for="(item, j) in abbreviation.slice(
                        i * 3,
                        (i + 1) * 3
                      )"
                      :key="j"
                    >
                      <img
                        :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/myGame/${item.abbreviation}`"
                        alt=""
                      />
                      <span :class="j === 2 ? 'icon2' : 'icon'">{{
                        j === 2 ? "=" : "+"
                      }}</span>
                    </div>
                  </div>
                  <div class="title ellipsis" style="padding: 0">
                    {{ vipGift[i].title }}
                  </div>
                  <div class="backgorund_line"></div>
                  <div class="text">
                    {{
                      abbreviation
                        .slice(i * 3, (i + 1) * 3)
                        .map((item) => item.gamename_zh)
                        .join("+")
                    }}
                  </div>
                </div>
                <div class="price_info">
                  <div class="source">原价： {{ calculateSourcePrice(i) }}</div>
                  <div class="discount">-{{ calculateRatio(i) }}%</div>

                  <div class="price">￥{{ calculatePrice(i) }}元</div>
                </div>
                <div class="btntw" @click="openPayModal('games', i)">
                  立即购买
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div
          class="model-btn"
          style="width: 100px"
          data-id="1"
          @click="uploadExe"
        >
          <!-- {{ !showDiscountMore ? "显示更多" : "收起" }} -->
          查看更多
        </div>
      </div>

      <br />
      <br />
      <div class="game-detail-content">
        <div class="game-desc">
          <div class="v-title">
            <img src="../assets/imgs/game_introduce.png" alt="" />
            <div class="titless">游戏介绍</div>
          </div>
          <div class="game-desc_container">
            <div
              class="game-desc-content"
              id="goods_content"
              v-html="smallDetails.infodetail"
            ></div>

            <div
              v-if="smallDetails.infodetail.indexOf('<img') < 0"
              style="padding: 0 20px"
            >
              <div
                class="goods_img"
                v-for="img in smallDetails.smallDetails.split(',')"
                :key="img"
              >
                <img
                  :src="`${BASE_IMAGE_URL}/${smallDetails.platform}/${smallDetails.gameid}/smallDetails/${img}`"
                  alt=""
                />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="detail">
            <div class="v-title">
              <img
                style="width: 32px; height: 26px"
                src="../assets/imgs/gameIcon.png"
                alt=""
              />
              <div class="titless" id="classify_name">游戏详情</div>
            </div>
            <div class="detail-content">
              <!-- <div class="titless">游戏详情</div> -->
              <ul id="xiangqing">
                <ul data-v-98fd4408="">
                  <li data-v-98fd4408="" class="item">
                    <span data-v-98fd4408="" class="key">开发商:</span>
                    <span data-v-98fd4408="" class="val">{{
                      smallDetails.developer
                    }}</span>
                  </li>
                  <li data-v-98fd4408="" class="item">
                    <span data-v-98fd4408="" class="key">首发日期:</span>
                    <span data-v-98fd4408="" class="val">{{
                      smallDetails.indate.slice(0, 10)
                    }}</span>
                  </li>
                  <li data-v-98fd4408="" class="item">
                    <span data-v-98fd4408="" class="key">支持语言:</span>
                    <span data-v-98fd4408="" class="val"
                      >简体中文,繁体中文,英文</span
                    >
                  </li>
                  <li data-v-98fd4408="" class="item">
                    <span data-v-98fd4408="" class="key">存储空间:</span>
                    <span data-v-98fd4408="" class="val">{{
                      smallDetails.peizhi.split("*")[6]
                    }}</span>
                  </li>
                </ul>
              </ul>
            </div>
            <div style="height: 1px"></div>
          </div>
          <!-- <div class="vip">
            <div class="v-title">
              <img src="../assets/imgs/vip_privilege.png" alt="" />
              <div class="titless" id="classify_name">会员特权</div>
            </div>
            <div class="vip-table" id="classify_intro">
              <ul class="column">
                <li class="row1">特权</li>
                <li class="row1 vip1">永久会员</li>
                <li class="row1 vip2">月度会员</li>
                <li class="row1 vip3">体验会员</li>
              </ul>
              <ul class="column">
                <li class="row1">客服</li>
                <li class="row">金牌会员专属</li>
                <li class="row">银牌会员专属</li>
                <li class="row">铜牌会员服务</li>
              </ul>
              <ul class="column">
                <li class="row1">热游</li>
                <li class="row">千款游戏免费玩</li>
                <li class="row">百款游戏免费玩</li>
                <li class="row">指定游戏免费玩</li>
              </ul>
              <ul class="column">
                <li class="row1">类型</li>
                <li class="row level-btn-bg">共享游戏</li>
                <li class="row level-btn-bg">共享游戏</li>
                <li class="row level-btn-bg">共享游戏</li>
              </ul>
              <ul class="column">
                <li class="row1">折扣</li>
                <li class="row">低至2折</li>
                <li class="row">低至7折</li>
                <li class="row">消费8折</li>
              </ul>
            </div>
          </div> -->
          <div class="game-detail game-detail-pz">
            <div class="v-title">
              <img src="../assets/imgs/detial.png" alt="" />
              <div class="titless">详细信息</div>
            </div>
            <div class="game-detail-content2">
              <div class="lowest">
                <div class="titless1">推荐配置</div>
                <ul id="tuijainpeizhi">
                  <ul data-v-98fd4408="" v-if="smallDetails.tjpeizhi">
                    <li data-v-98fd4408="" class="item">
                      操作系统: {{ smallDetails.tjpeizhi.split("*")[0] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      处理器: {{ smallDetails.tjpeizhi.split("*")[1] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      内存: {{ smallDetails.tjpeizhi.split("*")[2] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      显卡: {{ smallDetails.tjpeizhi.split("*")[3] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      DirectX 版本: {{ smallDetails.tjpeizhi.split("*")[4] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      网络: {{ smallDetails.tjpeizhi.split("*")[5] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      存储空间: {{ smallDetails.tjpeizhi.split("*")[6] }}
                    </li>
                  </ul>
                </ul>
              </div>
              <div class="recommend">
                <div class="titless1">最低配置</div>
                <div id="peizhi">
                  <ul data-v-98fd4408="" v-if="smallDetails.peizhi">
                    <li data-v-98fd4408="" class="item">
                      操作系统: {{ smallDetails.peizhi.split("*")[0] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      处理器: {{ smallDetails.peizhi.split("*")[1] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      内存: {{ smallDetails.peizhi.split("*")[2] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      显卡: {{ smallDetails.peizhi.split("*")[3] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      DirectX 版本: {{ smallDetails.peizhi.split("*")[4] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      网络: {{ smallDetails.peizhi.split("*")[5] }}
                    </li>
                    <li data-v-98fd4408="" class="item">
                      存储空间: {{ smallDetails.peizhi.split("*")[6] }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="loading" v-show="loading"></div>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="() => (this.dialogVisible = false)"
    >
      <PayModal
        :closePayModal="() => (this.dialogVisible = false)"
        :showState="dialogVisible"
        :gameData="gameData"
        :isShowForm="isShowForm"
      ></PayModal>
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import Swiper from "swiper";
//import obj from '../jsondata.json'
import PayModal from "../components/PayModal.vue";
import "swiper/dist/css/swiper.css";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
import { homeApi, userApi } from "../api";
import {
  getFiveDigits,
  getUser,
  formateDate,
  isMobile,
  getUUID,
} from "../common/utils";
import { BASE_IMAGE_URL } from "../common/config.json";
export default {
  name: "Gamedetail",
  components: {
    PayModal,
    // swiper,
    // swiperSlide,
  },
  data() {
    return {
      BASE_IMAGE_URL,
      dialogVisible: false,
      goods_list: [],
      goodsCurrentIndex: 0,
      SwiperOptions3D: {
        effect: "coverflow",
        loop: true,
        slidesPerView: "auto",
        centeredSlides: false,
        slideToClickedSlide: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 20,
          depth: 220,
          modifier: 1,
          slideShadows: true,
        },
      },
      showDiscountMore: true,
      smallDetails: {},
      bigDetails: {},
      buynum: 0,
      abbreviation: [],
      abbreviationTexts: [
        "最佳单机合集",
        "最佳冒险合集",
        "最佳剧情合集",
        "年度最畅销游戏",
        "三国系列合集",
        "战地风云系列集合",
        "射击游戏合集",
        "模拟游戏合集",
      ],
      vipGift: [
        {
          title: "最佳单机合集",
          total: 189,
          list: [
            { gameid: 814380, platform: "Steam" },
            { gameid: 968790, platform: "Steam" },
            { gameid: 261550, platform: "Steam" },
          ],
        },
        {
          title: "最佳冒险合集",
          total: 49,
          list: [
            { gameid: 203160, platform: "Steam" },
            { gameid: 242760, platform: "Steam" },
            { gameid: 1307550, platform: "Steam" },
          ],
        },
        {
          title: "最佳剧情合集",
          total: 129,
          list: [
            { gameid: 292030, platform: "Steam" },
            { gameid: 1030840, platform: "Steam" },
            { gameid: 1158310, platform: "Steam" },
          ],
        },
        {
          title: "年度最畅销游戏",
          total: 139,
          list: [
            { gameid: 1091500, platform: "Steam" },
            { gameid: 892970, platform: "Steam" },
            { gameid: 517630, platform: "Steam" },
          ],
        },
        {
          title: "三国系列合集",
          total: 160,
          list: [
            { gameid: 1591530, platform: "Steam" },
            { gameid: 968790, platform: "Steam" },
            { gameid: 730310, platform: "Steam" },
          ],
        },
        {
          title: "战地风云系列集合",
          total: 129,
          list: [
            { gameid: 1517290, platform: "Steam" },
            { gameid: 1238810, platform: "Steam" },
            { gameid: 304, platform: "Origin" },
          ],
        },
        {
          title: "射击游戏合集",
          total: 79,
          list: [
            { gameid: 517630, platform: "Steam" },
            { gameid: 550, platform: "Steam" },
            { gameid: 225540, platform: "Steam" },
          ],
        },
        {
          title: "模拟游戏合集",
          total: 99,
          list: [
            { gameid: 1089350, platform: "Steam" },
            { gameid: 255710, platform: "Steam" },
            { gameid: 1259970, platform: "Steam" },
          ],
        },
      ],
      gameData: {},
      loading: false,
      videoAutoPlay: true,
      user: {},
      showTimeCount: false,
      timeCountText: "",
      uploadsUrl: "https://download.zzzzu.com/v1/Steamalypc_v1.0.0.1367.exe",
      timer: "",
      // isPay: false,
      isShowForm: true,
    };
  },
  created() {
    if (isMobile()) {
      const { id, platform } = this.$route.params;
      this.$router.push(`/mGamedetail/${platform}/${id}`);
    }
  },
  mounted() {
    this.user = getUser() ? getUser() : { bVip: 0 };
    this.loadData();
    this.getGift();
    this.timeCount();
    // 获取下载客户端的url
    homeApi.reqUploads("1").then((res) => {
      this.uploadsUrl = res[0].childurl;
    });
  },
  activated() {
    this.videoAutoPlay = true;
  },
  deactivated() {
    this.dialogVisible = false;
  },
  computed: {
    playVideo() {
      const currentItem = this.goods_list[this.goodsCurrentIndex];
      if (currentItem) {
        if (currentItem.ext_video) {
          return currentItem.ext_video;
        } else {

          return `${this.$gameStartState.BASE_VIDEO_URL}/${currentItem.platform}/webm/${currentItem.vedio}`;
        }
      }
      return "";
    },
    discountPrice() {
      const { sharemoney, vsprice } = this.smallDetails;

      return (
        this.user.bVip == 1
          ? (sharemoney * 100 * (vsprice * 100)) / 10000
          : sharemoney
      ).toFixed(2);
    },
    discountRatio() {
      const { sharemoney, money } = this.smallDetails;
      return ((sharemoney / money - 1) * 100).toFixed(0);
    },
  },
  watch: {
    dialogVisible(value) {
      if (value) {
        this.$nextTick(() => {
          document.querySelector(".el-dialog__header").style.display = "none";
          document.querySelector(".el-dialog__body").style.padding = "20px";
        });
      }
    },

    $route() {
      if (this.$route.params.id && this.$route.params.platform) {
        this.loadData();
      }
    },

    goodsCurrentIndex(value) {
      const currentItem = this.goods_list[value];
      if (currentItem && this.smallDetails.id != currentItem.gameid) {
        this.smallDetails = { ...currentItem };
      }
    },
    // smallDetails(newValue){
    //   document.title=newValue.gamename_zh
    // }
  },
  methods: {
    getFiveDigits,
    loadData(
      id = this.$route.params.id,
      platform = this.$route.params.platform
    ) {
      this.loading = true;
      homeApi.reqNewList(`('${platform}_${id}')`).then((res) => {
        if (res.length > 0) {
          this.smallDetails = res[0] || {};
          if (res[0].ms == 0) {
            this.$router.push("/gamecenter");
          }
          this.getNewNum();
          this.$nextTick(() => (this.loading = false));
          // this.getlist();
          this.getList();
          document.title = this.smallDetails.gamename_zh;
          userApi.reqUserHistory(
            getUUID(),
            this.smallDetails.gameid,
            this.smallDetails.platform,
            window.location.href,
            this.$gameStartState.ip
          );
        } else {
          this.$router.push("/gamecenter");
        }
      });
    },
    uploadExe() {
      let url = window.location.origin.split("https://")[1];
      homeApi.getChannelDownloadUrl(url).then((res) => {
        if (res) {
          console.log(res.dowload_url);
          var a = document.createElement("a");
          a.href = res.dowload_url.replace(/https/,'http').replace(/http/,'https');
          a.download = this.$gameStartState.fileName;
          a.click();
        }
      });
      // console.log(this.$gameStartState.file)
      // var a = document.createElement("a");
      // a.href = this.$gameStartState.file;
      // a.download = this.$gameStartState.fileName;
      // a.click();
    },
    getNewNum() {
      // var that = this;
      window.clearInterval(this.timer);
      if (this.smallDetails.bSharemake === 1) {
        // this.numFn(0,this.getFiveDigits());
        this.buynum = this.getFiveDigits();
        // let num = this.buynum;
        // this.timer = setInterval(function () {
        //   that.$nextTick(
        //     (that.buynum = (
        //       num * 1 +
        //       Math.round(Math.random() * 20 + 30)
        //     ).toString())
        //   );
        // }, 2000);
      } else {
        this.buynum = "0000";
      }
    },
    //计算倒计时
    timeCount() {
      const date = formateDate(
        new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      );
      this.timeCountDown(date, (value, clear) => {
        this.timeCountText = value;
        if (value != "已结束") {
          this.showTimeCount = true;
        }
        this.$once("hook:beforeDestroy", clear);
      });
    },
    numFn(startNum, maxNum) {
      var that = this;
      let numText = startNum;
      let golb; // 为了清除requestAnimationFrame
      function numSlideFun() {
        // 数字动画
        numText += parseInt((maxNum - startNum) / 10); // 速度的计算可以为小数 。数字越大，滚动越快
        if (numText >= maxNum) {
          numText = maxNum;
          cancelAnimationFrame(golb);
        } else {
          golb = requestAnimationFrame(numSlideFun);
        }
        that.buynum = numText;
      }
      numSlideFun();

      setInterval(() => {
        let points = Math.floor(Math.random() * (50 - 45 + 1) + 45);
        startNum = numText;
        maxNum += points;
        numSlideFun();
      }, 5000); // 调用数字动画
    },
    /**
     * 日期倒计时
     *  @param date 如：2020-08-20 18:00:00
     */
    timeCountDown(date, callback = () => {}) {
      var time = "";
      const formatNumber = (n) => {
        n = n.toString();
        return n[1] ? n : "0" + n;
      };
      const setTime = () => {
        const leftTime = new Date(date.replace(/-/g, "/")) - new Date();
        if (leftTime >= 0) {
          // 天计算
          // const d = Math.floor(leftTime / 1000 / 60 / 60 / 24),
          // 小时计算
          const d = Math.floor(leftTime / 1000 / 60 / 60),
            h = Math.floor((leftTime / 1000 / 60 / 60) % 24),
            m = Math.floor((leftTime / 1000 / 60) % 60),
            s = Math.floor((leftTime / 1000) % 60);
          // 天计算
          // time = `${ d > 0 ? d + '天' : '' }${ [h, m, s]
          // 小时计算
          time = `${[h + (d >= 24 ? d : 0), m, s].map(formatNumber).join(":")}`;
          callback(time, () => clearInterval(timer));
        } else {
          time = "已结束";
          callback(time, () => clearInterval(timer));
          return;
        }
      };
      const timer = setInterval(() => {
        setTime();
      }, 1e3);
    },
    getList() {
      homeApi.reqAllGameList().then((res) => {
        let _goods_list = [];
        const { is_info_banner } = res;
        let arr = is_info_banner.filter((item) => {
          return item.gameid !== this.smallDetails.gameid;
        });
        _goods_list = [this.smallDetails, ...arr];
        this.goods_list = _goods_list;
        this.$nextTick(() => this.initSwiper());
      });
    },
    getlist() {
      var pg = [
        { gameid: 578080, platform: "Steam" },
        { gameid: 1196590, platform: "Steam" },
        { gameid: 283, platform: "Origin" },
        { gameid: 31, platform: "Origin" },
        { gameid: 346110, platform: "Steam" },
      ];
      var game = pg.filter((item) => {
        return !(
          item.gameid == this.smallDetails.gameid &&
          item.platform == this.smallDetails.platform
        );
      });
      game.unshift({
        gameid: this.smallDetails.gameid,
        platform: this.smallDetails.platform,
      });

      var _list = [];
      game.forEach((item) => {
        _list.push(`${item.platform}_${item.gameid}`);
      });

      homeApi.reqNewList(`('${_list.join("','")}')`).then((res) => {
        let _goods_list = [];
        game.forEach((row) => {
          row = res.find((resItem) => {
            return resItem.gameid == row.gameid;
          });
          _goods_list.push(row ? { ...row, gameposition: "hot" } : {});
        });
        this.goods_list = _goods_list;
        this.$nextTick(() => this.initSwiper());
      });
    },

    getGift() {
      var _list = [];
      // 游戏礼包
      this.vipGift.forEach((gift) => {
        gift.list.forEach((item) => {
          _list.push(`${item.platform}_${item.gameid}`);
        });
      });
      homeApi.reqNewList(`('${_list.join("','")}')`).then((res) => {
        let giftArr = [];
        // 游戏礼包
        this.vipGift.forEach((item) => {
          item.list.forEach((row, index) => {
            item.list[index] = res.find((resItem) => {
              return resItem.gameid == row.gameid;
            });
            giftArr.push(item.list[index] ? item.list[index] : {});
          });
        });
        this.abbreviation = giftArr;
      });
    },

    // 计算优惠价格
    calculatePrice(i) {
      return this.vipGift[i].total;
    },

    calculateSourcePrice(i) {
      return Math.floor(
        this.abbreviation
          .slice(i * 3, (i + 1) * 3)
          .reduce((pre, item) => pre + item.money, 0)
      );
    },

    // 计算礼包优惠百分比
    calculateRatio(i) {
      return (
        (1 - this.calculatePrice(i) / this.calculateSourcePrice(i)) *
        100
      ).toFixed(0);
    },

    openPayModal(type, index) {
      if (type === "game") {
        if (this.smallDetails.bSharemake != 1) {
          return this.$message.error("当前游戏暂时无法购买");
        }
        this.gameData = {
          buyType: type,
          game: this.smallDetails,
        };
      } else if (type === "games") {
        this.gameData = {
          buyType: type,
          bagName: this.vipGift[index].title,
          games: this.abbreviation.slice(index * 3, (index + 1) * 3),
          finallyPrice: this.calculatePrice(index),
        };
      }
      this.isShowForm = getUser() ? false : true;
      this.dialogVisible = true;
    },

    initSwiper() {
      // var packs = document.querySelector(".game-swiper");
      // var pack = packs.querySelector(".swiper-container");

      // // console.log(pack);

      // new Swiper(pack, {
      //   loop: true,
      //   effect: "coverflow",
      //   slidesPerView: "auto",
      //   centeredSlides: true,
      //   slideToClickedSlide: true,
      //   coverflowEffect: {
      //     rotate: 0,
      //     stretch: 55,
      //     depth: 280,
      //     slideShadows: false,
      //   },
      // });

      // var pack2 = document.querySelector(".swiper-container01");

      // new Swiper(pack2, {
      //   loop: true,
      //   effect: "coverflow",
      //   slidesPerView: "auto",
      //   centeredSlides: true,
      //   slideToClickedSlide: true,
      //   coverflowEffect: {
      //     rotate: 0,
      //     stretch: 55,
      //     depth: 280,
      //     slideShadows: false,
      //   },
      // });

      const _this = this;
      this.goods_swiper = new Swiper(".game_detail_list_1 .swiper-container", {
        loop: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        slidesPerView: 4,
        spaceBetween: 15,
        onSlideChangeEnd: function () {
          const dom = document.querySelector(
            ".game_detail_list_1 .swiper-slide-active"
          );
          const video = dom.getAttribute("data-video");
          const index = _this.goods_list.findIndex(
            (item) => item.vedio === video
          );
          _this.goodsCurrentIndex = index;
          _this.getNewNum();
        },
        on: {
          slideChangeTransitionEnd: function () {
            // $('#xiangqing').html(obj.xiangqing);
            // $('#tuijainpeizhi').html(obj.tuijainpeizhi);
            // $('#peizhi').html(obj.peizhi);
            // $('#goods_content').html(obj.goods_content);
            // $('#price').html(obj.price);
            // $('#price2').html(obj.price);
            // $('#yuanjia').html(obj.yuanjia);
            // $('#brand').html(obj.brand);
            // $('#sales').html(obj.sales);
            // $('#color').html(obj.color);
            // $('#goods_name').html(obj.goods_name);
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },

    slideSwitch(swiper, type) {
      if (type === "prev") {
        this[swiper].slidePrev();
      } else if (type === "next") {
        this[swiper].slideNext();
      }
    },
  },
  beforeDestroy() {
    document.title = "游戏平台";
  },
};

$(".show-more").click(function () {
  var id = $(this).attr("data-id");
  if (id == 1) {
    $(".collect-list").css("height", "auto");
    $(this).text("收起");
    $(this).attr("data-id", 2);
  } else {
    $(".collect-list").css("height", "288px");
    $(this).text("显示更多");
    $(this).attr("data-id", 1);
  }
});
</script>

<style scoped lang="less">
@import url("../assets/css/reset.css");
@import url("../assets/css/public.css");
@import url("../assets/css/font_2442414_fx489h9ah1d.css");
@import url("../assets/css/font_2442491_or1abnb55db.css");
@import url("../assets/css/index.css");
@import url("../assets/css/swiper-bundle.min.css");
@import url("../assets/css/goods.css");

@font-face {
  font-family: "iconfont";
  src: url("../assets/css/font_2442414_fx489h9ah1d.woff2?t=1625642978036")
      format("woff2"),
    url("../assets/css/font_2442491_or1abnb55db.woff2?t=1626839359807")
      format("woff2");
}
.swiperleft {
  transform: translateX(10px);
}
.swiperright {
  transform: translateX(-10px);
}
.slider {
  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: fill !important;
    border-radius: 5px;
  }
  .slider_text {
    position: absolute;
    width: 100%;
    bottom: 3px;
    font-size: 14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }
}
.swiper-slide-active .slider {
  .slider_text {
    bottom: 8px;
  }
}
.border {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  margin-right: 0 !important;
  width: 260px !important;
}
.swiper-slide-active {
  margin-right: 0 !important;
  width: 320px !important;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  transform: translateY(-20%);
  width: 30px;
  height: 70px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: url("../assets/images/right.png") no-repeat center
    rgba(255, 255, 255, 0.06);
  &::after {
    display: none;
  }
}
.swiper-button-prev {
  background: url("../assets/images/left.png") no-repeat center
    rgba(255, 255, 255, 0.06);
}
.game-swiper {
  display: flex;
  margin-right: 0;
  .img {
    position: relative;
    margin-right: 18px;
    img {
      border-radius: 5px;
      border: 1px solid #95690c;
      width: 102px;
      height: 142px;
    }
    .icon,
    .icon2 {
      display: inline-block;
      position: absolute;
      top: 52%;
      right: -21.5%;
      transform: translateY(-50%);
      text-align: center;
      border-radius: 4px;
      border: 1px solid #dfbd6e;
      color: #fbac02;
      font-weight: 700;
      width: 25px;
      height: 18px;
      background-color: #151d27;
      line-height: 16px;
      z-index: 999;
    }
    .icon2 {
      right: -21%;
    }
  }
}
.collection-container {
  position: relative;
}
.ellipsis {
  margin: 5px 0 3px 0;
  color: #eeece6;
}
.backgorund_line {
  width: 320px;
  height: 2px;
  background-image: linear-gradient(
    90deg,
    rgba(158, 110, 7, 0),
    rgba(158, 110, 7, 0.8),
    rgba(158, 110, 7, 0)
  );
}
.text {
  width: 84%;
}
.price_info {
  width: 120px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #292929;
  border-radius: 8px;
  margin-right: 20px;
  margin-top: 16px;
  padding: 5px 0;
  .discount {
    margin: 10px 0;
    display: block;
    padding: 0 2px;
    height: 26px;
    line-height: 25px;
    text-align: center;
    background-color: #4d3b18;
    border-radius: 4px;
    border: 1px solid #fbac02;
    font-size: 18px;
    color: #1096c5;
    font-weight: 700;
  }
}
.btntw {
  position: absolute;
  right: 0;
  top: 75%;
  background: url("../assets/images/back.png");
  width: 142px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  color: white;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 20px;
  font-weight: normal;
  bottom: 15px;
  &:hover {
    color: #04beff;
  }
}
.goods_azbz {
  img {
    margin-right: 7px;
  }
}
.shadow {
  position: absolute;
  bottom: -175px;
  height: 321px;
  width: 100%;
  opacity: 1;
  background: linear-gradient(180deg, transparent, #131820 45%);
  z-index: 1;
}

.show-more {
  margin-top: 15px;
  border: 2px solid #05dafa;
  border-radius: 3px;
  &:hover {
    border-color: #f07d06;
    background-color: #e15304;
  }
}

.swiper-slide {
  width: 106px;
  height: 128px;
  border-radius: 0;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide p {
  line-height: 98px;
  padding-top: 0;
  text-align: center;
  color: #636363;
  font-size: 1.1em;
  margin: 0;
}

.swiper-pagination {
  width: 100%;
  bottom: 20px;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  border: 3px solid #fff;
  background-color: #d5d5d5;
  width: 10px;
  height: 10px;
  opacity: 1;
}

.swiper-pagination-bullets .swiper-pagination-bullet-active {
  border: 3px solid #00aadc;
  background-color: #fff;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 100;
}
.coundDown {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  top: -54px;
  height: 26px;
  font-weight: 600;
  background-size: 100% 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 18px;
}
.coundDown span {
  font-size: 12px;
  letter-spacing: 1px;
  margin-right: 5px;
}
.game-desc-content {
  font-size: 14px;
}
.accelerator_tips {
  position: absolute;
  transform: translateY(100%);
  bottom: -10px;
  width: 257px;
  height: 66px;
  left: -10px;
}
.btn32 {
  background: url(https://imgcloud.huifangkuai.com/Display/btn1.png) no-repeat 50%;
}
.btn22 {
  background: url(https://imgcloud.huifangkuai.com/Display/btn2.png) no-repeat 50%;
}
</style>

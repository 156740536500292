/* home请求模块 */
import ajax from '../ajax'
import { BASE_REQUEST_URL_DEV, BASE_REQUEST_URL_PROD } from '../../common/config.json'
const BASE_REQUEST_URL = process.env.NODE_ENV === 'production' ? BASE_REQUEST_URL_PROD : BASE_REQUEST_URL_DEV
// import {  BASE_REQUEST_URL_PROD,BASE_REQUEST_URL_TEST } from '../../common/config.json'
// const BASE_REQUEST_URL = process.env.NODE_ENV === 'production' ? BASE_REQUEST_URL_PROD : BASE_REQUEST_URL_TEST


// 首页接口
const reqFirstpage = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/firstpage`, { Uuid, page, sign }, 'POST');

// 展示站首页数据
const reqWebFirstPage = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/webfirstpage`, { Uuid, page, sign }, 'POST');

// 游戏中心
const reqGamelist = (Uuid, page, sign, num, gamename, platform, flag, type,ispricetop,isms) => ajax(`${BASE_REQUEST_URL}/gamelist`, { Uuid, page, sign, num, gamename, platform, flag, type,ispricetop,isms}, 'POST');

// 游戏详情
const reqGameintroduction = (Uuid, page, sign, gameid, platform) => ajax(`${BASE_REQUEST_URL}/gameintroduction`, { Uuid, page, sign, gameid, platform }, 'POST');

// 免费专区
const reqFreegame = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/freegame`, { Uuid, page, sign }, 'POST');

// 游戏交换展示数据
const reqGamechange = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/gamechange`, { Uuid, page, sign }, 'POST');

// 游戏交换操作(newid:要交换的游戏id, oldid:我的共享游戏)
const reqGamechangeOp = (Uuid, page, sign, newid, oldid) => ajax(`${BASE_REQUEST_URL}/gamechangeop`, { Uuid, page, sign, newid, oldid }, 'POST');

// 加速器
const reqSpeed = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/speed`, { Uuid, page, sign }, 'POST');

// 游戏大礼包
const reqGamegift = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/gamegift`, { Uuid, page, sign }, 'POST');

// 88元游戏
const reqNice = (Uuid, page, sign) => ajax(`${BASE_REQUEST_URL}/nice`, { Uuid, page, sign }, 'POST');

// 88元游戏里添加
const reqAllgame = (Uuid, page, sign, gamename) => ajax(`${BASE_REQUEST_URL}/allgame`, { Uuid, page, sign, gamename }, 'POST');

// 获取前端自定义数据
const reqVipgift = (pg, pgposition) => ajax(`${BASE_REQUEST_URL}/vipgift`, { pg, pgposition }, 'POST');

// 优化+ 列表查询 
const reqNewList = (pg) => ajax(`${BASE_REQUEST_URL}/newgetinfo`, { pg }, 'POST');

// 根据ip查询用户所在地
const reqIpQueryCity=(ip)=>ajax(`${BASE_REQUEST_URL}/getCity`,{ip},"POST")
// 提交ocpc
const reqOcpc = (conversionTypes,
  ipaddress,domain,selfbill=0,uuid="") => ajax(`${BASE_REQUEST_URL}/baiduocpc`, { conversionTypes,ipaddress ,domain,selfbill,uuid}, 'POST');
// 获取ip地址
const reqGetIpAddress=()=>ajax(`${BASE_REQUEST_URL}/getIp`)
// 根据ip地址 提交用户首次打开网站信息
const reqGetUserFirst = (ipaddress, url, logintime, bdvid,belong,uuid,isdownload=0,isvaliduser=0) => ajax(`${BASE_REQUEST_URL}/getuserfirst`, { ipaddress, url, logintime, bdvid,belong,uuid,isdownload,isvaliduser}, 'POST');


const reqXfBdvid = (ipaddress, selfbill) => ajax(`${BASE_REQUEST_URL}/xfbdvid`, { ipaddress, selfbill}, 'POST');

// 获取首页底部图片
const reqGetFootImg = (url) => ajax(`${BASE_REQUEST_URL}/getUrlImg`, {url}, 'POST');
// 获取下载客户端的版本
const reqUploads = (version) => ajax(`${BASE_REQUEST_URL}/getbanben`, { version }, 'POST')
// 所有游戏列表查询
const reqAllGameList = () => ajax(`${BASE_REQUEST_URL}/getListData`);
// 提交搜索记录
const reqPostSearchLog=(data)=>ajax(`${BASE_REQUEST_URL}/searchlog`,data,"POST")
// 优化+ 列表查询 
// const reqOcpc2 = (token,conversionTypes) => ajax(`https://ocpc.baidu.com/ocpcapi/api/uploadConvertData`, {token , conversionTypes}, 'POST');
// 获取工具和平台的下载链接
const reqGetTool=()=>ajax(`${BASE_REQUEST_URL}/getTool`)
// 获取获取客服端下载链接
const getChannelDownloadUrl=(domain)=>ajax(`${BASE_REQUEST_URL}/getChannelDownloadUrl`, {domain}, 'POST')
export default {
  getChannelDownloadUrl,
  reqFirstpage,
  reqWebFirstPage,
  reqGamelist,
  reqGameintroduction,
  reqFreegame,
  reqGamechange,
  reqGamechangeOp,
  reqSpeed,
  reqGamegift,
  reqNice,
  reqAllgame,
  reqVipgift,
  reqNewList,
  reqOcpc,
  reqGetUserFirst,
  reqXfBdvid,
  reqGetFootImg,
  reqUploads,
  reqIpQueryCity,
  reqAllGameList,
  reqGetIpAddress,
  reqPostSearchLog,
  reqGetTool
}
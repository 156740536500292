<template>
  <div class="mHome">
    <!-- 顶部bannar -->
    <div class="top_bannar">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannarList" :key="index">
          <img class="bannar_li" :src="item" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 站点展示 -->
    <div class="web_desc">
      <div class="online_num">
        <div class="online_icon">
          <div class="online_icon_1"></div>
          <div class="online_icon_2"></div>
        </div>
        <div class="online_number">
          <div class="online_number_1">在线：681,321</div>
          <div class="online_number_2">游戏中：612,548</div>
        </div>
      </div>
      <!-- 边界 -->
      <div class="crossLine"></div>
      <div class="tf_1">
        <img class="tf_1_img" src="../assets/images/h5/tf_1.png" alt="" />
        <div class="tf_1_name">官方正版</div>
      </div>
      <div class="tf_2">
        <img class="tf_1_img" src="../assets/images/h5/tf_2.png" alt="" />
        <div class="tf_1_name">支持联机</div>
      </div>
      <div class="tf_3">
        <img class="tf_3_img" src="../assets/images/h5/tf_3.png" alt="" />
        <div class="tf_1_name">支持存档</div>
      </div>
      <div class="crossLine"></div>
      <div class="tf_4">
        <img class="tf_4_img" src="../assets/images/h5/gh.png" alt="" />
        <div class="tf_4_name">独家推荐平台</div>
      </div>
    </div>
    <!-- 最新上市 -->
    <div class="sameBox">
      <div class="sameBox_title">
        <div class="sameBox_name">最新上市</div>
        <div class="sameBox_more">
          更多
          <img
            class="sameBox_more_icon"
            src="../assets/images/h5/more.png"
            alt=""
          />
        </div>
      </div>
      <div class="sameBox_content">
        <div
          class="sameBox_li_1"
          v-for="(item, index) in newGameList"
          :key="index"
        >
          <img
            class="sameBox_li_1_img"
            :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.gameimg}`"
            alt=""
          />
          <div class="sameBox_li_1_name">{{ item.gamename_zh }}</div>
          <div class="sameBox_li_1_time">{{ item.indate }}</div>
        </div>
      </div>
    </div>
    <!-- 近期热门 -->
    <div class="sameBox">
      <div class="sameBox_title">
        <div class="sameBox_name">近期热门</div>
        <div class="sameBox_more">
          更多
          <img
            class="sameBox_more_icon"
            src="../assets/images/h5/more.png"
            alt=""
          />
        </div>
      </div>
      <div class="sameBox_content">
        <div
          class="sameBox_li_1"
          v-for="(item, index) in hotGameList"
          :key="index"
        >
          <img
            class="sameBox_li_2_img"
            :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.gameimg}`"
            alt=""
          />
          <div class="sameBox_li_1_name">{{ item.gamename_zh }}</div>
        </div>
      </div>
    </div>
    <!-- 大家都在玩 -->
    <div class="allPlay">
      <div class="sameBox_title">
        <div class="sameBox_name">大家都在玩</div>
        <div class="sameBox_more">
          更多
          <img
            class="sameBox_more_icon"
            src="../assets/images/h5/more.png"
            alt=""
          />
        </div>
      </div>
      <div class="allplay_content">
        <div
          class="allplay_content_li"
          v-for="(item, index) in allPlayGameList"
          :key="index"
        >
          <img
            class="allplay_content_li_img"
            :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.gameimg}`"
            alt=""
          />
          <div class="sameBox_li_1_name">{{ item.gamename_zh }}</div>
        </div>
      </div>
    </div>
    <!-- 列表分类 -->
    <van-tabs v-model="active" sticky background="hsla(0, 0%, 100%, .06)" color="#fff">
      <van-tab v-for="(item, index) in tabList" :title="item" :key="index">
        <div style="width: 100%; height: 1000px"></div>
      </van-tab>
      <van-tab>
        <template #title> 更多<van-icon name="arrow" /> </template>
        内容 {{ index }}
      </van-tab>
    </van-tabs>
    <!-- 底部菜单栏 -->
    <!-- <TabBar :active="0"></TabBar> -->
  </div>
</template>

<script>
// import TabBar from "../components/tabBar.vue";
import { homeApi } from "../api";
import { getUUID, getSign } from "../common/utils";
import { BASE_IMAGE_URL } from "../common/config.json";
export default {
  // components: {
  //   TabBar,
  // },
  data() {
    return {
      tabList: ["热销", "折扣", "最新", "价格"],
      bannarList: [
        "http://imgcloud.hfxyw.cn/Steam/578080/list/1.jpg",
        "http://imgcloud.hfxyw.cn/Steam/255710/list/1.jpg",
        "http://imgcloud.hfxyw.cn/Steam/271590/list/1.jpg",
      ],
      newGameList: [],
      hotGameList: [],
      allPlayGameList: [],
      BASE_IMAGE_URL,
    };
  },
  created() {
    this.getNewGameList("indate");
    this.getNewGameList("px");
    this.getNewGameList("discount");
  },
  mounted() {},
  methods: {
    //获取最新游戏列表取六个
    getNewGameList(type) {
      let that = this;
      homeApi
        .reqGamelist(getUUID(), 1, getSign(), 6, "", "", "", type, 1,1)
        .then((res) => {
          if (type == "indate") {
            that.newGameList = res.list;
          } else if (type == "px") {
            that.hotGameList = res.list;
          } else if (type == "discount") {
            that.allPlayGameList = res.list;
          }
        });
    },
  },
};
</script>
<style>
.van-tab{
  color: #fff !important;
}
</style>
<style scoped src="../assets/css/h5/mHome.css">
</style>
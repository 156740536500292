<template>
  <div class="mGamedetail" v-loading="loading">
    <!-- 跳转按钮 -->
    <div class="jump_btn" @click="toGameCenter()">返回</div>
    <!-- 底部视频 -->
    <div class="mGamedetail_videoBox" :class="dialogIsShow ? 'hidden' : ''">
      <video controls="true" controlslist="nodownload" :src="playVideo"></video>
    </div>
    <!-- 轮播图 -->
    <div class="goods_detail_banner">
      <div
        class="w goods_detail_banner_box game_detail_list_1"
        style="z-index: 6"
      >
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              :data-video="item.vedio"
              :data-gameid="item.gameid"
              v-for="(item, i) in goods_list"
              :key="i"
            >
              <a href="javascript:void(0)">
                <!-- {{i}}--{{goodsCurrentIndex}} -->
                <div class="slider" ref="goods_slider">
                  <div class="border">
                    <img
                      :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/list/${item.abbreviation}`"
                    />
                  </div>

                  <div class="slider_text">{{ item.gamename_zh }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          class="swiper-button-prev"
          @click="slideSwitch('goods_swiper', 'prev')"
        ></div>
        <div
          class="swiper-button-next"
          @click="slideSwitch('goods_swiper', 'next')"
        ></div>
      </div>
    </div>
    <!-- 游戏信息 -->
    <div class="mgame_info">
      <div class="mgame_price">
        <img src="../assets/imgs/tehui.png" alt="" />
        <small>¥</small
        ><span class="sharemoney">{{ smallDetails.sharemoney }}</span>
        <span class="discount">
          {{
            ((smallDetails.sharemoney / smallDetails.money) * 10).toFixed(1)
          }}折
        </span>
        <del>¥{{ smallDetails.money }}</del>
      </div>
      <div class="mgame_name_zh">{{ smallDetails.gamename_zh }}</div>
      <div class="mgame_name_en">{{ smallDetails.gamename_en }}</div>
      <div class="mgame_label">
        <div
          class="game-types"
          v-for="flag in smallDetails.gameflag
            ? smallDetails.gameflag.split(',')
            : smallDetails.gamelabel.split(',')"
          :key="flag"
        ></div>
      </div>
    </div>
    <!-- 声明图片 -->
    <div class="mgame_img">
      <span>
        <img data-v-0d9af763="" src="../assets/imgs/icon3.png" />账号保障
      </span>
      <span>
        <img data-v-0d9af763="" src="../assets/imgs/icon1.png" />错误包换
      </span>
      <span>
        <img data-v-0d9af763="" src="../assets/imgs/icon2.png" />
        官方正版
      </span>
    </div>
    <!-- tabbar -->
    <div class="mgame_tabbar">
      <div class="mgame_details" @click="switchTab(0)">
        <span v-if="tab == 0"></span>游戏详情
      </div>
      <div class="mgame_productinfo" @click="switchTab(1)">
        <span v-if="tab == 1"></span>商品信息
      </div>
    </div>
    <!-- 游戏详情tab -->
    <div class="mgame_details_text" v-show="tab == 0">
      <div class="mgame_title">游戏介绍</div>
      <hr />
      <div class="mgame_text" v-html="smallDetails.infodetail"></div>
      <hr />
      <div class="mgame_title">最低配置</div>
      <ul v-if="smallDetails.peizhi">
        <li data-v-98fd4408="" class="item">
          操作系统: {{ smallDetails.peizhi.split("*")[0] }}
        </li>
        <li data-v-98fd4408="" class="item">
          处理器: {{ smallDetails.peizhi.split("*")[1] }}
        </li>
        <li data-v-98fd4408="" class="item">
          内存: {{ smallDetails.peizhi.split("*")[2] }}
        </li>
        <li data-v-98fd4408="" class="item">
          显卡: {{ smallDetails.peizhi.split("*")[3] }}
        </li>
        <li data-v-98fd4408="" class="item">
          DirectX 版本: {{ smallDetails.peizhi.split("*")[4] }}
        </li>
        <li data-v-98fd4408="" class="item">
          网络: {{ smallDetails.peizhi.split("*")[5] }}
        </li>
        <li data-v-98fd4408="" class="item">
          存储空间: {{ smallDetails.peizhi.split("*")[6] }}
        </li>
      </ul>
      <hr />
      <div class="mgame_title">推荐配置</div>
      <ul v-if="smallDetails.tjpeizhi">
        <li data-v-98fd4408="" class="item">
          操作系统: {{ smallDetails.tjpeizhi.split("*")[0] }}
        </li>
        <li data-v-98fd4408="" class="item">
          处理器: {{ smallDetails.tjpeizhi.split("*")[1] }}
        </li>
        <li data-v-98fd4408="" class="item">
          内存: {{ smallDetails.tjpeizhi.split("*")[2] }}
        </li>
        <li data-v-98fd4408="" class="item">
          显卡: {{ smallDetails.tjpeizhi.split("*")[3] }}
        </li>
        <li data-v-98fd4408="" class="item">
          DirectX 版本: {{ smallDetails.tjpeizhi.split("*")[4] }}
        </li>
        <li data-v-98fd4408="" class="item">
          网络: {{ smallDetails.tjpeizhi.split("*")[5] }}
        </li>
        <li data-v-98fd4408="" class="item">
          存储空间: {{ smallDetails.tjpeizhi.split("*")[6] }}
        </li>
      </ul>
    </div>
    <!-- 商品信息tab -->
    <div class="mgame_productinfo_text" v-show="tab == 1">
      <div class="mgame_title">商品简介:</div>
      <hr />
      <p>
        本商品为 steam 零售商出售的<span style="color: red">PC正版游戏</span>
      </p>
      <p>名称：{{ smallDetails.gamename_zh }}</p>
      <p>类型：{{ smallDetails.gameflag || smallDetails.gamelabel }}</p>
      <p>开发商：{{ smallDetails.gamename_en }}</p>
      <p>发行时间：{{ smallDetails.indate }}</p>
      <p>支持语言:简体中文,繁体中文,英文</p>
      <div class="mgame_title">买前须知:</div>
      <hr />

      <p>
        1.平台所售游戏或其他三方产品，一经激活，非质量问题，无法退换，激活码【CDK】/独享/会员类产品，一经发送无法退货！
      </p>
      <p>2.平台不负责玩家使用第三方软件，作弊，违规等造成的后果。</p>
      <p>3.平台不承担玩家电脑配置，网络等问题不能运行游戏所造成的问题。</p>
      <p>
        4.平台承诺，所有游戏均为正版！游戏为虚拟数字商品，一经发货，领取，激活后将失去保密性，游戏激活后将终身绑定在您购买的账号内，我们无法收回，故不支持退换。
      </p>
      <p>
        5.根据国家有关法律法规及平台规则，购买网游类商品需年满18周岁，禁止未成年人购买。
      </p>
    </div>
    <!-- 下载游戏平台客户端 -->
    <div class="down_load" @click="showDownloadUrl">
      <img
        class="logoimg"
        src="https://imgcloud.huifangkuai.com/Display/stm/mlogo.png"
        alt=""
      />
      <span>下载游戏平台客户端</span>
      <img class="rightimg" src="../assets/images/right.png" alt="" />
    </div>
    <!-- 底部购买 -->
    <div class="mgame_footer">
      <div class="footer_left">
        {{ smallDetails.gamename_zh }}
      </div>
      <div class="footer_right" @click="pay">
        <span>立即购买</span
        ><span class="sharemoney"
          ><small>¥</small>{{ smallDetails.sharemoney
          }}<del>¥{{ smallDetails.money }}</del></span
        >
      </div>
    </div>
    <!-- 商品信息弹窗 -->
    <div
      class="mgame_productinfo_dialog"
      v-if="dialogIsShow"
      @click.stop="closeDialog"
      @touchmove.prevent
      v-loading="loading"
    >
      <div class="dialog_body" @click.stop>
        <div class="dialog_title">确认订单</div>
        <!-- 订单信息 -->
        <div class="dialog_gameinfo">
          <div class="dialog_game_img">
            <img
              :src="`${BASE_IMAGE_URL}/${smallDetails.platform}/${smallDetails.gameid}/list/${smallDetails.abbreviation}`"
              alt=""
            />
          </div>
          <div class="dialog_game_info">
            <div class="dialog_game_info_name">
              {{ smallDetails.gamename_zh
              }}<span>¥{{ smallDetails.sharemoney }}</span>
            </div>
            <div>账号使用权永久共享</div>
          </div>
        </div>
        <!-- 三张图片 -->
        <div class="mgame_img dialog_mgame_img">
          <span>
            <img data-v-0d9af763="" src="../assets/imgs/icon3.png" />账号保障
          </span>
          <span>
            <img data-v-0d9af763="" src="../assets/imgs/icon1.png" />错误包换
          </span>
          <span>
            <img data-v-0d9af763="" src="../assets/imgs/icon2.png" />
            官方正版
          </span>
        </div>
        <!-- 订单金额 -->
        <div class="order_amount">
          <span> 订单总计：</span
          ><span
            >¥{{
              (this.payType == 1
                ? $gameStartState.zfbCharges * smallDetails.sharemoney
                : $gameStartState.wxCharges * smallDetails.sharemoney
              ).toFixed(2)
            }}</span
          >
        </div>
        <!-- 支付方式 -->
        <div class="pay_type">
          <span> 支付方式:</span>
          <button
            class="payType-item zfb-pay"
            :class="payType === 1 && 'active'"
            @click="payType = 1"
          >
            <span
              class="payType-tips"
              v-if="Number($gameStartState.zfbCharges) != 1"
              >{{ $gameStartState.zfbChargesText }}</span
            >
            <span class="payType-tips payType-tips_solid" v-else>推荐</span>
            <img :src="imgs.zfb" alt="" /> 支付宝
          </button>
          <button
            class="payType-item wx-pay"
            :class="payType === 2 && 'active'"
            @click="payType = 2"
          >
            <span
              class="payType-tips"
              v-if="Number($gameStartState.wxCharges) != 1"
              >{{ $gameStartState.wxChargesText }}</span
            >
            <img :src="imgs.wx" alt="" />微信支付
          </button>
        </div>
        <!-- 填写手机号表单 -->
        <div class="phone_form">
          <p>请填写有效的手机号，购买后使用该手机号登录客户端</p>

          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            @submit.native.prevent
          >
            <div class="mask" ref="formMask"></div>
            <div class="icon">*</div>
            <el-form-item prop="mobile">
              <el-input
                v-model="form.mobile"
                placeholder="输入你的手机号码"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 确认支付按钮 -->
        <button class="payBtn" @click="toPay">确认支付</button>
      </div>
    </div>
  </div>
</template>
<script>
import { userApi, homeApi, payApi } from "../api";
import Swiper from "swiper";
import md5 from "js-md5";
import { BASE_IMAGE_URL } from "../common/config.json";
import { isMobile } from "../common/utils";
export default {
  data() {
    const checkPhone = (rule, value, callback) => {
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          value
        )
      ) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      goods_list: [],
      goodsCurrentIndex: 0,
      tab: 0,
      details: {},
      dialogIsShow: false,
      payType: 1,
      form: { mobile: "" },
      rules: {
        mobile: [
          {
            validator: checkPhone,
            required: true,
            trigger: "change",
          },
        ],
      },
      loading: false,
      imgs: {
        wx: require("../assets/images/wx.png"),
        zfb: require("../assets/images/zfb.png"),
      },
      BASE_IMAGE_URL,
      wxbneila: "",
      zfbbneila: "",
    };
  },
  created() {
    this.init();
    if (!isMobile()) {
      const { id, platform } = this.$route.params;
      this.$router.push(`/gamedetail/${platform}/${id}`);
    }
  },
  mounted() {
    payApi.getPayStatus().then((res) => {
      console.log(JSON.parse(res));
      let _res = JSON.parse(res);
      this.wxbneila = _res.wxbneila;
      this.zfbbneila = _res.zfbbneila;
    });
  },
  computed: {
    playVideo() {
      const currentItem = this.goods_list[this.goodsCurrentIndex];
      if (currentItem) {
        return `${this.$gameStartState.BASE_VIDEO_URL}/${currentItem.platform}/webm/${currentItem.vedio}`;
      }
      return "";
    },
    smallDetails() {
      return this.goods_list[this.goodsCurrentIndex];
    },
  },
  activated() {
    this.dialogIsShow = false;
    this.init();
  },
  methods: {
    init() {
      this.loadData();
      window.addEventListener("pageshow", function (event) {
        //event.persisted属性为true时，表示当前文档是从往返缓存中获取
        if (event.persisted) location.reload();
      });
    },
    // 获取路由上的平台和id
    loadData(
      id = this.$route.params.id,
      platform = this.$route.params.platform
    ) {
      this.loading = true;
      homeApi.reqNewList(`('${platform}_${id}')`).then((res) => {
        this.details = res[0] || {};
        // this.getNewNum();
        this.$nextTick(() => (this.loading = false));
        // this.getlist();
        this.getList();
      });
    },
    initSwiper() {
      const _this = this;
      this.goods_swiper = new Swiper(".game_detail_list_1 .swiper-container", {
        loop: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        slidesPerView: 3,
        spaceBetween: 15,
        onSlideChangeEnd: function () {
          const dom = document.querySelector(
            ".game_detail_list_1 .swiper-slide-active"
          );
          const video = dom.getAttribute("data-video");
          const index = _this.goods_list.findIndex(
            (item) => item.vedio === video
          );
          _this.goodsCurrentIndex = index;
          document.title = _this.smallDetails.gamename_zh;
          //   _this.getNewNum();
        },
        on: {
          slideChangeTransitionEnd: function () {
            // $('#xiangqing').html(obj.xiangqing);
            // $('#tuijainpeizhi').html(obj.tuijainpeizhi);
            // $('#peizhi').html(obj.peizhi);
            // $('#goods_content').html(obj.goods_content);
            // $('#price').html(obj.price);
            // $('#price2').html(obj.price);
            // $('#yuanjia').html(obj.yuanjia);
            // $('#brand').html(obj.brand);
            // $('#sales').html(obj.sales);
            // $('#color').html(obj.color);
            // $('#goods_name').html(obj.goods_name);
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    getList() {
      homeApi.reqAllGameList().then((res) => {
        let _goods_list = [];
        const { is_info_banner } = res;
        let arr = is_info_banner.filter((item) => {
          return item.gameid !== this.details.gameid;
        });

        _goods_list = [this.details, ...arr];
        this.goods_list = _goods_list;
        this.$nextTick(() => this.initSwiper());
      });
    },
    slideSwitch(swiper, type) {
      if (type === "prev") {
        this[swiper].slidePrev();
      } else if (type === "next") {
        this[swiper].slideNext();
      }
    },
    switchTab(val) {
      this.tab = val;
    },
    getH5Os() {
      var u = navigator.userAgent;
      let os;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        os = "Android";
      } else if (u.indexOf("iPhone") > -1) {
        os = "iOS";
      } else {
        os = "Wap";
      }
      return os;
    },
    pay() {
      this.dialogIsShow = true;
    },
    closeDialog() {
      if (this.loading) return;
      this.dialogIsShow = false;
    },
    showDownloadUrl() {
      let url = window.location.origin;
      let str = `复制链接后在电脑浏览器中打开:<br/>${url}`;
      this.$confirm("", {
        title: "下载客户端",
        message: str,
        confirmButtonText: "复制",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        var input = document.createElement("input"); // js创建一个input输入框
        input.value = url; // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input); // 将输入框暂时创建到实例里面
        input.select(); // 选中输入框中的内容
        document.execCommand("Copy"); // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$message({ type: "success", message: "复制成功", offset: 200 });
      });
    },
    toPay() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$refs.formMask.style = "animation: formTwinkle 3s linear";
          setTimeout(() => {
            this.$refs.formMask.style = "";
          }, 3000);
          return;
        } else {
          //   if (this.payType === 1) {
          //   return this.$message({
          //     type: "error",
          //     message: "提示：请使用支付宝支付！",
          //     offset: 400,
          //   });
          // }
          // 校验 通过
          this.$refs.formMask.style = "";
          userApi
            .reqLogin(
              "",
              0,
              "",
              this.form.mobile,
              "",
              this.$gameStartState.ip,
              this.$gameStartState.city,
              2,
              this.$gameStartState.belong
            )
            .then((res) => {
              if (!res) {
                this.$alert("您的账号出现异常，请联系客服处理", {
                  confirmButtonText: "确定",
                  type: "warning",
                });
                return;
              }
              this.loading = true;
              //  校验成功跳转支付
              userApi.reqGettime().then((time) => {
                const { gameid, gamename_zh, sharemoney, platform } =
                  this.smallDetails;
                let xfmoney =
                  (this.payType == 1
                    ? this.$gameStartState.zfbCharges * sharemoney
                    : this.$gameStartState.wxCharges * sharemoney
                  ).toFixed(2) * 100;
                const payParam = {
                  type: 0,
                  gameid,
                  gamename: gamename_zh,
                  num: 1,
                  bShare: 1,
                  paydetail: this.payType,
                  xfmoney,
                  lastmoney: 0,
                  info: 0,
                  platform,
                  sceneType: this.getH5Os(),
                  goodsinfo: 0,
                  paytime: time,
                  speedtime: "",
                  jsqname: "",
                  ipaddress: this.$gameStartState.ip,
                  versionName: "标准版",
                };
                this.payParam = {
                  ...payParam,
                  paydetail: this.payType,
                  Uuid: this.form.mobile,
                  sign: md5(this.form.mobile + "kkkuu").toUpperCase(),
                };
                if (this.payType == 2) {
                  if (this.wxbneila == 0) {
                    this.isPay = false;
                    this.loading = false;
                    this.$alert("微信支付维护中，请选择其他支付方式", {
                      confirmButtonText: "确定",
                      type: "warning",
                    });
                  } else {
                    payApi
                      .reqHHpayop({
                        ...this.payParam,
                        city: this.$gameStartState.city,
                      })
                      .then((res) => {
                        // const tradeNo = res.split("=")[1];
                        // 获取链接参数订单号, 调用支付宝和微信对应接口
                        // 微信是url 直接跳转
                        this.timer = setTimeout(() => {
                          console.log("可以点了");
                          this.isPay = false;
                          this.loading = false;
                        }, 2000);
                        if (this.payType == 2) {
                          window.location.href =
                            res.trade_wechat_jsapi_pay_response.cashier_url;
                        } else {
                          // 支付宝是表单，手动操作
                          // document.querySelector("body").innerHTML = res;
                          // document.forms[0].submit();
                          window.location.href =
                            res.trade_alipay_qrcode_pay_response.qr_code;
                        }
                      });
                  }
                } else {
                  payApi
                    .reqJkpayop({
                      ...this.payParam,
                      city: this.$gameStartState.city,
                    })
                    .then((res) => {
                      // const tradeNo = res.split("=")[1];
                      // 获取链接参数订单号, 调用支付宝和微信对应接口
                      // 微信是url 直接跳转
                      this.timer = setTimeout(() => {
                        console.log("可以点了");
                        this.isPay = false;
                        this.loading = false;
                      }, 2000);
                      if (this.payType == 2) {
                        window.location.href =
                          res.trade_wechat_jsapi_pay_response.cashier_url;
                      } else {
                        // 支付宝是表单，手动操作
                        // document.querySelector("body").innerHTML = res;
                        // document.forms[0].submit();
                        window.location.href = res;
                      }
                    });
                }
              });
            });
        }
      });
    },
    toGameCenter() {
      this.$router.push("/mGameCenter");
    },
  },
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.hidden {
  video {
    display: none;
  }
}
.down_load {
  width: 100%;
  padding: 0 2vw;
  height: 20vw;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  font-size: 16px;
  background-color: rgb(29, 30, 40);
  margin-bottom: 18vw;
  .logoimg {
    width: auto;
    height: auto;
    transform: scale(0.5);
  }
  .rightimg {
    width: 3vw;
    height: 4vw;
    position: absolute;
    right: 5vw;
  }
}

.mGamedetail {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .jump_btn {
    position: absolute;
    top: 10vw;
    width: 20vw;
    height: 20vw;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    left: 2vw;
    text-align: center;
    line-height: 20vw;
    z-index: 999;
  }
}
/deep/.el-message-box {
  width: 20% !important;
}
.mgame_productinfo_dialog {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.73);
  position: fixed;
  top: 0;
  left: 0;
  .dialog_body {
    width: 100%;
    height: 82%;
    background-color: #151727;
    position: absolute;
    bottom: 0;
    padding: 10px;
  }
  .order_amount,
  .pay_type {
    height: 15vw;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 1.067vw;
    padding: 3.2vw;
    align-items: center;
    border-bottom: 1px solid #2d2e3c;

    img {
      width: 3.5vw;
      height: 3.5vw;
      margin-right: 2vw;
    }
    .payType-item {
      width: 30vw;
      height: 10vw;
      background: rgba(41, 41, 41);
      color: #fff;
      font-size: 14px;
      position: relative;
      border: 1px solid #666666;
      .payType-tips {
        position: absolute;
        right: -2vw;
        top: -1vw;
        transform: scale(0.9);
        font-size: 12px;
        line-height: 3vw;
        color: #ff4b16;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ff4b16;
        padding: 0px 9px;
        background-color: #292929;
        border-radius: 0px 4px 0px 12px;
      }
    }
  }
  .active {
    border-color: #c8443a !important;
  }
  .phone_form {
    p {
      font-size: 14px;
      margin-bottom: 3vw;
      color: rgb(161, 161, 161);
      padding-left: 2vw;
    }
    background: rgba(255, 255, 255, 0.04);
    border-radius: 1.067vw;
    padding: 4vw 2.667vw 0.5vw;
    margin-top: 2.667vw;

    /deep/.el-form {
      position: relative;
      height: 100%;
      .icon {
        position: absolute;
        top: 30%;
        color: red;
        width: 15px;
        height: 15px;
        background-color: rgb(23, 29, 39);
        text-align: center;
        margin-right: 10px;
      }
      .mask {
        width: 90%;
        height: 30px;
        margin-left: 25px;
        position: absolute;
        top: 5px;
        opacity: 0;
        background-color: #f76058;
      }
      .el-form-item {
        margin-left: 25px;
        width: 90%;
        .el-input__inner {
          color: white;
          background-color: rgb(23, 29, 39);
          border-radius: 3px;
          border: rgb(76, 77, 77) solid 1px;
          height: 30px;
        }
      }
    }
  }
  .payBtn {
    width: 80%;
    height: 12vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(0deg, #87231d, #f95244);
    color: #fff;
    touch-action: manipulation;
    padding: 0 8px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease 0s;
    opacity: 0.8;
    font-size: 18px;
    box-sizing: border-box;
    margin: 3vw auto;
  }
  .dialog_title {
    height: 10vw;
    line-height: 10vw;
    text-align: center;
  }
  .dialog_gameinfo {
    display: flex;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 1.067vw;
    padding: 3.2vw;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: 20.8vw;
    .dialog_game_img {
      width: 25.6vw;
      height: 14.4vw;
      margin-right: 2.667vw;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .dialog_game_info {
      &_name {
        font-size: 16px;
      }
      flex: 1;
      font-size: 14px;
      div {
        display: flex;
        justify-content: space-between;
        height: 4.8vw;
        line-height: 4.8vw;
        margin-bottom: 2vw;
      }
    }
  }
}
.mGamedetail_videoBox {
  width: 100%;
  height: 250px;
  video {
    width: 100%;
    height: 100%;
  }
}
.goods_detail_banner {
  width: 100%;
  .goods_detail_banner_box {
    padding: 0;
  }
  .swiper-container {
    width: 80%;
  }
}
.swiper-button-prev,
.swiper-button-next {
  background: none;
  margin-top: -1vw;
  &::after {
    transform: scale(0.5);
  }
}

.slider {
  padding: 2.5vw !important;
  .slider_text {
    font-size: 12px;
    margin-top: 5px;
  }
}
.goods_detail_banner .swiper-slide-active {
  transform: scale(0.95);
  height: 96px !important;
  background: url(/img/gameBg-735c5e31.7fca1c95.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 30px;
  overflow: visible;
  border: 2px solid #557cb3;
  &::before {
    // display: none;
    top: -13.5px;
  }
  .slider {
    background: none;

    .border {
      width: auto;
      height: auto;
      background: none;
      border: none;
      position: static;
      img {
        width: 100% !important;
        height: 100% !important;
      }
      // border: 1px solid red;
    }
  }
}
del {
  color: rgb(109, 109, 109);
  font-size: 2.667vw;
}
.mgame_info {
  width: 100%;
  padding: 2.933vw 2.667vw 4vw 4vw;
  .mgame_price {
    display: flex;
    align-items: center;
    img {
      width: 10.4vw;
      vertical-align: middle;
      padding-right: 2vw;
    }
    .sharemoney {
      font-size: 5.333vw;
      margin-right: 2vw;
    }

    .discount {
      height: 4.133vw;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 0.267vw;
      padding: 0px 1.067vw;
      font-size: 2.667vw;
      color: rgb(151, 151, 151);
      margin-right: 2.4vw;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }
  }
  .mgame_name_zh {
    font-size: 3.733vw;
    line-height: 5.333vw;
    margin-bottom: 1.067vw;
    font-weight: bold;
    letter-spacing: 1px;
  }
  .mgame_name_en {
    color: rgb(151, 151, 151);
    font-size: 2.933vw;
    line-height: 4vw;
    margin-bottom: 2.667vw;
  }
}
.dialog_mgame_img {
  background-color: #1e202f !important;
  border-radius: 1.067vw;
  margin: 2.667vw 0;
}
.mgame_img {
  display: flex;
  justify-content: space-between;
  padding: 3.2vw 4.267vw;
  font-size: 2.667vw;
  background-color: #0a0f16;
  color: rgba(255, 255, 255, 0.79);
  img {
    width: 4.267vw;
    height: 4.267vw;
    margin-right: 1.067vw;
    object-fit: contain;
  }
}
.mgame_tabbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vw;
  line-height: 10vw;
  margin-top: 2vw;

  .mgame_details,
  .mgame_productinfo {
    height: 7.467vw;
    line-height: 4.933vw;
    position: relative;
    padding-right: 1.6vw;
    margin-right: 6.667vw;
    font-weight: bold;
    position: relative;
    span {
      display: inline-block;
      height: 0.8vw;
      background: linear-gradient(
        270deg,
        rgba(224, 54, 23, 0) 0%,
        rgb(224, 54, 23) 100%
      );
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }
}
.mgame_details_text,
.mgame_productinfo_text {
  font-size: 14px;
  padding: 5vw;
  // padding-bottom: 15vw;
  p {
    margin: 0.7vw 0;
  }
  .mgame_title {
    margin-top: 1vw;
    font-size: 16px;
    color: #557cb3;
  }
  hr {
    height: 0.2vw;
    background-color: #fff;
    margin-top: 3vw;
    margin-bottom: 1vw;
  }
}
.mgame_footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 15vw;
  display: flex;
  background-color: #1d1d28;
  justify-content: space-between;
  .footer_left {
    flex: 1;
    text-align: center;
    line-height: 15vw;
    padding-left: 10vw;
  }
  .footer_right {
    width: 45%;
    display: flex;
    padding-left: 22vw;
    flex-direction: column;
    justify-content: center;
    background: url(../assets/imgs/btn-img.png) no-repeat;
  }
  del {
    color: #fff;
    opacity: 0.46;
    margin-left: 2vw;
  }
}
</style>
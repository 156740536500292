<template>
  <div class="mains">
    <img :src="imgsrc" alt="" class="bk-img" />
    <el-carousel
      :autoplay="true"
      :interval="5000"
      type="card"
      indicator-position="none"
      arrow="always"
      @change="qh()"
      ref="carousel"
    >
      <el-carousel-item v-for="(item, index) in banner" :key="item.id">
        <img
          :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/banner/${item.banner}`"
          alt=""
          class="swiper-slide-img"
          @click="$router.push(`/gamedetail/${item.platform}/${item.gameid}`)"
        />

        <div class="swiper-slide-text">
          <!-- <div class="swiper-slide-tit">{{ item.name }}</div>
          <div class="swiper-slide-price">
            <div class="swiper-slide-price-item back_color_BDE960">
              {{ ((item.sharemoney/item.money)* 100).toFixed(0) }}%
            </div>
            <div class="swiper-slide-price-item">￥{{ item.sharemoney }}</div>
          </div> -->
          <div class="game_name1">{{ item.gamename_zh }}</div>
          <div class="game_price">
            <div class="discount_text">
              -{{ Math.floor((1 - item.sharemoney / item.money) * 100) }}%
            </div>
            <div class="money">
              <span class="sharemoney">￥{{ item.money.toFixed(2) }}</span>
              <span>￥{{ item.sharemoney.toFixed(2) }}</span>
            </div>
          </div>
        </div>

        <div class="ler jt">
          <img :src="btnItem('left', index)['img']" alt="" class="game-img" />
          <div class="game-infos">
            <div class="titles elipsis">
              {{ btnItem("left", index)["name"] }}
            </div>
            <div class="sub-title ellipsis"></div>
          </div>
          <img src="../assets/imgs/left.png" alt="" class="left-img" />
        </div>
        <div class="rier jt" @click="arrowClick()">
          <img src="../assets/imgs/left.png" alt="" class="left-img" />
          <div class="game-infos">
            <div class="titles elipsis">
              {{ btnItem("right", index)["name"] }}
            </div>
            <div class="sub-title ellipsis"></div>
          </div>
          <img :src="btnItem('right', index)['img']" alt="" class="game-img" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import $ from "jquery";
import { BASE_IMAGE_URL } from "../common/config.json";
export default {
  props: {
    banner: Array,
  },
  data() {
    return {
      BASE_IMAGE_URL,
      imgList: [
        {
          src: require("../assets/imgs/1634612805_banner_img.jpg"),
          name: "战地2042",
        },
        {
          src: require("../assets/imgs/1634616271_banner_img.png"),
          name: "战地5",
        },
        {
          src: require("../assets/imgs/1634628108_video_img.jpg"),
          name: "绝地求生",
        },
        {
          src: require("../assets/imgs/YiRMe78MM8.png"),
          name: "NBA 2K",
        },
        {
          src: require("../assets/imgs/nppMxsrK8d.png"),
          name: "双人成行",
        },
      ],
      imgsrc: "",
    };
  },
  mounted() {
    // this.imgsrc = this.imgList[0].src
  },
  watch: {
    banner() {
      const currentItem = this.banner[0];
      this.imgsrc = `${BASE_IMAGE_URL}/${currentItem.platform}/${currentItem.gameid}/banner/${currentItem.banner}`;
      this.initCarousel();
    },
  },
  methods: {
    initCarousel() {
      this.$nextTick(() => {
        $(function () {
          $(".ler").hover(function () {
            $(".ler").addClass("leron");
            $(".el-carousel__arrow--left").show();
          });
          $(".el-carousel__arrow--left").mouseleave(function () {
            $(".ler").removeClass("leron");
            $(".el-carousel__arrow--left").hide();
          });
          $(".rier").hover(function () {
            $(".rier").addClass("rieron");
            $(".el-carousel__arrow--right").show();
          });
          $(".el-carousel__arrow--right").mouseleave(function () {
            $(".rier").removeClass("rieron");
            $(".el-carousel__arrow--right").hide();
          });
          $(".el-carousel__arrow").click(function () {
            $(".ler").removeClass("leron");
            $(".rier").removeClass("rieron");
            $(".el-carousel__arrow").hide();
          });
        });
      });
    },
    btnItem(type, index) {
      const { banner, BASE_IMAGE_URL } = this;
      let item = "";
      if (type === "left") {
        item = banner[index === 0 ? banner.length - 1 : index - 1];
      } else if (type === "right") {
        item = banner[index + 1 === banner.length ? 0 : index + 1];
      }
      return {
        img: `${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/banner/${item.banner}`,
        name: item.gamename_zh,
      };
    },
    arrowClick() {
      // console.log(this.$refs.carousel);
      // if(val=='right'){
      //   this.$refs.cardShow.next();
      // }else{
      //   this.$refs.cardShow.prev();
      // }
    },
    qh() {
      const currentItem = this.banner[this.$refs.carousel.activeIndex];
      this.imgsrc = `${BASE_IMAGE_URL}/${currentItem.platform}/${currentItem.gameid}/banner/${currentItem.banner}`;
      // this.imgsrc = this.imgList[this.$refs.carousel.activeIndex].src
    },
  },
};
</script>


<style lang="less">
.jt:hover {
  opacity: 1;
}
.game_name1 {
  width: 140px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  bottom: 10px;
  right: 0;
  background-color: rgba(58, 68, 67, 0.5);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
}
.game_price {
  width: 140px;
  height: 30px;
  line-height: 24px;
  position: absolute;
  bottom: -20px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .discount_text {
    width: 65px;
    height: 100%;
    font-size: 20px;
    color: #a4d007;
    background: #4c6b22;
    text-align: center;
    line-height: 30px;
  }
  .money {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    background-color: rgba(46, 68, 80);
    color: #acdbf5;
    span {
      width: 100%;
      height: 14px;
      line-height: 14px;
      letter-spacing: 1px;
      margin-left: 5px;
    }
    .sharemoney {
      text-decoration: line-through;
      letter-spacing: 0px;
      font-size: 12px;
      margin-left: 15px;
    }
  }
}
.el-carousel__arrow--right {
  width: 290px !important;
  height: 104px !important;
  right: 25% !important;
  opacity: 0 !important;
  border-radius: 0 !important;
  display: none;
}
.el-carousel__arrow--left {
  width: 290px !important;
  height: 104px !important;
  left: 25% !important;
  opacity: 0 !important;
  border-radius: 0 !important;
  display: none;
}
.mains {
  position: relative;
}
.bk-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px);
  opacity: 0.7;
}
.el-carousel {
  margin-top: 60px;
  padding-top: 40px;
  height: 750px;
}
.leron {
  transform: translateY(-50%) !important;
}
.rieron {
  transform: translateY(-50%) !important;
}
.rier .left-img {
  transform: rotate(180deg);
}
.jt .game-img {
  flex: 0 0 76px;
  height: 76px;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}
.jt .game-infos {
  padding: 0 5px;
  box-sizing: border-box;
  max-width: 100px;
}
.jt .game-infos .title {
  font-size: 18px;
  font-weight: 600;
}
.jt .game-infos .sub-title {
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 15px;
  font-weight: 600;
}

.jt .left-img {
  width: 60px;
  height: 60px;
}
.titles {
  font-size: 18px;
  font-weight: 600;
}
.elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.is-active .jt {
  display: flex;
}
.jt {
  display: none;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 50%;
  width: 290px;
  height: 104px;
  opacity: 0.7;
  background: #1a1827;
  border-radius: 5px;
  transition: transform 0.3s;
  box-sizing: border-box;
}
.ler {
  transform: translate(-216px, -50%);
  padding-left: 20px;
}
.rier {
  transform: translate(216px, -50%);
  padding-right: 20px;
  right: 0;
}
.el-carousel__container {
    height: 68% !important;
    max-height: 690px;
}
</style>

